import get from 'lodash/get'

import { FORMAT, GMTToIANA } from '@ga/shared-dates'

import { KEYS, MAPS, PATH } from '../constants/digital'
import { HASH, HASH_PARAM } from '../constants/hash'
import { errors } from '../repositories/errors'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Стор ЭПК
   */
  get _state() {
    return this.gaApp.stores.giftCards.digital
  }

  /**
   * Идентификатор текущей ЭПК
   */
  get cardNumberHash() {
    return this.gaApp.stores.giftCards.digitalReceive?.cardNumberHash
  }

  /**
   * Формат текущей даты для отображения в результирующей компоненте
   */
  get textCurrentDate() {
    const { currentDate } = this._state
    const { country } = this.gaApp.i18n.locale
    const timezoneValue = this.gaApp.stores.giftCards.digital.timezoneValue
    const formatDate = this.gaApp.libs
      .dateWrapper(currentDate)
      .tz(GMTToIANA(timezoneValue, country))
      .format(FORMAT.BASE)

    return this.gaApp.i18n.t('giftCards.stepLine.date.current', { formatDate })
  }

  /**
   * Формат выбранной даты для отображения в результирующей компоненте
   */
  get textChosenDate() {
    const state = this._state
    const date = get(state, MAPS.DATE)
    const { country } = this.gaApp.i18n.locale
    const timezoneValue = this.gaApp.stores.giftCards.digital.timezoneValue
    const timezoneText = this.gaApp.stores.giftCards.digital.timezoneText
    const is12HourFormat = this.gaApp.features.get('giftCards12hFormat')
    const formatDate = this.gaApp.libs
      .dateWrapper(date)
      .tz(GMTToIANA(timezoneValue, country))
      .format(is12HourFormat ? FORMAT.WITH_INDENT_12H : FORMAT.WITH_INDENT)

    return `${formatDate} ${timezoneText}`
  }

  /**
   * Номинал с указанной валютой
   */
  get currencyAmount() {
    const state = this._state
    const amount = get(state, MAPS.AMOUNT)
    const { currency } = state.priceInfo

    return this.gaApp.services.app.currency.getNominalFormatted({
      amount,
      currency,
      withSymbol: true,
      minimumFractionDigits: 0,
    })
  }

  /**
   * Код валюты
   */
  get currency() {
    return this._state.priceInfo.currency
  }

  /**
   * Конфигурация номера телефона
   */
  get phoneMetadata() {
    return this.gaApp.services.app.phone.phoneMetadataDefault
  }

  /**
   * Маска номера телефона
   */
  get phoneMask() {
    return this.phoneMetadata?.masks.international
  }

  /**
   * Начальная часть маски номера телефона
   */
  get phonePrefix() {
    return this.phoneMetadata?.prefix
  }

  /**
   * Идентификаторы начальных частей маски номера телефона
   */
  get phonePrefixAliases() {
    return this.phoneMetadata?.prefixAliases
  }

  /**
   * Регулярное выражение проверки параметров адреса оформления ЭПК
   */
  get _validator() {
    const { stepItems } = this._state
    const matcher = `#${HASH}(\\?${HASH_PARAM.STEP}=[0-${stepItems.length - 1}](&${HASH_PARAM.PROMPT}=1)?$)?`

    return new RegExp(matcher)
  }

  get isDesignTypesEnabled() {
    return this.gaApp.features.get('giftCardsDesignTypes')
  }

  /**
   * Выбор типа подарочной карты
   * @param {string} value тип подарочной карты
   */
  setCardType(value) {
    this.gaApp.stores.giftCards.main.card.selectedType = value
  }

  /**
   * Валидация внешней ссылки и перенаправление
   * @param {URL | string} url параметры внешней ссылки
   */
  redirectToExternalUrl(url) {
    if (url) {
      window.location.assign(url)

      return
    }
    throw this.gaApp.api.error(errors.paymentOrderCard)
  }

  /**
   * Перенаправление на страницу результата платежа
   */
  redirectAfterPaymentUrl(type) {
    const path = type === 'success' ? PATH.SUCCESS : PATH.ERROR

    this.gaApp.router.push(path)
  }

  /**
   * Обработка возвращенных ошибок BFF (4xx коды)
   * @param {string} localizedMessage локализированное сообщение об ошибке
   */
  async handleValidationServerError(localizedMessage) {
    const message = localizedMessage
    // повторный запрос конфигурации оформления ЭПК
    await this.gaApp.services.giftCards.digital.fetchConfigData()

    // закрываем модальное окно с чеком
    this.gaApp.services.giftCards.digital.toggleVisibleInnerModal(false)
    // устанавливаем первый шаг ЭПК
    this.setHash({
      method: 'replace',
      [HASH_PARAM.STEP]: 0,
    })

    // отображение сообщение об ошибке в нотификации
    if (message) {
      this.gaApp.services.notification.main.open(message)
    }
  }

  /**
   * Обработка возвращенных ошибок BFF
   * @param {Error} error объект ошибки
   */
  handleServerErrors(error) {
    const statusCode = error.statusCode
    const invalidParameters = error.invalidParameters

    const hasErrorValidation = statusCode >= 400 && statusCode < 500

    if (hasErrorValidation && Object.keys(invalidParameters)?.length) {
      // при ошибке в полях формы, перенаправляем на первый шаг и повторно запрашиваем конфигурацию
      this.handleValidationServerError(error.localizedMessage)
    } else {
      // перенаправляем на страницу с ошибкой оплаты
      this.redirectAfterPaymentUrl('error')
    }
    throw this.gaApp.api.error(error)
  }

  /**
   * Перенаправление пользователя на страницу ошибки оплаты
   * @param {Error} error
   */
  redirectToErrorPage(error) {
    this.gaApp.redirectError(error)
    throw this.gaApp.api.error(error)
  }

  /**
   * Данные шага формы с выводом результата
   */
  resultView() {
    const state = this._state
    const data = Object.values(state.formData).reduce((result, value) => {
      return {
        ...result,
        ...value,
      }
    }, {})

    data[KEYS.DATE] = this.gaApp.stores.giftCards.digital.isDeliveryNow
      ? this.textCurrentDate
      : this.textChosenDate

    data[KEYS.AMOUNT] = this.currencyAmount

    return data
  }

  /**
   * Открытие формы оформления ЭПК
   */
  async startDigital() {
    if (
      !this.gaApp.stores.giftCards.digital.isConfigLoaded ||
      (this.isDesignTypesEnabled &&
        !this.gaApp.stores.giftCards.digitalAiDesign.isConfigLoaded)
    ) {
      // загрузка конфигурации
      await this.gaApp.services.giftCards.digital.fetchConfigData()
    }

    if (!this.validCurrentRouteHash()) {
      // устанавливаем при открытии модального окна хэш с номером шага
      this.setHash({
        [HASH_PARAM.STEP]: this._state.currentStep,
      })
    }
  }

  /**
   * Закрытие модельного окна оформления ЭПК
   */
  closeModal() {
    this.gaApp.analytics.modules.giftCards.onCloseModal()
    // при закрытии модального окна удаляем хэш
    if (this.validCurrentRouteHash()) {
      this.removeHash()
    }
  }

  /**
   * Валидация параметров адреса оформления ЭПК
   * @param {string} hash хэш-параметр адреса оформления ЭПК
   * @returns {boolean} валидность параметров адреса оформления ЭПК
   */
  validHash(hash) {
    return this._validator.test(hash)
  }

  /**
   * Валидация параметров оформления ЭПК в текущем адресе
   * @returns {boolean} валидность параметров адреса оформления ЭПК
   */
  validCurrentRouteHash() {
    const { hash } = this.gaApp.router.currentRoute
    return this.validHash(hash)
  }

  /**
   * Обновление параметров оформления ЭПК в текущем адресе
   * @param {string} options.method метод обновления роута
   * @param {number} options.step шаг формы оформления ЭПК
   * @param {number} options.prompt открыто ли модальное окно генерации ЭПК
   */
  setHash({ method = 'push', ...hashParamsValues } = {}) {
    const isDigitalCardsPath =
      this.gaApp.route.fullPath.includes('cards/digital')

    if (
      !this.gaApp.stores.giftCards.digital.visibleModal &&
      !isDigitalCardsPath
    ) {
      return undefined
    }

    const hashParams = new URLSearchParams()

    const step =
      hashParamsValues[HASH_PARAM.STEP] ||
      this.gaApp.stores.giftCards.digital.currentStep

    if (step || step === 0) {
      hashParams.set(HASH_PARAM.STEP, step)
    }

    const prompt = Number(
      hashParamsValues[HASH_PARAM.PROMPT] ||
        this.gaApp.stores.giftCards.digitalAiDesign.promptModal.isOpen,
    )

    if (prompt) {
      hashParams.set(HASH_PARAM.PROMPT, prompt)
    }

    const paramsString = hashParams.toString()

    const routerParams = isDigitalCardsPath
      ? { query: hashParamsValues }
      : { hash: `#${HASH}${paramsString ? '?' : ''}${paramsString}` }

    this.gaApp.router[method](routerParams)
  }

  /**
   * Удаление параметров оформления ЭПК в текущем адресе
   */
  removeHash() {
    this.gaApp.router.replace({ hash: '' })
  }

  /**
   * Проверяет, является ли текущий роут модулем "Подарочные карты"
   */
  isGiftCardsModuleRoute() {
    return ['/cards', '/b2b/cards'].some((item) =>
      this.gaApp.route.fullPath.startsWith(item),
    )
  }

  /**
   * Заполняет данные для главной страницы ПК
   */
  async getMainPage() {
    try {
      const data = await this.gaApp.services.giftCards.api.getDataMainPage()
      this.gaApp.stores.giftCards.main.mainPage = data
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Заполняет данные о меню ПК
   */
  async getMainPageMenu() {
    try {
      const data = await this.gaApp.services.giftCards.api.getDataMenu()
      this.gaApp.stores.giftCards.main.menu = data
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Заполнение данных для главной страницы
   */
  getMainPageData() {
    const promises = []

    const isMainFetchEnabled = this.gaApp.features.get('giftCardsMainFetch')
    const isMenuFetchEnabled = this.gaApp.features.get('giftCardsMenuFetch')

    if (isMainFetchEnabled) promises.push(this.getMainPage())
    if (isMenuFetchEnabled) promises.push(this.getMainPageMenu())

    return Promise.all(promises)
  }
}
