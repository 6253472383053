import { SOURCE } from '../../../constants'

export class GtmBaseModel {
  constructor({ event, source, additional }) {
    this.event = event
    this.event_source = source || SOURCE

    if (additional) {
      Object.entries(additional).forEach(([key, value]) => {
        this[key] = value
      })
    }
  }
}
