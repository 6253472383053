export class ApiAdvBannersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getBanners(params) {
    try {
      const response =
        await this.gaApp.repositories.articles.advBanners.getBanners(params)

      return response.data.banners
    } catch (error) {
      return []
    }
  }

  async addViewed({ captchaToken, bannerId, campaignId }) {
    try {
      await this.gaApp.repositories.articles.advBanners.addViewed({
        captchaToken,
        bannerId,
        campaignId,
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  async addViewedWebview({ bannerId, campaignId }) {
    try {
      await this.gaApp.repositories.articles.advBanners.addViewedWebview({
        bannerId,
        campaignId,
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  async addClicked({ captchaToken, bannerId, campaignId }) {
    try {
      await this.gaApp.repositories.articles.advBanners.addClicked({
        captchaToken,
        bannerId,
        campaignId,
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  async addClickedWebview({ bannerId, campaignId }) {
    try {
      await this.gaApp.repositories.articles.advBanners.addClickedWebview({
        bannerId,
        campaignId,
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  async getBannerPreview({ campaignId, bannerId }) {
    try {
      const response =
        await this.gaApp.repositories.articles.advBanners.getBannerPreview({
          campaignId,
          bannerId,
        })

      return response.data
    } catch (error) {
      console.error(error.message)
    }
  }
}
