export const offerPageMock = {
  id: '6627ca1e480cc6c822199d08',
  title: "Ligne St. Barth х L'Artisan Parfumeur",
  description: 'на цветные линзы только по промокоду',
  editorObject: {
    sections: [
      {
        id: 'D0MFn6wjrCucKqAFYWtjc',
        content: {
          time: 1727456254893,
          blocks: [
            {
              id: 'IVJcVnbc9v',
              type: 'header',
              data: {
                text: '12321',
                level: 5,
                isSectionHeader: false,
                useTypograf: true,
                noMargin: false,
              },
            },
            {
              id: 'dDkfwo6ZbS',
              type: 'paragraph',
              data: {
                text: 'шнлнпгл',
                dropcap: false,
                highlight: false,
                useTypograf: true,
                noMargin: false,
              },
            },
            {
              id: 'FR3pVEGUtQ',
              type: 'header',
              data: {
                text: 'нкрароенп',
                level: 5,
                isSectionHeader: false,
                useTypograf: true,
                noMargin: false,
              },
            },
            {
              id: 'KjJDPBq6JH',
              type: 'product',
              data: {
                type: 'slider',
                name: 'Похожие Товары',
                link: 'https://testecom.goldapple.ru/novinki',
                skus: null,
              },
            },
          ],
          version: '2.29.1',
        },
        indent: 'l',
      },
    ],
  },
  startDate: '2024-06-06',
  endDate: '2024-06-07',
  button: {
    name: 'Купить сейчас',
    url: '/offers/special-price-11-07',
  },
  labels: [
    'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
    'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
  ],
  imageUrl: {
    format: ['webp', 'png'],
    screen: ['fullhd', 'tablet', 'mobile'],
    // eslint-disable-next-line no-template-curly-in-string
    url: 'https://stage-pcdn.goldapple.ru/t/msc/363732333663666464353631626564613737366336636662/web/5f6d756c7469536f75726365436f6d6d6f6e8dcf9a103f7bf35${screen}.${format}',
  },
  disclaimer: {
    legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
    erid: 'rgrgwg',
    label: 'black',
  },
}
