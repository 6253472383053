import {
  COOKIE_SELECTED_ADDRESS_ID,
  STORAGE_SELECTED_ADDRESS_KEY,
} from '../constants/confirm'

/**
 * Кеш сервис модуля локации
 * работает с выбранным адресом
 */
export class CacheService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Сохраняет выбранный адрес для гостя
   * @param selectedAddress
   */
  saveGuestSelectedAddress(selectedAddress) {
    if (!this.gaApp.services.user.main.isAuthorized) {
      this.setSelectedAddress(selectedAddress)
    }
  }

  /**
   * Удаляет временный адрес для гостя
   */
  removeGuestSelectedAddress() {
    this.gaApp.services.app.browserStorage.local.remove(
      STORAGE_SELECTED_ADDRESS_KEY,
    )

    this.removeSelectedAddressId()
  }

  /**
   * Для бесшовной миграции, чтобы не потерять выбранные адреса при включении тоггла
   * убрать после удаления тоггла
   * @param selectedAddress
   */
  saveMigrateSelectedAddress(selectedAddress) {
    this.setSelectedAddress(selectedAddress)
  }

  /**
   * Получение временного адреса для миграции
   */
  getMigrateSelectedAddress() {
    return this.getSelectedAddress()
  }

  /**
   * Удаляет временный адрес для миграции
   * убрать после удаления тоггла
   */
  removeMigrateSelectedAddress() {
    this.gaApp.services.app.browserStorage.local.remove(
      STORAGE_SELECTED_ADDRESS_KEY,
    )

    this.removeSelectedAddressId()
  }

  /**
   * Фиксит некорректную обработку row: null на старом чекауте
   * делаем тут, потому что быстрее
   * todo: убрать как чекаут станет корректно обрабатывать
   */
  prepareSelectedAddress(selectedAddress) {
    const { row, lang, ...result } = selectedAddress || {}

    if (row !== null) {
      result.row = row
    }

    result.lang = lang || this.gaApp.i18n.locale.code

    return result
  }

  /**
   * Записываетс выбранный адрес в локальное хранилище
   * @param {object} selectedAddress - выбранный адрес
   */
  setSelectedAddress(selectedAddress) {
    if (!selectedAddress?.city || !selectedAddress?.id) {
      const error = new Error(
        'Ошибка сохранения выбранного адреса в локальное хранилище',
      )
      error.data = JSON.stringify(selectedAddress || {})

      this.gaApp.services.app.apm.captureError(error)

      return
    }

    const preparedSelectedAddress = this.prepareSelectedAddress(selectedAddress)

    this.gaApp.services.app.browserStorage.local.set(
      STORAGE_SELECTED_ADDRESS_KEY,
      preparedSelectedAddress,
    )
    this.saveSelectedAddressId(preparedSelectedAddress?.id)
  }

  /**
   * Отдает выбранные адрес с локального хранилища
   */
  getSelectedAddress() {
    return this.gaApp.services.app.browserStorage.local.get(
      STORAGE_SELECTED_ADDRESS_KEY,
    )
  }

  /**
   * Сохраняет фиас локации в куку
   * как-то на бфф будет использоваться
   * @param {string} addressId - фиас локации
   */
  saveSelectedAddressId(addressId) {
    this.gaApp.cookies.set(COOKIE_SELECTED_ADDRESS_ID, addressId)
  }

  /**
   * Удаляет фиас локации с куки
   * как-то на бфф будет использоваться
   * @param {string} addressId - фиас локации
   */
  removeSelectedAddressId(addressId) {
    this.gaApp.cookies.remove(COOKIE_SELECTED_ADDRESS_ID)
  }
}
