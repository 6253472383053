export const PAGE_TYPE = {
  DOCUMENTS: 'info/documents',
  DELIVERY: 'info/delivery',
  CONTACTS: 'info/contacts',
  LOYALTY: 'info/loyalty',
  FAQ: 'info/faq',
}

export const PAGE_URL_KEY = {
  DOCUMENTS: 'documents',
  DELIVERY: 'delivery',
  FAQ: 'faq',
}

export const CMS_PAGE_PATH = {
  DOCUMENTS: '/documents',
  DELIVERY: '/dostavka-i-oplata',
  DELIVERY_MOBILE: '/dostavka-i-oplata-mobile',
  FAQ: '/faq',
}
