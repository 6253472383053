export class DigiAnalyticsBrandViewModel {
  constructor({ category, page }) {
    const { name, breadcrumbs } = category
    const { products } = page

    this.data = {
      eventType: 'BRAND_PAGE_VIEW',
      brandName: name,
      breadcrumbs: breadcrumbs?.length ? breadcrumbs : [name],
      pageProducts: products?.map(({ itemId }) => itemId) ?? [],
    }
  }
}
