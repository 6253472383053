export const endpoints = {
  setProofAge: () => ({
    url: 'adult-goods/age/confirm',
  }),
  updateUserLanguage: () => ({
    url: 'user/language',
  }),
  updateUserLanguageV2: () => ({
    url: 'user/language-v2',
  }),
  getData: () => ({
    url: 'user/data',
  }),
  getInfoFull: () => ({
    url: 'user/info/full',
  }),
  getMobileInfoFull: () => ({
    url: 'user/mob/info/full',
  }),
  getInfoShort: () => ({
    url: 'user/info/short',
  }),

  getAddress: () => ({
    url: 'location/address',
  }),
  getAddressV2: () => ({
    url: 'location/address-v2',
  }),

  checkPhoneNumber: () => ({
    url: 'user/checknumber',
  }),
}
