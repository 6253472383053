export class XmasService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  changeGiftModalVisibility(value) {
    this.gaApp.stores.info.xmas.modals.modalGiftIsOpen = value
  }

  async openTask(day) {
    try {
      await this.gaApp.services.info.api.openXmasTask(day)
      this.gaApp.stores.info.xmas.userData.lastOpenDay = day
    } catch (e) {
      console.error('openTask error: ' + e.message)
    }
  }

  async openGiftModal(day) {
    try {
      const openedTask = this.gaApp.stores.info.xmas.tasks.find(
        (task) => task.day === day,
      )

      this.gaApp.stores.info.xmas.openedTaskProductPending = true
      const { variants } = await this.gaApp.services.info.api.getProduct({
        itemId: openedTask.id,
      })

      const product = variants.find(
        (variant) => variant.itemId === openedTask.id.toString(),
      )

      if (openedTask.placementIds) {
        const placements =
          await this.gaApp.services.info.api.fetchProductsBySku(
            openedTask.placementIds,
          )

        this.gaApp.stores.info.xmas.openedTaskPlacements = placements
      }

      this.gaApp.stores.info.xmas.openedTaskProduct = product
      this.gaApp.stores.info.xmas.openedTask = openedTask

      this.gaApp.stores.info.xmas.openedTaskProductPending = false

      this.changeGiftModalVisibility(true)
    } catch (e) {
      this.gaApp.services.notification.main.open(
        'Не удалось открыть товар. Попробуйте еще раз',
      )
    }
  }

  closeGiftModal() {
    this.changeGiftModalVisibility(false)
  }

  changeQuizModalVisibility(value) {
    this.gaApp.stores.info.xmas.modals.modalQuizIsOpen = value
  }

  openQuizModal() {
    this.changeQuizModalVisibility(true)
  }

  closeQuizModal() {
    this.changeQuizModalVisibility(false)
  }

  changePromoModalVisibility(value) {
    this.gaApp.stores.info.xmas.modals.modalPromoIsOpen = value
  }

  openPromoModal() {
    this.changePromoModalVisibility(true)
  }

  closePromoModal() {
    this.changePromoModalVisibility(false)
  }

  changeWelcomeScreenVisibility(value) {
    this.gaApp.stores.info.xmas.opened = value
  }

  closeWelcomeScreen() {
    this.changeWelcomeScreenVisibility(true)
  }

  async copyPromoCodeAndSendNotification(promoCode) {
    try {
      await this.gaApp.services.app.clipboard.writeText(promoCode)
      this.gaApp.services.notification.main.open(
        `Промокод скопирован в буфер обмена.`,
      )
    } catch (e) {
      this.gaApp.services.notification.main.open('Попробуйте еще раз')
    }
  }

  async getUser() {
    const data = await this.gaApp.services.info.api.getXmasUser()
    this.gaApp.stores.info.xmas.userData = data.userData
  }

  async getTasks() {
    const data = await this.gaApp.services.info.api.getXmasTasks()
    this.gaApp.stores.info.xmas.tasks = data.tasks
  }

  async completeTask(day) {
    const data = await this.gaApp.services.info.api.completeXmasTask(day)
    this.gaApp.stores.info.xmas.userData.promoCodes.push(data)
  }
}
