import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import { GaStoreSwitcherCountry } from '../country'

// @vue/component
export default {
  name: 'ga-app-store-switcher-store-list',

  components: {
    GaAction,
    GaStoreSwitcherCountry,
  },

  setup(props) {
    const { $gaApp } = useContext()

    const currentLanguage = computed(() => $gaApp.i18n.locale.code)

    const getStoreMods = (code) => {
      return {
        current: $gaApp.services.app.langSwitcher.isCurrentStore(code),
      }
    }

    const getLanguageMods = (store, language) => {
      return {
        current: $gaApp.services.app.langSwitcher.isCurrentLanguage(
          language.code,
        ),
        code: language.code,
        default: $gaApp.services.app.langSwitcher.isStoreDefaultLanguage(
          language.code,
          store,
        ),
      }
    }

    const getHref = (store, languageCode) => {
      if ($gaApp.services.app.langSwitcher.isCurrentStore(store.code)) {
        return $gaApp.i18n.switchLocalePath(languageCode)
      }

      return null
    }

    const onClick = (store, languageCode) => {
      if ($gaApp.services.app.langSwitcher.isCurrentStore(store.code)) {
        $gaApp.services.app.langSwitcher.switchLocale(languageCode)
      } else {
        $gaApp.services.app.langSwitcher.setSelectedStoreDomain({
          store,
          languageCode,
        })

        $gaApp.services.app.langSwitcher.confirmOrRedirectToSelected({
          position: 'right',
        })
      }
    }

    const stores = computed(() => $gaApp.services.app.langSwitcher.allStores)

    return {
      currentLanguage,
      stores,

      getStoreMods,
      getLanguageMods,

      onClick,
      getHref,
    }
  },
}
