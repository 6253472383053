export const advBannersEndpoints = {
  fetchAdvBanners: () => ({
    url: 'articles/advertising/banners',
  }),

  fetchAdvBannerViewed: () => ({
    url: 'articles/advertising/viewed',
  }),

  fetchAdvBannerViewedWebview: () => ({
    url: 'articles/advertising/webview/viewed',
  }),

  fetchAdvBannerClicked: () => ({
    url: 'articles/advertising/clicked',
  }),

  fetchAdvBannerClickedWebview: () => ({
    url: 'articles/advertising/webview/clicked',
  }),

  fetchAdvBannerPreview: ({ campaignId, bannerId }) => ({
    url: `articles/advertising/preview/${campaignId}/banner/${bannerId}`,
  }),
}
