import { render, staticRenderFns } from "./product-card-vertical.vue?vue&type=template&id=3b64497d"
import script from "./product-card-vertical.scripts.js?vue&type=script&lang=js&external"
export * from "./product-card-vertical.scripts.js?vue&type=script&lang=js&external"
import style0 from "./product-card-vertical.styles.scss?vue&type=style&index=0&prod&lang=scss&module=true&external"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports