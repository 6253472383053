import debounce from 'lodash/debounce'

import { SEARCH_DEBOUNCE_DELAY } from '../constants/search'

export class StreetsService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.getSearchDataDebounced = debounce(
      this.getSearchDataDebounced,
      SEARCH_DEBOUNCE_DELAY,
    )
  }

  updateQuery(value) {
    this.gaApp.stores.location.search.streets.query = value

    if (value.trim()) {
      this.pendingStart()
      this.getSearchDataDebounced(value)
    } else {
      this.resetState()
    }
  }

  updateQueryTemp(value) {
    this.gaApp.stores.location.search.streets.queryTemp = value
  }

  pendingStart() {
    this.gaApp.stores.location.search.streets.pending = true
  }

  pendingStop() {
    this.gaApp.stores.location.search.streets.pending = false
  }

  async getSearchDataDebounced(value) {
    await this.gaApp.services.location.api.suggestStreets(value)

    this.pendingStop()
  }

  resetState() {
    this.gaApp.stores.location.search.streets.items = []
    this.gaApp.stores.location.search.streets.query = ''
    this.gaApp.stores.location.search.streets.pending = false
  }

  /*
   * Возвращает подсказку с полными данными, включая локацию
   * */
  async getSuggestData(option) {
    const additionalData =
      await this.gaApp.services.location.streets.fetchSuggestAdditionalData(
        option.value,
        option.fullAddress,
      )

    return {
      ...option,
      ...additionalData, // подмешиваем объект локации в выбранный саджест
    }
  }

  /**
   * Обновляет query после выбора подсказки
   * @param text
   */
  updateFromSuggestData({ text }) {
    this.gaApp.services.location.streets.updateQueryTemp(text)
  }

  /*
   * Запрос локации для адресных подсказок с раздельными запросами
   * */
  async fetchSuggestAdditionalData(suggestId, fullAddress) {
    this.pendingStart()
    const data = await this.gaApp.services.location.api.geocodeStreets(
      suggestId,
      fullAddress,
    )
    this.pendingStop()

    return data
  }
}
