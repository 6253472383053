import { encode } from 'js-base64'

import { buildContentTypeHeader, buildRequestPayload } from '../helpers'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get isAuthorized() {
    return this.gaApp.stores.user.main.isAuthorized
  }

  /**
   * Метод запроса СМС-кода по номеру телефона
   *
   * @param {object} obj
   * @param {string} obj.phone - Номер телефона, на который отправить СМС
   * @param {string} obj.phoneCode - Код страны, например 'ru'
   * @param {string} obj.captchaToken - каптча-токен
   *
   * @returns {object} response | error
   */
  async sendAuthCode({ phone, phoneCode, captchaToken } = {}) {
    try {
      const reqType = 'json'
      const data = buildRequestPayload('sendCode', reqType, {
        phone,
        phoneCode,
        captchaToken,
      })
      const config = buildContentTypeHeader(reqType)

      const response = await this.gaApp.services.auth.api.sendCodeV2(
        data,
        config,
      )

      if (response.data) {
        this.gaApp.stores.auth.main.setResendCodeToken(
          response.data.resend_token,
        )

        this.gaApp.stores.auth.main.setTokenOptions({
          grantType: response.data.grant_type,
        })
      }

      return response
    } catch (error) {
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      throw error
    }
  }

  /**
   * Метод повторного запроса СМС-кода
   *
   * @param {object} obj
   * @param {string} obj.phone - Номер телефона, на который отправить СМС
   * @param {string} obj.phoneCode - Код страны, например 'ru'
   * @param {string} obj.captchaToken - каптча-токен
   *
   * @returns {object} response | error
   */
  async resendAuthCode({ phone, phoneCode, captchaToken } = {}) {
    try {
      const resendToken = this.gaApp.stores.auth.main.resendCodeToken

      const reqType = 'json'
      const data = buildRequestPayload('resendCode', reqType, {
        phone,
        phoneCode,
        resendToken,
        captchaToken,
      })
      const config = buildContentTypeHeader(reqType)

      const response = await this.gaApp.services.auth.api.resendCodeV2(
        data,
        config,
      )

      if (response.data) {
        this.gaApp.stores.auth.main.setResendCodeToken(
          response.data.resend_token,
        )

        this.gaApp.stores.auth.main.setTokenOptions({
          grantType: response.data.grant_type,
        })
      }

      return response
    } catch (error) {
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      throw error
    }
  }

  /**
   * Метод подтверждения СМС-кода
   *
   * @param {object} obj
   * @param {string} obj.code - код из СМС-сообщения
   * @param {string} obj.phone - Номер телефона, куда была отправлен СМС-код
   *
   * @returns {object} response | error
   */
  async login({ code, phone, meta }) {
    const reqType = 'form'

    const grantType = this.gaApp.stores.auth.main.grantType
    const data = buildRequestPayload('loginGrandType', reqType, {
      code,
      phone,
      grantType,
    })

    const config = buildContentTypeHeader(reqType)

    const response = await this.gaApp.services.auth.api.login(data, config)

    if (response.data) {
      // eslint-disable-next-line camelcase
      const { magento_data = {} } = response.data

      // Запрашиваем данные юзера и устанавливаем
      await this.gaApp.services.user.api.fetchUserInfoFull()

      // Устанавливаем для magento данные в localStorage
      // eslint-disable-next-line camelcase
      this.gaApp.services.cache.main.saveUser(magento_data.customer_section)

      // Сбрасываем токен для перезапроса смс-кода
      this.gaApp.stores.auth.main.setResendCodeToken(null)
    }

    this.gaApp.eventBus.publish('module/auth/login', {
      meta,
    })

    return response
  }

  /**
   * Метод отправляет запрос на выход из системы
   *
   * @returns {object} response | error
   */
  async logout() {
    const reqType = 'form'
    const data = buildRequestPayload('logout', reqType)
    const config = buildContentTypeHeader(reqType)

    this.gaApp.services.auth.core.resetTokens()

    const response = await this.gaApp.services.auth.api.logout(data, config)

    this.gaApp.eventBus.publish('module/auth/logout')

    return response
  }

  async logoutWithClearData() {
    await this.logout()
    this.gaApp.services.user.main.resetUser()
    this.gaApp.services.auth.modal.reset()
  }

  onAuthFailed(redirect) {
    window.requestAnimationFrame(() => {
      const payload = redirect
        ? {
            redirect: 'page',
            callbacks: { redirect },
          }
        : {}

      // Открываем модалку
      window.requestAnimationFrame(() => {
        this.gaApp.services.modal.main.openSuperModalAuth({
          reason: 'relogin',
          ...payload,
        })

        // Если модалка не открылась, то очищаем юзера
        this.gaApp.ctx.$doubleRAF(() => {
          if (!this.gaApp.stores.modal.main.hasOpenedAuthModal) {
            this.gaApp.services.user.main.resetUser()
          }
        })
      })
    })
  }

  openModal(settings) {
    const payload = { push: true, ...settings }

    this.gaApp.services.modal.main.openSuperModalAuth(payload)
  }

  async checkRouteAuth(to, _, next) {
    const onAccessDenied = () => {
      next(false)

      this.gaApp.services.auth.core.resetTokens()
      this.gaApp.services.auth.main.onAuthFailed(next)
    }

    // Если это роут не требующий авторизации,
    // то просто пропускаем юзера
    if (!this.gaApp.services.app.router.isAuthRequired(to.path)) {
      return next()
    }

    if (!this.isAuthorized) {
      return onAccessDenied()
    }

    // Если юзер авторизован, то проверяем его токены
    const { hasAccessToken, hasRefreshToken } =
      this.gaApp.services.auth.core.checkTokens()

    try {
      // Если нет access'a, но есть рефреш, то пробуем рефрешнуть
      if (!hasAccessToken && hasRefreshToken) {
        await this.gaApp.services.auth.core.doRefreshTokens()

        await this.gaApp.services.user.api.fetchUserInfoFull()

        return next()
      }

      // Если нет ни access'a, ни refresh'a, то не пропускаем юзера
      if (!hasAccessToken && !hasRefreshToken) {
        return onAccessDenied()
      }

      // Запросили полное инфо юзера
      await this.gaApp.services.user.api.fetchUserInfoFull()

      return next()
    } catch (error) {
      return onAccessDenied()
    }
  }

  /**
   * При авторизаци передаем данные для мержка сессии гостя и пользователя
   */
  getUserData() {
    const cartId = this.gaApp.stores.cart.main.id
    const { id } = this.gaApp.services.location.main.getDeliveryAddress()

    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      return {
        'X-Magento-Auth-Data': encode(
          JSON.stringify({
            ...(cartId ? { quoteId: cartId } : {}),
          }),
        ),
      }
    } else {
      return {
        'X-Magento-Auth-Data': encode(
          JSON.stringify({
            ...(cartId ? { quoteId: cartId } : {}),
            ...(id ? { geolocationId: id } : {}),
          }),
        ),
      }
    }
  }
}
