import { ACTIONS } from '../../../constants'
import { GtmBaseModel, GtmGa4BannerBaseModel } from '../../index'

export class GtmGa4BannerShowModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.VIEW_PROMOTION.GA4)

    const { type } = data

    this.type = type
    this.ecommerce = new GtmGa4BannerBaseModel(data)
  }
}
