import {
  FALLBACK_PAYMENT_METHOD,
  PAY_METHOD,
  PAYMENT_LINK,
  STEP,
} from '../constants/payment'

export class PaymentService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Флаг указывающий, что пользователь авторизован
   */
  get isUserAuthorized() {
    return this.gaApp.stores.user.main.isAuthorized
  }

  /**
   * Флаг, указывающий, что пользователь выбрал подарочную карту для себя
   */
  get isForSelf() {
    return this.gaApp.stores.giftCards.digital.isUserRecipient
  }

  /**
   * Флаг, указывающий, что заказ был изменен
   */
  get orderWasModified() {
    return Boolean(this.gaApp.stores.giftCards.digital.lastModifyFormData)
  }

  /**
   * Содержимое заказа
   */
  get orderData() {
    if (
      !this.gaApp.stores.giftCards.digital.basketId ||
      this.orderWasModified
    ) {
      this.gaApp.services.giftCards.digital.setBasketId()
    }

    const digitalOrderData =
      this.gaApp.services.giftCards.dto.order.getOrderData()

    // Для каждого способа оплаты свой basketId
    digitalOrderData.basketId = `${this.gaApp.stores.giftCards.payment.payMethod.selected}-${digitalOrderData.basketId}`

    if (!digitalOrderData.senderPhone) {
      digitalOrderData.senderPhone =
        this.gaApp.stores.giftCards.payment.recipe.phone
    }

    return digitalOrderData
  }

  /**
   * Сервисы для каждого способа оплаты.
   *
   * Для каждого способа оплаты используйте новый сервис с добавленным в него методом startPayment.
   */
  get payMethodsServices() {
    return {
      [PAY_METHOD.CARD]: this.gaApp.services.giftCards.paymentCard,
      [PAY_METHOD.SBP]: this.gaApp.services.giftCards.paymentSbp,
      [PAY_METHOD.MASTERCARD]: this.gaApp.services.giftCards.paymentMasterCard,
      [PAY_METHOD.MAGNATI]: this.gaApp.services.giftCards.paymentMagnati,
    }
  }

  /**
   * Выбранный сервис для оплаты.
   */
  get selectedPayMethodService() {
    return this.payMethodsServices[
      this.gaApp.stores.giftCards.payment.payMethod.selected
    ]
  }

  /**
   * Получает конфигурацию каждого способа оплаты заказа
   */
  getPayMethodsServicesData() {
    const fetchers = Object.values(this.payMethodsServices).map(
      (payMethodService) => payMethodService.getData?.(),
    )

    return Promise.all(fetchers)
  }

  /**
   * Устанавливает новый шаг.
   *
   * Устанавливает новый шаг и добавляет его в историю шагов. Текущим шагом является текущий из массива
   *
   * @param {STEP} newStep новый шаг, на который нужно перейти
   */
  nextStep(newStep) {
    if (!Object.values(STEP).includes(newStep)) {
      return undefined
    }

    this.gaApp.stores.giftCards.payment.stepHistory.push(newStep)
  }

  /**
   * Шаг назад по истории шагов.
   *
   * Удаляет последний шаг – с которого перешли из истории шагов.
   */
  stepBack() {
    if (this.gaApp.stores.giftCards.payment.stepHistory.length <= 1) {
      return undefined
    }

    this.gaApp.stores.giftCards.payment.stepHistory.pop()
  }

  setStepHistory(steps = []) {
    this.gaApp.stores.giftCards.payment.stepHistory = steps
  }

  /**
   * Устанавливает первый шаг.
   *
   * Если пользователь неавторизован, то первым шагом будет номер телефона для чека.
   * Если шаг уже установлен, то он не будет изменён.
   * В остальных случаях – способ выбора оплаты.
   */
  defineFirstStep() {
    if (
      !this.isForSelf &&
      !this.isUserAuthorized &&
      this.gaApp.stores.giftCards.payment.firstStep !== STEP.SET_RECIPE_PHONE
    ) {
      return this.setStepHistory([STEP.SET_RECIPE_PHONE])
    }

    if (
      (this.isForSelf || this.isUserAuthorized) &&
      this.gaApp.stores.giftCards.payment.firstStep === STEP.SET_RECIPE_PHONE
    ) {
      this.gaApp.stores.giftCards.payment.stepHistory.shift()
    }

    if (!this.gaApp.stores.giftCards.payment.firstStep) {
      this.setStepHistory([STEP.SELECT_PAY_METHOD])
    }
  }

  /**
   * Заменяет текущий шаг на указанный
   *
   * @param {STEP} step шаг, на который необходимо совершить замену
   */
  replaceCurrentStep(step) {
    this.stepBack()
    this.nextStep(step)
  }

  /**
   * Устанавливает предвыбранный способ оплаты у пользователя.
   *
   * Если способ оплаты уже установлен в модальном окне, то он не будет изменен. Когда предыдущая оплата ЭПК производилась картой, будет предвыбрана карта, во всех остальных случаях – СБП.
   */
  definePreselectedPayMethod() {
    if (this.gaApp.stores.giftCards.payment.payMethod.selected) {
      return undefined
    }
    const { defaultPaymentMethod } = this.gaApp.stores.giftCards.payment

    const store = this.gaApp.i18n.locale?.country
    const isQaStore = store === 'qa'
    const isAeStore = store === 'ae'

    const fallBackPaymentMethod =
      isQaStore || isAeStore
        ? FALLBACK_PAYMENT_METHOD[store]
        : FALLBACK_PAYMENT_METHOD.DEFAULT

    this.gaApp.stores.giftCards.payment.payMethod.selected =
      defaultPaymentMethod || fallBackPaymentMethod
  }

  /**
   * Изменяет видимость модального окна
   */
  changeModalVisibility(visibility) {
    this.gaApp.stores.giftCards.payment.modal.isOpen = visibility
  }

  /**
   * Открывает модальное окно оплаты.
   *
   * Перед открытием:
   *
   * - получает конфигурацию по способам оплаты;
   * - определяет первый шаг;
   * - определяет предвыбранный способ оплаты;
   * - переопределяет данные оплаты, если ранее была начата оплата и заказ был изменен.
   */
  async openModal() {
    await this.getPayMethodsServicesData()

    this.defineFirstStep()
    this.definePreselectedPayMethod()

    await this.updatePaymentData()

    this.changeModalVisibility(true)
  }

  /**
   * Закрывает модальное окно.
   */
  closeModal() {
    this.changeModalVisibility(false)
  }

  /**
   * Отображает ошибку на экране выбора способа оплаты.
   *
   * @param {string} text текст ошибки
   */
  setPayMethodError(text) {
    this.gaApp.stores.giftCards.payment.payMethod.error = text
  }

  /**
   * Очищает ошибку
   */
  clearPayMethodError() {
    this.gaApp.stores.giftCards.payment.payMethod.error = ''
  }

  /**
   * Перенаправляет пользователя на страницу пользователя
   */
  redirectToSuccessPage() {
    const query = {
      orderId: this.gaApp.stores.giftCards.payment.orderId,
      paymentType: this.gaApp.stores.giftCards.payment.payMethod.selected,
    }

    // TODO: Заменить на gaApp.services.app.router.pushToRoute после добавления в метод возможности прокидывать параметры для роутера
    this.gaApp.router.push({
      path: PAYMENT_LINK.SUCCESS,
      query,
    })
  }

  /**
   * Начинает оплату выбранным способом.
   *
   * Определяет какой метод должен быть вызван для выбранного способа оплаты и включает загрузку на странице способа оплаты.
   */
  async startPayment() {
    try {
      this.clearPayMethodError()
      this.gaApp.services.giftCards.paymentStatus.stopChecker()
      this.gaApp.stores.giftCards.payment.payMethod.loading = true

      if (typeof this.selectedPayMethodService.startPayment !== 'function') {
        throw new TypeError('The "startPayment" method is not implemented')
      }

      await this.selectedPayMethodService.startPayment()
    } finally {
      this.gaApp.stores.giftCards.payment.payMethod.loading = false
      this.gaApp.services.giftCards.digital.updateAtFormData(0)
    }
  }

  /**
   * Возвращает назад до указанного шага
   *
   * @param {STEP} step шаг, на который необходимо вернуться
   */
  returnToStep(step) {
    const stepHistory = this.gaApp.stores.giftCards.payment.stepHistory
    const selectPayMethodIndex = stepHistory.indexOf(step)

    if (selectPayMethodIndex === -1) {
      return undefined
    }

    this.setStepHistory(stepHistory.slice(0, selectPayMethodIndex + 1))
  }

  /**
   * Пересоздает заказ с новыми данными.
   *
   * Обновляет данные заказа только если:
   * - заказ был изменен после начала оплаты;
   * - пользователь не находится на шагах процесса оплаты;
   */
  async updatePaymentData() {
    const { currentStep } = this.gaApp.stores.giftCards.payment

    if (
      [STEP.SELECT_PAY_METHOD, STEP.SET_RECIPE_PHONE].includes(currentStep) ||
      !this.gaApp.stores.giftCards.payment.orderId ||
      !this.orderWasModified
    ) {
      return undefined
    }

    try {
      await this.startPayment()
    } catch (error) {
      this.returnToStep(STEP.SELECT_PAY_METHOD)
    }
  }
}
