import { filtersStore as filters } from './filters'
import { listingStore as listing } from './listing'
import { mainStore as main } from './main'
import { offerStore as offer } from './offer'
import { offersStore as offers } from './offers'
import { pageStore as page } from './page'

export const stores = (gaApp) => {
  return {
    main: main(gaApp),
    listing: listing(gaApp),
    page: page(gaApp),
    filters: filters(gaApp),
    offers: offers(gaApp),
    offer: offer(gaApp),
  }
}
