import { analytics } from './analytics'
import * as components from './components'
import { repositories } from './repositories'
import { services } from './services'
import { stores } from './stores'

export { useUser } from './composables/user'
export { useUserSavedAddresses } from './composables/saved-addresses'

export const user = {
  meta: {
    name: 'user',
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}
