export const COVER_IMAGE = {
  ar: {
    src: '/_static/img/auth/welcome-tablet-ar.jpg',
    lazy: false,
    sources: [
      {
        srcset: [
          '/_static/img/auth/welcome-tablet-ar.jpg',
          '/_static/img/auth/welcome-tablet-ar@2x.jpg',
        ],
        media: { from: 1120 },
        ext: 'jpeg',
      },
      {
        srcset: [
          '/_static/img/auth/welcome-desktop-ar.jpg',
          '/_static/img/auth/welcome-desktop-ar@2x.jpg',
        ],
        media: { from: 600 },
        ext: 'jpeg',
      },
    ],
  },
  en: {
    src: '/_static/img/auth/welcome-tablet-en.jpg',
    lazy: false,
    sources: [
      {
        srcset: [
          '/_static/img/auth/welcome-tablet-en.jpg',
          '/_static/img/auth/welcome-tablet-en@2x.jpg',
        ],
        media: { from: 1120 },
        ext: 'jpeg',
      },
      {
        srcset: [
          '/_static/img/auth/welcome-desktop-en.jpg',
          '/_static/img/auth/welcome-desktop-en@2x.jpg',
        ],
        media: { from: 600 },
        ext: 'jpeg',
      },
    ],
  },
}

export const BACKGROUND_IMAGE = {
  ar: {
    src: '/_static/img/auth/welcome-background-mobile-ar.jpg',
    lazy: false,
    sources: [
      {
        srcset: [
          '/_static/img/auth/welcome-background-mobile-ar.jpg',
          '/_static/img/auth/welcome-background-mobile-ar@2x.jpg',
        ],
        media: { till: 600 },
        ext: 'jpeg',
      },
    ],
  },
  en: {
    src: '/_static/img/auth/welcome-background-mobile-en.jpg',
    lazy: false,
    sources: [
      {
        srcset: [
          '/_static/img/auth/welcome-background-mobile-en.jpg',
          '/_static/img/auth/welcome-background-mobile-en@2x.jpg',
        ],
        media: { till: 600 },
        ext: 'jpeg',
      },
    ],
  },
}

export const FOREGROUND_IMAGE = {
  ar: {
    src: '/_static/img/auth/welcome-foreground-mobile-ar.png',
    lazy: false,
    sources: [
      {
        srcset: [
          '/_static/img/auth/welcome-foreground-mobile-ar.png',
          '/_static/img/auth/welcome-foreground-mobile-ar@2x.png',
        ],
        media: { till: 600 },
        ext: 'png',
      },
    ],
  },
  en: {
    src: '/_static/img/auth/welcome-foreground-mobile-en.png',
    lazy: false,
    sources: [
      {
        srcset: [
          '/_static/img/auth/welcome-foreground-mobile-en.png',
          '/_static/img/auth/welcome-foreground-mobile-en@2x.png',
        ],
        media: { till: 600 },
        ext: 'png',
      },
    ],
  },
}
