export const SIZE = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
}

export const EVENTS = {
  CLICK_CARD: 'click-card',
  MOUSEENTER_CARD: 'mouseenter-card',
  CLICK_ADD_TO_FAVORITE: 'click-add-to-favorite',
  ADD_TO_FAVORITE: 'add-to-favorite',
  CLICK_ADD_TO_CART: 'click-add-to-cart',
  ADD_TO_CART: 'add-to-cart',
  OPEN_PREVIEW: 'open-preview',
}

export const INFO_INDENT = {
  START: 'start',
  END: 'end',
  BOTH: 'both',
  NONE: 'none',
}
