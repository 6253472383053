import { EVENT_CATEGORY_ADVENT2024 } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmAdvent2024CopyPromocodeButtonClickModel extends GtmBaseModel {
  constructor({ promoCodeName }) {
    super({ eventCategory: EVENT_CATEGORY_ADVENT2024 })

    this.action_type = 'copy_promo_code'
    this.promo_code_name = promoCodeName
  }
}
