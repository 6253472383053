export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getProduct({ itemId }) {
    try {
      const { id: cityId } =
        this.gaApp.services.location.main.getDeliveryAddress()

      const { customerGroupId, groupId } = this.gaApp.services.user.main.data

      const params = {
        itemId,
        cityId,
        customerGroupId: customerGroupId || groupId || '0',
      }

      const { data } =
        await this.gaApp.repositories.info.main.getProduct(params)
      return data
    } catch (e) {
      console.error('getProduct error: ' + e.message)
    }
  }

  async fetchCmsContent(key) {
    const { data } = await this.gaApp.repositories.info.main.getCmsContent(key)
    this.gaApp.stores.info.cms.info = data
  }

  async fetchContactsInfo() {
    const { data } = await this.gaApp.repositories.info.main.fetchContactsInfo()

    this.gaApp.stores.info.contacts.title = data.title
    this.gaApp.stores.info.contacts.description = data.description
    this.gaApp.stores.info.contacts.items = data.items
  }

  async subscribe(firstName, lastName, email, locale = 'launchQa') {
    try {
      if (!firstName || !lastName || !email) {
        throw new Error('The information for subscription is not full')
      }
      if (locale === 'launchAe') {
        await this.gaApp.repositories.info.main.launchAeSubscribe({
          firstName,
          lastName,
          email,
        })
      } else {
        await this.gaApp.repositories.info.main.launchQaSubscribe({
          firstName,
          lastName,
          email,
        })
      }
    } catch (error) {
      const subscribed = error.statusCode === 409

      if (subscribed) {
        const error = new Error('E-mail is already subscribed')
        error.alreadySubscribed = true

        throw error
      }
    }
  }

  async fetchProductsBySku(skuArray = []) {
    const { id: cityId } =
      this.gaApp.services.location.main.getDeliveryAddress()

    const params = {
      itemIds: skuArray.map((item) => item.toString()),
      cityId,
    }

    try {
      const { data } =
        await this.gaApp.repositories.info.main.fetchProductsBySku(params)
      return data.products || []
    } catch (e) {
      console.error('fetchProductsBySku error: ' + e.message)
      return []
    }
  }

  async getXmasUser() {
    try {
      const { data } = await this.gaApp.repositories.info.main.getXmasUser()
      return data
    } catch (e) {
      console.error('getXmasUser error: ' + e.message)
      return {}
    }
  }

  async getXmasTasks() {
    try {
      const { data } = await this.gaApp.repositories.info.main.getXmasTasks()
      return data
    } catch (e) {
      console.error('getXmasTasks error: ' + e.message)
    }
  }

  async openXmasTask(day) {
    try {
      const { data } = await this.gaApp.repositories.info.main.openXmasTask({
        day,
      })
      return data
    } catch (e) {
      console.error('openXmasTask error: ' + e.message)
    }
  }

  async completeXmasTask(day) {
    try {
      const { data } = await this.gaApp.repositories.info.main.completeXmasTask(
        {
          day,
        },
      )
      this.gaApp.stores.info.xmas.userData.promoCodes.push(data.coupon)
      return data
    } catch (e) {
      console.error('completeXmasTask error: ' + e.message)
    }
  }
}
