export const endpoints = {
  cms: (key) => ({
    url: `info/${key}`,
  }),
  contacts: () => ({
    url: 'info/contacts',
  }),
  launchQaSubscribe: () => ({
    url: 'info/launch-qa/subscribe',
  }),
  launchAeSubscribe: () => ({
    url: 'info/launch-qa/subscribe', // TODO: поменять на ae как добавим ручку
  }),
  getProductBase: () => ({
    url: 'catalog/product-card/base',
  }),
  fetchProductsBySku: () => ({
    url: 'catalog/products-by-sku',
  }),
  getXmasUser: () => ({
    url: 'advent/user',
  }),
  getXmasTasks: () => ({
    url: 'advent/tasks',
  }),
  openXmasTask: () => ({
    url: 'advent/task/open',
  }),
  completeXmasTask: () => ({
    url: 'advent/task/complete',
  }),
}
