import { propValidator } from '../../../utils'

import { SIZE } from './scripts/consts'
import { labels as schemaLabels } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-labels',

  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
      validator: (value) => propValidator(value, schemaLabels),
    },

    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },
  },
}
