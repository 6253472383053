import { ACTIONS } from '../../../constants'
import { MindboxBaseModel } from '../mindbox-base.model/mindbox-base.model'

export class MindboxPageViewModel extends MindboxBaseModel {
  constructor({ category }, country) {
    const { id } = category
    const operation = ACTIONS.VIEW.MINDBOX[country]

    super({ operation })

    this.data = {
      viewProductCategory: {
        productCategory: {
          ids: {
            website: id.toString(),
          },
        },
      },
    }
  }
}
