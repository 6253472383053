import isEmpty from 'lodash/isEmpty'
import objectHash from 'object-hash'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Детализированный заказ
   */

  async requestOrderDetail(id) {
    try {
      const response =
        await await this.gaApp.repositories.customer.main.fetchOrderDetailV3(id)

      this.gaApp.stores.customer.order.data = response.data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.orders.error.order'),
      )

      throw error
    }
  }

  async cancelOrder(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.cancelOrderV3(id)

      this.gaApp.stores.customer.order.data = response.data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.order.error.cancel'),
      )
    }
  }

  async canCancelOrder(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.canCancelOrderV3(id)

      const { canCancel } = response.data

      this.gaApp.stores.customer.order.canCancel = response.data

      if (canCancel) {
        this.gaApp.services.customer.api.cancelOrder(id)
      } else {
        this.gaApp.services.customer.dialog.openOrderCancelDefaultError()
      }
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.order.error.cancel'),
      )
    }
  }

  async getChangeDeliveryInfo(id) {
    try {
      this.gaApp.stores.customer.main.changeDeliveryModal.isLoading = true

      const response =
        await this.gaApp.repositories.customer.main.getChangeDeliveryInfo(id)

      const { possibleDates, additionalInfo } = response.data

      if (isEmpty(possibleDates) && isEmpty(additionalInfo)) {
        throw new Error(
          this.gaApp.i18n.t('orders.order.changeDeliveryModal.error'),
        )
      }

      this.gaApp.services.customer.order.setChangeDeliveryModalData(
        response.data,
      )
      this.gaApp.stores.customer.main.changeDeliveryModal.orderId = id

      this.gaApp.services.customer.order.toggleChangeDeliveryModal(true)
    } catch (error) {
      const slotText = error.localizedMessage
        ? error.localizedMessage
        : this.gaApp.i18n.t('orders.order.changeDeliveryModal.error')

      this.gaApp.services.notification.main.open({
        slotText,
        data: {
          props: {
            alert: !error.localizedMessage,
          },
        },
      })
    } finally {
      this.gaApp.stores.customer.main.changeDeliveryModal.isLoading = false
    }
  }

  /**
   * Нужен для изменения текущей даты доставки на новую
   */
  async changeDeliveryDate(data) {
    try {
      this.gaApp.stores.customer.main.changeDeliveryModal.saveButtonLoading = true

      const response =
        await this.gaApp.repositories.customer.main.changeDeliveryDate(data)

      this.gaApp.stores.customer.order.data = response.data

      // Закрываем модалку после успешного изменения даты
      this.gaApp.services.customer.order.toggleChangeDeliveryModal(false)

      this.gaApp.services.customer.order.scrollToOrderById(data.orderId, {
        duration: 300,
        offset: -200,
      })
    } catch (error) {
      this.gaApp.services.notification.main.open({
        data: {
          props: {
            text: this.gaApp.i18n.t(
              'orders.order.changeDeliveryModal.delivery.error',
            ),
            alert: true,
          },
        },
      })
    } finally {
      this.gaApp.stores.customer.main.changeDeliveryModal.saveButtonLoading = false
    }
  }

  /**
   *  Листинг заказов (список заказов с фильтрами)
   */
  async getOrdersListing() {
    this.gaApp.services.filters.main.resetState()

    const { data: filtersData } = await this.requestFiltersOrders()

    const query = this.gaApp.route.query

    const filtersDataNormalized = filtersData.filters.map((filter) => ({
      ...filter,
      key: filter.id,
    }))

    const filtersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(
        filtersDataNormalized,
        query,
      )

    this.gaApp.stores.filters.main.setFiltersSelected(filtersFromQuery)

    const page = this.gaApp.stores.customer.orders.lastViewedPage

    this.gaApp.stores.filters.main.setFiltersHash({
      filters: filtersFromQuery,
    })

    const response =
      await this.gaApp.repositories.customer.main.fetchOrdersListingV3({
        pageSize: 24,
        pageNumber: page,

        filters: filtersFromQuery,
      })

    const { orders, countPage, count, completedCount, countSelectedFilters } =
      response.data.orders

    const { analytics, filters, productsCount } = response.data.filters

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
      key: filter.id,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)

    this.gaApp.stores.customer.orders.completedCount = completedCount
    this.gaApp.stores.customer.orders.firstCompletedOrderIndex =
      count - completedCount
    this.gaApp.stores.customer.orders.count = count

    this.gaApp.services.customer.orders.clearPages()

    if (orders.length) {
      this.gaApp.stores.customer.orders.hasOrders = !!orders.length

      this.gaApp.services.customer.orders.setPage({
        number: page,
        orders: this.gaApp.services.customer.orders.normalizeOrders(orders),
      })
    }

    const setOrdersHash = () => {
      this.gaApp.stores.customer.orders.ordersHash = objectHash(
        {
          filters: filtersFromQuery,
        },
        { unorderedArrays: true },
      )
    }

    const setCountSelectedFilters = () =>
      this.gaApp.stores.filters.main.setCountSelectedFilters(
        countSelectedFilters,
      )

    const setPageCount = () => {
      this.gaApp.stores.customer.orders.totalPages = countPage
    }

    this.gaApp.services.app.scheduler.postTasks([
      { task: setOrdersHash },
      { task: setCountSelectedFilters },
      { task: setPageCount },
    ])
  }

  /**
   * Фильтры заказов
   */

  async requestFiltersOrders() {
    try {
      this.gaApp.stores.filters.main.setFiltersPending(true)

      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setFiltersHash({
        filters: filtersSelected,
      })

      const response =
        await this.gaApp.repositories.customer.main.fetchFiltersV3({
          filters: filtersSelected,
        })

      const { analytics, filters, productsCount } = response.data

      const filtersNormalized = filters.map((filter) => ({
        ...filter,
        key: filter.id,
      }))

      this.gaApp.stores.filters.main.setFilters(filtersNormalized)
      this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
      this.gaApp.stores.filters.main.setAnalytics(analytics)

      this.gaApp.stores.filters.main.setFiltersPending(false)

      return response
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.orders.error.filters'),
      )

      this.gaApp.stores.filters.main.setFiltersPending(false)
    }
  }

  /**
   * Заказы
   */
  async requestOrders() {
    this.gaApp.services.filters.main.resetState()

    const page = this.gaApp.stores.customer.orders.lastViewedPage

    await Promise.all([
      this.fetchOrdersByPage({ page }),
      this.requestFiltersOrders(),
    ])
  }

  async fetchOrdersByPage({ page = 1 }) {
    try {
      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setCountSelectedFilters(
        this.gaApp.stores.filters.main.getFiltersMainSelected.length,
      )

      const response =
        await this.gaApp.repositories.customer.main.fetchOrdersV3({
          pageSize: 24,
          pageNumber: page,

          filters: filtersSelected,
        })

      const { orders, countPage, count, completedCount, countSelectedFilters } =
        response.data

      this.gaApp.stores.customer.orders.completedCount = completedCount
      this.gaApp.stores.customer.orders.firstCompletedOrderIndex =
        count - completedCount
      this.gaApp.stores.customer.orders.count = count

      if (orders.length) {
        this.gaApp.stores.customer.orders.hasOrders = !!orders.length

        this.gaApp.services.customer.orders.setPage({
          number: page,
          orders: this.gaApp.services.customer.orders.normalizeOrders(orders),
        })
      }

      const setOrdersHash = () => {
        this.gaApp.stores.customer.orders.ordersHash = objectHash(
          {
            filters: filtersSelected,
          },
          { unorderedArrays: true },
        )
      }

      const setCountSelectedFilters = () =>
        this.gaApp.stores.filters.main.setCountSelectedFilters(
          countSelectedFilters,
        )

      const setPageCount = () => {
        this.gaApp.stores.customer.orders.totalPages = countPage
      }

      this.gaApp.services.app.scheduler.postTasks([
        { task: setOrdersHash },
        { task: setCountSelectedFilters },
        { task: setPageCount },
      ])
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.orders.error.ordersPage'),
      )
    }
  }
  /**
   * Доставка по клику
   */

  async onDemandOrderDetailV3(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.onDemandOrderDetailV3(id)

      this.gaApp.stores.customer.order.data = response.data
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('orders.error.onDemand'),
      )
    }
  }

  async onDemandOrderV3(id) {
    try {
      const response =
        await this.gaApp.repositories.customer.main.onDemandOrderV3(id)

      this.gaApp.stores.customer.orders.updatedOrder = response.data
      this.gaApp.services.customer.orders.updateOrders()
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('orders.error.onDemand'),
      )
    }
  }

  async getProfileInfo() {
    const { data } = await this.gaApp.repositories.customer.main.getInfo()
    this.gaApp.services.customer.edit.setFormData(data)
    this.gaApp.services.customer.edit.setAdditionalData(data)
  }

  async saveProfileInfo(formData) {
    try {
      this.gaApp.stores.customer.edit.isSaving = true

      const { data } =
        await this.gaApp.repositories.customer.main.updateInfo(formData)

      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.edit.notification.success'),
      )

      await this.gaApp.services.user.api.fetchUserInfoFull()

      if (this.gaApp.features.get('enableProfileBanner')) {
        await this.fetchLoyaltyBanner()
      }

      this.gaApp.services.customer.edit.setFormData(data)
      this.gaApp.services.customer.edit.setAdditionalData(data)

      this.gaApp.services.customer.edit.setWasSaved(true)

      return data
    } catch (error) {
      const statusCode = error.statusCode
      const isErrorValidation = statusCode === 400
      const errorData = error.data?.data

      if (isErrorValidation) {
        this.gaApp.services.customer.edit.handleValidationError(errorData)
      } else {
        this.gaApp.services.notification.main.open(
          this.gaApp.i18n.t('customer.edit.notification.error'),
        )
      }

      return null
    } finally {
      this.gaApp.stores.customer.edit.isSaving = false
    }
  }

  /**
   * Обновление подписок
   * @param {object} body Измененное состояние подписок
   */
  async updateSubscriptionsSettings(body) {
    try {
      this.gaApp.stores.customer.settings.settingsPending = true

      // Отправляем измененнное состояние подписок в плед
      const { data } =
        await this.gaApp.repositories.customer.main.updateSubscriptionsSettings(
          body,
        )

      // Записываем актуальное состояние подписок в стор
      this.gaApp.services.customer.settings.setSubscriptionsSettings(data)

      // Показываем уведомление об успешном изменении подписок
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.settings.updateSettingsSuccess'),
      )
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.settings.error.updateSettings'),
      )
    } finally {
      this.gaApp.stores.customer.settings.settingsPending = false
    }
  }

  async fetchLoyalty() {
    const { data } = await this.gaApp.repositories.customer.main.getLoyalty()

    const { bonus, title, currentLevel } = data

    if (isEmpty(bonus) || !title || !currentLevel) {
      throw new Error('Ошибка')
    }

    this.gaApp.services.customer.cards.setLoyalty(data)
  }

  async fetchLoyaltyBanner() {
    try {
      const { data } =
        await this.gaApp.repositories.customer.main.getLoyaltyBanner()

      this.gaApp.services.customer.cards.setLoyaltyBanner(data)
    } catch (error) {
      this.gaApp.stores.customer.cards.loyaltyBanner = null
    }
  }

  async emailConfirm() {
    try {
      await this.gaApp.repositories.customer.main.emailConfirm()

      if (!this.gaApp.stores.customer.edit.isEmailConfirmModalOpened) {
        this.gaApp.services.customer.edit.openEmailConfirmModal()
      }

      this.gaApp.analytics.modules.giftCardsB2b.onMailConfirmed()
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('customer.edit.confirmEmail.error'),
      )
    }
  }

  /**
   * Получение подписок
   */
  async getSubscriptionsSettings() {
    // Получаем данные из пледа
    const { data } =
      await this.gaApp.repositories.customer.main.getSubscriptionsSettings()

    // Записываем данные о подписках в стор
    this.gaApp.services.customer.settings.setSubscriptionsSettings(data)
  }

  /**
   * Получение дат для изменения срока хранения заказа
   */
  async getChangeStorageInfo(id) {
    try {
      // Устанавливаем значение индикатора загрузки в true
      this.gaApp.stores.customer.main.changeDeliveryModal.isLoading = true

      // Получаем данные о переносе срока хранения заказа
      const { data } =
        await this.gaApp.repositories.customer.main.getChangeStorageInfo(id)

      const { additionalInfo, possibleDates } = data

      // Если поля additionalInfo и possibleDates пусты/null - выбрасываем ошибку
      if (isEmpty(additionalInfo) && isEmpty(possibleDates)) {
        throw new Error(
          this.gaApp.i18n.t('orders.order.changeDeliveryModal.error'),
        )
      }
      // Записываем полученные данные в стор
      this.gaApp.services.customer.order.setChangeDeliveryModalData(data)

      // Сохраняем id заказа
      this.gaApp.stores.customer.main.changeDeliveryModal.orderId = id

      // Открываем модальное окно переноса срока хранения заказа
      this.gaApp.services.customer.order.toggleChangeDeliveryModal(true)
    } catch (error) {
      // Формируем конфиг для нотификации ошибки в зависимости от ответа

      const slotText = error.localizedMessage
        ? error.localizedMessage
        : this.gaApp.i18n.t('orders.order.changeDeliveryModal.error')

      // Открываем нотификацию с информацией об ошибке
      this.gaApp.services.notification.main.open({
        slotText,
        data: {
          props: {
            alert: !error.localizedMessage,
          },
        },
      })
    } finally {
      // Устанавливаем значение индикатора загрузки в false
      this.gaApp.stores.customer.main.changeDeliveryModal.isLoading = false
    }
  }

  /**
   * Изменение срока хранения заказа
   */
  async changeStorageDate(body) {
    try {
      // Отправляем запрос на перенос срока хранения заказа
      const response =
        await this.gaApp.repositories.customer.main.changeStorageDate(body)

      // Обновляем детальную информацию о заказе в сторе
      this.gaApp.stores.customer.order.data = response.data

      // Закрываем модалку
      this.gaApp.services.customer.order.toggleChangeDeliveryModal(false)

      // Скролл до заказа
      this.gaApp.services.customer.order.scrollToOrderById(body.orderId, {
        duration: 300,
        offset: -200,
      })
    } catch (error) {
      // Открываем нотификацию  об ошибке
      this.gaApp.services.notification.main.open({
        data: {
          props: {
            text: this.gaApp.i18n.t(
              'orders.order.changeDeliveryModal.storage.error',
            ),
            alert: true,
          },
        },
      })
    }
  }
}
