import chunk from 'lodash/chunk'

import { CHANGE_DELIVERY_TYPES } from '../../../constants'
import {
  BannerClickBuilder,
  BannerViewBuilder,
  LogoutBuilder,
  MenuItemClickBuilder,
  OrderInteractionBuilder,
  OrderProductClickBuilder,
  OrderProductViewBuilder,
  PageViewBuilder,
  UserAccountBuilder,
} from '../../builders'
import { UserInteractionBuilder } from '../../builders/user-interaction.builder/user-interaction.builder'
import {
  ACTION_TYPES,
  CHUNK_SIZE_ANALYTICS,
  EVENT_CATEGORIES,
  SCREEN_TYPES,
} from '../../constants'
import { OrderProductModel } from '../../models'
import { BaseController } from '../base.controller/base.controller'

export class CustomerAnalytics extends BaseController {
  constructor(gaApp, core) {
    super(gaApp, core)

    this.viewedOrderProducts = []
  }

  /**
   * Клик по товару в заказе
   */
  onOrderProductClick(clickedItem) {
    const index = this.viewedOrderProducts.filter(
      (item) => item.item_id === clickedItem.itemId,
    )[0]?.index

    this.send(OrderProductClickBuilder, {
      ...clickedItem,
      index,
    })
  }

  sendLastChunk() {
    const chunks = chunk(this.viewedOrderProducts, CHUNK_SIZE_ANALYTICS)
    const lastChunk = chunks[chunks.length - 1]

    this.send(OrderProductViewBuilder, lastChunk)
  }

  /**
   * Просмотр товаров на странице детализированного заказа
   */
  onOrderProductView(product) {
    this.viewedOrderProducts.push(new OrderProductModel(product))
    this.viewedOrderProducts[this.viewedOrderProducts.length - 1].index =
      this.viewedOrderProducts.length

    if (this.viewedOrderProducts.length % CHUNK_SIZE_ANALYTICS === 0) {
      this.sendLastChunk()
    }
  }

  /**
   * Клик по пункту меню
   */
  onMenuItemClick(data) {
    this.send(MenuItemClickBuilder, data)
  }

  /**
   * Клик по инфо о пользователе
   */
  onUserInfoClick(data) {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.USER_INFO,
      screenType: SCREEN_TYPES.ACCOUNT,
    })
  }

  /**
   * Клик на кнопку "Повторить заказ" в детальной информации по заказу
   */
  onClickRepeatOrder(data) {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_REPEAT_ORDER,
      screenType: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Пользователь тапает на избранное в Таббаре внизу экрана, или переходит из ЛК
   */
  onOpenWishlist(from) {
    this.send(UserInteractionBuilder, {
      event_category: EVENT_CATEGORIES.OPEN_WISHLIST,
      from,
    })
  }

  /**
   * Клик на кнопку "Оценить покупку" в детальной информации по заказу
   */
  onClickRateOrder(data) {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_RATE_ORDER,
      screenType: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Клик на кнопку "Отменить заказ" в детальной информации по заказу
   */
  onClickCancelOrder(data) {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_CANCEL_ORDER,
      screenType: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Клик на кнопку "Подробнее о пункте выдачи"
   */
  onClickAboutPickUpPoint(data) {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_ABOUT_PICK_UP_POINT,
      screenType: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Клик на кнопку "Перейти к покупкам" (если нет заказов)
   */
  onClickBackToShopping() {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_BACK_TO_SHOPPING,
      screenType: SCREEN_TYPES.ACCOUNT_MY_PURCHASES,
    })
  }

  /**
   * Клик на кнопку "Оформить возврат" в детальной информации по заказу
   */
  onClickReturnOrder(data) {
    this.send(UserAccountBuilder, {
      actionType: ACTION_TYPES.CLICK_RETURN_ORDER,
      screenType: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Клик на кнопку "Привезти сейчас" в листинге
   */
  onClickOnDemandOrder() {
    this.send(OrderInteractionBuilder, {
      action_type: ACTION_TYPES.CLICK_BRING_NOW,
      screen_type: SCREEN_TYPES.ACCOUNT_MY_PURCHASES,
    })
  }

  /**
   * Клик на кнопку "Привезти сейчас" в детализированном заказе
   */
  onClickOnDemandOrderDetails() {
    this.send(OrderInteractionBuilder, {
      action_type: ACTION_TYPES.CLICK_BRING_NOW,
      screen_type: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Пользователь применил фильтры в разделе "мои покупки"
   */
  onApplyOrdersFilters(data) {
    this.send(UserInteractionBuilder, {
      actionType: ACTION_TYPES.APPLY_FILTERS,
      screenType: SCREEN_TYPES.ACCOUNT_MY_PURCHASES,
      event_category: EVENT_CATEGORIES.FILTERS,
      ...this.gaApp.services.filters.main.getFormattedForOrdersFilterList(),
    })
  }

  /**
   * При уходе со страницы детализированного заказа
   */
  onOrderPageLeaveProductView() {
    if (this.viewedOrderProducts.length % CHUNK_SIZE_ANALYTICS !== 0) {
      this.sendLastChunk()
    }
    this.viewedOrderProducts = []
  }

  /**
   * При входе на страницу PLP
   */
  onMounted(data) {
    this.send(PageViewBuilder, data)
  }

  /**
   * Клик по сгорающим бонусам на карте лояльности
   */
  onExpireBonusesClick() {
    this.send(UserInteractionBuilder, {
      event_category: EVENT_CATEGORIES.ACCOUNT,
      action_type: 'click_expiring_bonuses',
      screen_type: SCREEN_TYPES.ACCOUNT,
    })
  }

  /**
   * Клик по иконке инфо на карте лояльности
   */
  onLoyaltyInfoClick() {
    this.send(UserInteractionBuilder, {
      event_category: EVENT_CATEGORIES.ACCOUNT,
      action_type: 'click_bonus_rules',
      screen_type: SCREEN_TYPES.ACCOUNT,
    })
  }

  /**
   * Наличие сгорающих бонусов
   */
  onExpireBonusesView(hasBonuses) {
    this.send(UserInteractionBuilder, {
      event_category: EVENT_CATEGORIES.ACCOUNT,
      action_type: 'show_expiring_bonuses',
      screen_type: SCREEN_TYPES.ACCOUNT,
      availability: Number(hasBonuses),
    })
  }

  /**
   * Переключение тогглов настроек уведомлений
   */
  onSettingsToggleClick({ text, status }) {
    this.send(UserInteractionBuilder, {
      event_category: EVENT_CATEGORIES.ACCOUNT,
      action_type: 'click_set_notification',
      text,
      status,
    })
  }

  /**
   * Пользователь вышел из профиля
   */
  onLogoutClick() {
    this.send(LogoutBuilder)
  }

  /**
   * Клик по кнопке "изменить дату доставки/срок хранения"
   */
  onChangeDeliveryButtonClick() {
    const isDeliveryType =
      this.gaApp.stores.customer.main.changeDeliveryModal.deliveryType ===
      CHANGE_DELIVERY_TYPES.DELIVERY

    this.send(OrderInteractionBuilder, {
      action_type: isDeliveryType
        ? 'click_change_date_delivery'
        : 'click_change_date_storage',
      screen_type: SCREEN_TYPES.ACCOUNT_ORDER,
    })
  }

  /**
   * Клик по кнопке "сайт службы доставки" в инфо модалке
   * изменения даты доставки/срока хранения
   */
  onDeliveryServiceButtonClick() {
    const isDeliveryType =
      this.gaApp.stores.customer.main.changeDeliveryModal.deliveryType ===
      CHANGE_DELIVERY_TYPES.DELIVERY

    this.send(OrderInteractionBuilder, {
      screen_type: SCREEN_TYPES.ACCOUNT_ORDER,
      action_type: isDeliveryType
        ? 'click_change_date_delivery_on_website'
        : 'click_change_date_storage_on_website',
    })
  }

  /**
   * Клик по кнопке "сохранить" в модалке изменения даты доставки/срока хранения
   */
  onChangeDeliveryDate(data) {
    const isDeliveryType =
      this.gaApp.stores.customer.main.changeDeliveryModal.deliveryType ===
      CHANGE_DELIVERY_TYPES.DELIVERY

    this.send(OrderInteractionBuilder, {
      screen_type: SCREEN_TYPES.ACCOUNT_ORDER,
      action_type: isDeliveryType
        ? 'click_save_new_date_delivery'
        : 'click_save_new_date_storage',
      date_old: isDeliveryType
        ? `${data.oldDate}/${data.oldTime}`
        : data.oldDate,
      date_new: isDeliveryType
        ? `${data.newDate}/${data.newTime.from}-${data.newTime.to}`
        : data.newDate,
    })
  }

  /**
   * Пользователь увидел кнопку изменения даты доставки/срока хранения
   */
  onChangeDeliveryButtonView() {
    const isDeliveryType =
      this.gaApp.stores.customer.main.changeDeliveryModal.deliveryType ===
      CHANGE_DELIVERY_TYPES.DELIVERY

    this.send(OrderInteractionBuilder, {
      screen_type: SCREEN_TYPES.ACCOUNT_ORDER,
      action_type: isDeliveryType
        ? 'view_change_date_delivery'
        : 'view_change_date_storage',
    })
  }

  /**
   * Просмотр баннера
   */
  onBannerView(data) {
    this.send(BannerViewBuilder, {
      creative_link: data.link,
      promotion_name: data.title,
    })
  }

  /**
   * Клик по баннеру
   */
  onBannerClick(data) {
    this.send(BannerClickBuilder, {
      creative_link: data.link,
      promotion_name: data.title,
    })
  }
}
