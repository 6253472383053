import { cmsPageStore } from './cms'
import { contactsPageStore } from './contacts'
import { launchMeStore } from './launch-me'
import { lrpEffaclarStore } from './lrp-effaclar'
import { puigStore } from './puig'
import { xmasStore } from './xmas'

export const stores = (gaApp) => {
  return {
    cms: cmsPageStore(gaApp),
    contacts: contactsPageStore(gaApp),
    launchMe: launchMeStore(gaApp),
    lrpEffaclar: lrpEffaclarStore(gaApp),
    xmas: xmasStore(gaApp),
    puig: puigStore(gaApp),
  }
}
