export const BP_MOBILE_SMALL = 340 // $bp-mobile-small
export const BP_TABLET = 768 // $bp-tablet
export const BP_DESKTOP_SMALL = 1120 // $bp-desktop-small

export const ARTICLES_PAGE_CHUNK_SIZE = 18
export const STREAMS_PAGE_CHUNK_SIZE = 16
export const PRODUCT_LISTING_PAGE_SIZE = 24
export const CHUNK_SIZE_ANALYTICS = 1

// Количество изображений в карточках листинга, которые
// должны быть загружены перед показом листинга
export const COUNT_OF_LOAD_IMAGES = 4
export const COUNT_OF_LOAD_IMAGES_WEBVIEW = 2

export const URL_FILTER_TYPE = {
  rubric: 'rubrics',
  tag: 'tags',
}

export const BACK_FILTER_TYPE = {
  rubric: 'rubric',
  tag: 'tag',
}

export const FILTER_TYPE = {
  rubric: 'rubric',
  tag: 'tag',
}

const FLACON_PREFIX_URL = 'flacon'

export const ARTICLES_BASE_URL = `/${FLACON_PREFIX_URL}/articles`
export const ARTICLE_BASE_URL = `/${FLACON_PREFIX_URL}/article/`
export const ARTICLE_PREVIEW_BASE_URL = `/${FLACON_PREFIX_URL}/preview/`

export const STREAMS_BASE_URL = `/${FLACON_PREFIX_URL}/live`
export const STREAM_BASE_URL = `/${FLACON_PREFIX_URL}/live/:url`
export const STREAM_PREVIEW_BASE_URL = `/${FLACON_PREFIX_URL}/preview/live/`

export const STREAMS_RUBRIC_URL = `/${STREAMS_BASE_URL}/rubric`

export const URL_PAGE_KEY = 'p'

export const WEB_VIEW_QUERY_PARAM = {
  shareable: 'shareable',
  iconColorNav: 'icon-color-nav',
}

export const WEB_VIEW_ICON_COLOR = {
  white: '000000',
  black: 'FFFFFF',
}

export const SEO_ATTRS_KEY = {
  TITLE: 'title',
  DESCRIPTION: 'description',
}

export const LOAD_DIR = {
  NEXT: 'next',
  PREV: 'prev',
}

export const PAGE_TYPE = {
  ARTICLE: 'article',
  STREAM: 'stream',
}

export const LISTING_PAGE_TYPE = {
  ARTICLES: 'articles',
  STREAMS: 'streams',
}

export const LISTING_SERVICE_KEY = {
  ARTICLES: 'listing',
  STREAMS: 'streams',
}

export const BANNER_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const COVER_CAPTION_COLOR = {
  WHITE: 'white',
  BLACK: 'black',
}
