import { TYPE } from '../../constants'
import { GtmAdvent2024NextButtonInQuizButtonClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class Advent2024NextButtonInQuizButtonClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmAdvent2024NextButtonInQuizButtonClickModel],
    ])
    super(models, data)
  }
}
