import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ListingViewChangeModel extends GtmBaseModel {
  constructor(data) {
    super({ event: 'user_interaction' })

    const { plpType, itemListName } = data

    this.event_category = 'plp'
    this.action_type = 'click_catalog_view'
    this.plp_type = plpType
    this.item_list_name = itemListName
  }
}
