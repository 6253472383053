<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path stroke="#fff" stroke-width="1.25" d="M2 7.5h11M7.5 13V2" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-plp-plus',
}
</script>
