import { EVENT_CATEGORY_ADVENT2024 } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmAdvent2024PromocodesButtonClickModel extends GtmBaseModel {
  constructor() {
    super({ eventCategory: EVENT_CATEGORY_ADVENT2024 })

    this.action_type = 'click_promocodes'
  }
}
