import merge from 'lodash/merge'

import layouts from '~/locales/languages/layouts/en'
import layoutsEnSa from '~/locales/languages/layouts/en-SA'

export default () => {
  return {
    ...merge(layouts, layoutsEnSa),
  }
}
