import { log } from './eyezon.utils'
import { scriptInjecting } from '@ga/shared-browser'

export class Eyezon {
  constructor() {
    // singleton
    if (!!Eyezon.instance) {
      return Eyezon.instance
    }

    Eyezon.instance = this

    this.isInit = false

    this.setGlobalEyezon()
  }

  /**
   *  Метод добавляет экземпляр класса в глобальную область
   * видимости window.eyeZon, если она еще не существует.
   */
  setGlobalEyezon() {
    if (window && !window.eyeZon) {
      window.eyeZon = this.eyeZon
    }
  }

  /**
   * Основной метод инициализации библиотеки eyeZon. Он проверяет,
   * была ли уже инициализирована библиотека, и если нет,загружает
   * скрипт eyeZon на страницу с помощью метода scriptInjecting.inject.
   * Затем он инициализирует eyeZon с переданными параметрами payload и
   * устанавливает флаг isInit
   */
  async init(payload = {}) {
    try {
      if (this.isInit) {
        log('Модуль уже проинициализирован', payload)

        return false
      }

      const { scriptUrl, scriptAsync } = payload
      // Загружем на страницу скрипт
      const url = scriptUrl || '/eyezone/static/js/eyezonwidget.js'

      const options = { async: scriptAsync || true, id: 'eyeZone' };
      const debug = false;

      await scriptInjecting.inject(url, options)
      this.eyeZon('init', {
        ...(payload || {}),
        live: true,
      })

      if (payload.debug) {
        this.setDebugProperty()
      }

      this.isInit = true
      log('Инициализация прошла успешно', payload)
    } catch (error) {
      log('Ошибка инициализации', error)

      throw error
    }
  }

  // Геттер, который возвращает глобальный объект window.eyeZon
  get _globalEyeZon() {
    return window.eyeZon
  }

  /**
   * Геттер, который возвращает либо глобальный объект window.eyeZon,
   * если библиотека была инициализирована, либо функцию-обертку,
   * которая кэширует вызовы в массиве window.eyeZon.q для последующей
   * обработки после инициализации.
   */
  get eyeZon() {
    if (this.isInit) {
      return this._globalEyeZon
    }

    return function () {
      if (!window?.eyeZon?.q) {
        window.eyeZon.q = []
      }

      window.eyeZon.q.push(arguments)
    }
  }

  // Метод, который вызывает метод reset библиотеки eyeZon, если она была инициализирована.
  reset() {
    if (this.isInit) {
      this.eyeZon('reset')
    }
  }

  // Метод, который устанавливает свойства в глобальной области видимости для дебага.
  setDebugProperty() {
    // https://eyezon.notion.site/debugging-f0b237471dc2457c84cb3d16722139ae
    window.eyezon_IgnoreSettingsCache = true
    window.eyezon_IsTestBot = true
    window.eyeZon_NoExecutionDelay = true
    window.eyezon_TurnOnLogging = true
  }
}
