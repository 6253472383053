export const actions = () => ({
  resetAddress() {
    /** Очистка заполненного адреса */
    Object.entries(this.deliveryInfo?.addressForm).forEach(([key]) => {
      this.deliveryInfo.addressForm[key] = ''
    })
  },

  /** Очистка адреса курьерской доставки */
  resetAddressString() {
    this.deliveryInfo.addressString = ''
  },

  /** Сброс выбранного способа доставки */
  resetDeliveryType() {
    this.deliveryInfo.type = ''
  },

  /** Сброс комментария */
  resetComment() {
    this.deliveryInfo.comment = ''
  },

  /** Сброс города */
  resetCity() {
    this.config.city = ''
  },

  /** Сброс формы контрагента */
  resetCounterpartyForm() {
    this.counterparty.form = {
      inn: '',
      agreed: false,
      approved: false,
    }
  },

  /** Сброс формы контактов */
  resetContactsForm() {
    this.contacts.form = {
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      recipientName: '',
      recipientPhone: '',
      document: null,
    }
  },

  /** Сброс данных формы */
  resetFormData() {
    this.resetCounterpartyForm()
    this.resetContactsForm()
    this.resetComment()
    this.resetCity()
  },
})
