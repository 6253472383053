import flow from 'lodash/flow'

import { mapProduct } from '~/modules/product-card'

import { LISTING_MAX_PAGE_INFINITE_LOADER } from '../constants'

export class ListingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  fillStore(block) {
    this.gaApp.stores.brandzone.listing.$reset()

    this.gaApp.stores.brandzone.listing.name = block.data?.name ?? ''
    this.gaApp.stores.brandzone.listing.count = block.data?.count ?? 0
    this.gaApp.stores.brandzone.listing.layout = block.data?.layout ?? ''

    if (block.data?.products?.length) {
      this.gaApp.stores.brandzone.listing.page += 1
      this.gaApp.stores.brandzone.listing.pages.push(block.data?.products)
    }
  }

  async getNextPage(slotData) {
    this.gaApp.stores.brandzone.listing.pagination.pending = true
    this.gaApp.stores.brandzone.listing.pagination.show = true

    if (
      this.gaApp.stores.brandzone.listing.pagination.page ===
      LISTING_MAX_PAGE_INFINITE_LOADER
    ) {
      this.gaApp.stores.brandzone.listing.pagination.page = 0
    }

    const page = await this.gaApp.services.brandzone.api.fetchListing({
      slotData,
      page: this.gaApp.stores.brandzone.listing.page + 1,
    })

    this.gaApp.stores.brandzone.listing.pagination.pending = false

    if (page.data === null) {
      return
    }

    if (page.data.products.length > 0) {
      this.gaApp.stores.brandzone.listing.pages.push(page.data.products)
    } else {
      this.gaApp.stores.brandzone.listing.pagination.end = true
    }

    this.gaApp.stores.brandzone.listing.page++
    this.gaApp.stores.brandzone.listing.pagination.page++
    if (
      this.gaApp.stores.brandzone.listing.pagination.page !==
      LISTING_MAX_PAGE_INFINITE_LOADER
    ) {
      this.gaApp.stores.brandzone.listing.pagination.show = false
    }
  }

  /**
   * Мапим продукт, добавляя к нему видеоролики, если они есть.
   *
   * @param {Object} product - исходный продукт
   * @returns {Object} - обогащенный продукт
   */
  mapListingItem(product) {
    return flow(
      // Мапим продукт используя ф-ию из модуля product-card
      mapProduct,
      // Добавляем короткие видеоролики, если есть
      this.prependShortVideo.bind(this, product),
      // Добавляем videoState, при необходимости
      this.setVideoVisibleState.bind(this),
    )(product)
  }

  /**
   * Добавляем короткие видео в массив медиа.
   *
   * @param {Object} продукт - объект продукта
   * @returns {Object} продукт с добавленным видеороликом, если выполнены условия
   */
  prependShortVideo(originalProduct, mappedProduct) {
    const includeShortVideos =
      this.gaApp.features.get('bzHowToShortVideoOn') && this.gaApp.isClient

    if (originalProduct.shortVideos && includeShortVideos) {
      const inStock = originalProduct.inStock

      originalProduct.shortVideos.forEach((video) => {
        // Показываем видео, только если товар в наличии
        const source = inStock && { video, priority: true }

        if (source) {
          mappedProduct.media.splice(video.position || 0, 0, source)
        }
      })
    }

    return mappedProduct
  }

  /**
   * Добавляем для продукта videoState, если в массиве медиа есть видео
   *
   * @param {Object} product - объект продукта
   * @returns {Object} продукт, без изменений
   */
  setVideoVisibleState(product) {
    // Если есть видео, то добавим для продукта videoState
    if (product.media[0]?.video) {
      this.gaApp.services.brandzone.media.setVideoState({ product })
    }

    return product
  }

  reset() {
    this.gaApp.stores.brandzone.listing.$reset()
  }
}
