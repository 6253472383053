import { EYEZON_DEV_CDN } from '../constants'

import { config } from '~/config'

/**
 * Сервис отвечает за инициализацию и настройку eyezon
 * Он определяет конфигурацию, а затем передает ее
 * в gaApp.services.eyezon.setup для ее настройки.
 */

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // возвращает инстанс eyeZon
  get eyeZon() {
    return this.gaApp.services.eyezon.setup.eyeZon
  }

  // Метод инициализирует сервис, вызывая метод setup и передавая ему initConfig
  init(initConfig) {
    try {
      this.setup(initConfig)
    } catch (error) {
      this.gaApp.services.app.apm.captureError(error)
    }
  }

  /**
   * метод конфигурирует eyezon. Он вызывает метод _getInitConfig для получения
   * конфигурации на основе initData, а затем передает эту конфигурацию
   * в метод setup сервиса gaApp.services.eyezon.setup
   */
  setup(initData) {
    const initConfig = this._getInitConfig(initData)

    this.gaApp.services.eyezon.setup.setup(initConfig)

    this.gaApp.eventBus.subscribe('module/auth/logout', () => {
      this.gaApp.services.eyezon.cache.clearStorage()
    })

    this.gaApp.eventBus.subscribe('module/auth/login', () => {
      this.gaApp.services.eyezon.cache.clearStorage()
    })
  }

  // Получаем конфигурацию для инициализации eyezon.
  _getInitConfig(initData = this.gaApp.stores.app.common?.eyezon) {
    // берем нужный конфиг eyezon в зависимости от ФТ 'eyezonV2'
    const settingsConfig = config.get('eyezon')

    const initConfig = {
      ...settingsConfig,
      ...(initData || {}),
    }
    // в режиме разработки берем скрипт с cdn
    const isDev = this.gaApp.config.get('dev')

    const scriptUrl = isDev ? EYEZON_DEV_CDN : initConfig.scriptUrl

    return {
      ...initConfig,
      scriptUrl,
    }
  }
}
