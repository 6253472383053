import ar from './ar.json'
import en from './en.json'
import ru from './ru.json'
import ruBY from './ru-BY.json'
import ruBYOptions from './ru-BY.options.json'
import ruKZ from './ru-KZ.json'
import ruKZOptions from './ru-KZ.options.json'
import ruRU from './ru-RU.json'
import ruRUOptions from './ru-RU.options.json'

export const locales = {
  'ru-RU': [ru, ruRU, ruRUOptions],
  'ru-BY': [ru, ruBY, ruBYOptions],
  'ru-KZ': [ru, ruKZ, ruKZOptions],
  'ar-AE': ar,
  'ar-QA': ar,
  'ar-SA': ar,
  'en-AE': en,
  'en-QA': en,
  'en-SA': en,
  'ar-RU': ar,
  'en-RU': en,
}
