import { EVENT_CATEGORY_ADVENT2024 } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmAdvent2024GoToPurchasesFromLastWindowButtonClickModel extends GtmBaseModel {
  constructor({ blockName }) {
    super({ eventCategory: EVENT_CATEGORY_ADVENT2024 })

    this.action_type = 'click_go_to_purchases'
    this.block_name = blockName
  }
}
