export const INITIAL_STEP = 'welcome'

// Цели
export const PURPOSES = {
  CAREER_OPPORTUNITIES_MONEY: ['карьера', 'возможности', 'деньги'],
  ROMANCE_DATES_EASE: ['романтика', 'свидания', 'лёгкость'],
  JOURNEYS_DISCOVERIES_SPONTANEITY: ['путешествия', 'открытия', 'спонтанность'],
  STUDY_DISCIPLINE_DEVELOPMENT: ['учёба', 'дисциплина', 'развитие'],
}

// Названия ароматов
export const AROMA_NAMES = {
  MINT: 'мята',
  LAVENDER: 'лаванда',
  VANILLA: 'ваниль',
  GARDENIA: 'гардения',
  HONEY: 'мёд',
  PATCHOULI: 'пачули',
  GERANIUM: 'герань',
  PINK_PEPPER: 'розовый перец',
  VETIVER: 'ветивер',
  YUZU: 'юдзу',
  RASPBERRY: 'малина',
  ALMOND: 'миндаль',
  JASMINE: 'жасмин',
  COCOA: 'какао',
  WHITE_FLOWERS: 'белые цветы',
  JASMINE_ABSOLUTE: 'абсолю жасмина',
  BERGAMOT: 'бергамот',
  PEONY: 'пион',
  PINK_CHAMPAGNE: 'розовое шампанское',
  PEACH_BLOSSOM: 'цветок персика',
  MUSK: 'мускус',
  GINGER_LILY: 'имбирная лилия',
  SALTY_VANILLA: 'солёная ваниль',
  GREEN_PEAR: 'зелёная груша',
  LEMON: 'лимон',
  CARAMEL_APPLE: 'яблоко в карамели',
  CEDAR: 'кедр',
  POMEGRANATE: 'гранат',
  ORANGE_BLOSSOM: 'цветок апельсина',
  CASHMERE: 'кашмер',
  WHITE_PEPPER: 'белый перец',
  SAGE: 'шалфей',
  TONKA_BEANS: 'бобы тонка',
  RED_MANDARIN: 'красный мандарин',
  SANDALWOOD: 'сандал',
  CITRON: 'цитрон',
  GINGER_FLOWER: 'цветок имбиря',
  CARAMEL: 'карамель',
  RED_CURRANT: 'красная смородина',
  ROSE: 'роза',
  GREEN_ACCORD: 'зелёный аккорд',
  MANDARIN: 'мандарин',
  LEATHER: 'кожа',
  GRAPEFRUIT: 'грейпфрут',
  MARINE_ACCORD: 'морской аккорд',
  GUAIAC_WOOD: 'гваяковое дерево',
  LIME: 'лайм',
  VODKA: 'водка',
  WOODY_ACCORD: 'древесный аккорд',
  APPLE_TRIO: 'трио яблок',
  AMBER_WOOD: 'амбровое дерево',
}

// Описания ароматов по имени из AROMA_NAMES
export const AROMA_DESCRIPTIONS = {
  мята: 'Бодрящий запах прохлады и свежего летнего ветра с яркими зелёными нотами и лёгкой сладостью. Придаёт энергию и настраивает на новые свершения.',
  лаванда:
    'Умеренный и свежий аромат пышных лавандовых полей принесёт тебе спокойствие, уверенность и вдохновит природной красотой.',
  ваниль:
    'Нежная и сладкая ваниль окутает тебя тёплым ароматом сладкой выпечки, подарит чувство уюта и напомнит о вкусных домашних булочках.',
  гардения:
    'Её аромат мягкий, объёмный, пудровый и обволакивающий. Тонкие медово-сливочные оттенки перенесут тебя в мир мечтаний и гармонии.',
  мёд: 'Мягкие и комфортные медовые ноты окутывают композицию теплом, гармонизируют её и создают богатый фон для других нот.',
  пачули:
    'Тёплый землистый и сладковатый запах с характерным пряным древесным акцентом. Такой аромат расслабляет и добавляет образу загадку.',
  герань:
    'Освежающее цветочное благоухание с нежными нотами мяты и розы. Герань сочетает в себе свежие, сладкие, фруктовые и пряные оттенки.',
  'розовый перец':
    'Пряная и мягкая нота, которая придаст искру и благородство аромату и добавит оттенки сухофруктов, цитрусовых и можжевельника.',
  ветивер:
    'Сдержанный, мужественный и притягательный. Он может быть разным и перенести тебя в сухую раскалённую пустыню или в лес после дождя с запахом мокрой земли, цветов и ягод.',
  юдзу: 'Цветочно-цитрусовая нота с лаймовыми и бергамотовыми оттенками придаёт аромату свежесть и яркость. Духи с этой нотой искрящиеся, смелые и дерзкие.',
  малина:
    'Запах сладких ягод, ярких и сочных, только что собранных с куста. Эта нота тёплого лета и детства, она дарит нежность солнца, радость и лёгкость.',
  миндаль:
    'Аристократичный, но при этом нежный и тёплый аромат орехов и крема. Миндаль придаёт композиции изящества и ощущение комфорта.',
  жасмин:
    'Сладкая и холодная цветочная нота. Она изысканная, нежная и лёгкая. Композиция с ней вдохновляет на творчество и уносит в мир фантазий.',
  какао:
    'Насыщенный, тёплый и такой знакомый шоколадный аромат. Он манящий, густой и глубокий. Это сладкое звучание придаёт композиции особую притягательность.',
  'белые цветы':
    'Ноты белых цветов смягчают аромат, делают его более чувственным, деликатным и воздушным. Композиция с белыми цветами олицетворяет утончённость и возвышенность.',
  'абсолю жасмина':
    'Аромат с нотами фруктов, цветов и тёплым пикантным оттенком. Он добавит композиции нежность и оставит чувственное впечатление.',
  бергамот:
    'Он свежий и терпкий одновременно, бодрящий и праздничный. Духи с ним раскроются сладкими фруктовыми тонами и слабыми пряными нюансами.',
  пион: 'Запах пиона нежный, утончённый и сладкий фруктово-цветочный. Его нота очаровательна, она завораживает и надолго запоминается.',
  'розовое шампанское':
    'Свежие ягодные ноты клубники, малины и земляники поднимут тебе настроение, а игристость придаст лёгкости. Это аромат элегантности и беззаботности.',
  'цветок персика':
    'Деликатная и трогательная нота. Она дополнит аромат всей композиции теплом солнечных лучей и окутает нежностью и заботой.',
  мускус:
    'Сладковато-пудровый, чувственный запах мускуса слабо слышится в композиции, но он добавит в аромат нежность и чувственность.',
  'имбирная лилия':
    'Пряный, тёплый и сладкий аромат хрупких белых лепестков, из которых плетут душистые гирлянды на Гавайях. Такая нота в композиции даёт ощущение спокойствия и счастья.',
  'солёная ваниль':
    'Интересное сочетание свежих аккордов морской соли и чарующей сахарной ванили. Эта нота подчеркнёт чувственность и соблазнительность аромата.',
  'зелёная груша':
    'Лёгкий и сладковатый запах груши, в котором ощущается свежесть зелёных нот. Этот аромат наполняет композицию нежностью и романтикой.',
  лимон:
    'Свежесть и солнце. Эта яркая, сочная и искрящаяся нота добавит прохладу и энергию в аромат. Лимон взбодрит и оживит композицию.',
  'яблоко в карамели':
    'Фантазийная нота аппетитного десерта из яблок, щедро украшенного горячей ванильной карамелью. Тепло, сладко, согревающе.',
  кедр: 'Свежий аромат натурального дерева, пронизанный солнечными лучами и теплом. Он привносит в композицию уверенность и благородство.',
  гранат:
    'В запахе граната слышатся винные ноты с терпкими нюансами, мягкой кислинкой и сладостью. Искрящаяся и яркая фруктовая нота оживляет аромат.',
  'цветок апельсина':
    'Лёгкий, свежий, горьковатый цветочный аромат с терпкими и сладкими нюансами. Создаёт романтическое настроение, вдохновляет и воодушевляет.',
  кашмер:
    'Сложный многогранный аромат. В нём слышен мускусный и древесный тон, хвойный и фруктовый нюанс и ванильно-амбровый аспект.',
  'белый перец':
    'Пряная, слегка жгучая нота белого перца мягко оттеняет другие составляющие аромата и придаёт ему глубокое и объёмное звучание.',
  шалфей:
    'Освежающая, пряная и травяная нота с оттенками эвкалипта и хвои. Шалфей, растущий на скалах и горах, придаст композиции свежий и дымный оттенок.',
  'бобы тонка':
    'Тонкие орехово-карамельные ноты, аромат ванили, аккорды жжёного сахара, миндаля и чернослива. Духи с этими нотами окутают тебя мистикой и чувственностью.',
  'красный мандарин':
    'Освежающий цитрусовый аккорд любимого с детства мандарина. Этот яркий запах, добавит радость, свежесть и искристость в аромат.',
  сандал:
    'Утончённый, сладковато-древесный с мускусными и бальзамическими нюансами. Он придаст парфюму оттенок благородной древесины.',
  цитрон:
    'Чистый, свежий аромат прохладной зелени с приятной кислинкой. Композиция, дополненная цитроном, поднимет тебе настроение и зарядит энергией.',
  'цветок имбиря':
    'Древесные ноты, воздушная сладость и цитрусовая свежесть. В этой ноте ты услышишь весну, молодую зелень и терпкую древесину.',
  карамель:
    'Приятный сливочный аромат, который придаст духам тягучий, томный и насыщенный фруктово-ягодный или сливочный характер. С ним ты отправишься в путешествие за мечтой.',
  'красная смородина':
    'Красная смородина — это аромат лета, лёгкий, солнечный и позитивный. Духи с этой нотой будут очаровывать и притягивать комплименты.',
  роза: 'Дымно-цветочный, медово-таниновый, с оттенком табака и карамели. Роза может предстать в аромате импульсивной и волнующей, а может раскрыться тающей нежностью.',
  'зелёный аккорд':
    'Свежескошенная трава, зелёные листья, тёплая весна и прохладный летний день. Наполняет аромат энергичностью и свежестью нежных нот.',
  мандарин:
    'Аромат сочного мандарина раскрывается мягко в композиции. Он тёплый и сладкий, яркий и радостный. Добавит аромату лёгкость и безмятежность.',
  кожа: 'Эта нота придаёт композиции чувственность и элегантность. Она становится тёплой, дерзкой и манящей с оттенками дыма, кожи и древесины.',
  грейпфрут:
    'Аромат грейпфрута свежий и бодрящий, он придаёт звучанию лёгкую горчинку, сладость и неповторимую сочность. Композиция с ней подарит настроение и зарядит энергией.',
  'морской аккорд':
    'Свежий аромат, приносящий прохладу и свежесть морского дыхания. Перенесёт тебя на побережье к бескрайним бирюзовым просторам водной глади.',
  'гваяковое дерево':
    'Сильный аромат с оттенками дыма и ванили, земли и древесины. Он насыщенный и смолистый. Придаёт композиции глубину и экзотичность.',
  лайм: 'Цитрусовый аромат с кислинкой, наполненный свежестью и бодростью. Композиция с этой нотой обретает дух свободного ветра и полёта.',
  водка:
    'Провокационная и нестандартная нота. Будет играть в композиции совершенно по-разному: может открыться свежей и бодрящей или жаркой и манящей.',
  'древесный аккорд':
    'Бархатистая горечь и терпкость ноты с дымными и сладковатыми тонами. Она подарит аромату натуральные тёплые древесные оттенки, которые оставят нежный шёпот на коже.',
  'трио яблок':
    'Зелёные и красные яблоки раскрываются сладкой фруктовой нотой с кислинкой. Свежий и сочный аромат яблок добавит игривость в композицию.',
  'амбровое дерево':
    'Дымные древесно-кожаные акценты, в которых ощущаются бальзамические и немного сладковатые ноты с едва уловимыми анималистичными вкраплениями.',
}

// Списки ароматов исходя из выбранной цели из PURPOSES
export const AROMAS_BY_PURPOSE = {
  CAREER_OPPORTUNITIES_MONEY: [
    ['мята', 'лаванда', 'ваниль'],
    ['гардения', 'мёд', 'пачули'],
    ['герань', 'розовый перец', 'ветивер'],
    ['юдзу', 'малина', 'пачули'],
    ['миндаль', 'жасмин', 'какао'],
    ['белые цветы', 'абсолю жасмина', 'мёд'],
  ],
  ROMANCE_DATES_EASE: [
    ['бергамот', 'пион', 'ваниль'],
    ['розовое<br>шампанское', 'цветок<br>персика', 'мускус'],
    ['жасмин', 'имбирная<br>лилия', 'солёная<br>ваниль'],
    ['бергамот', 'зелёная груша', 'ваниль'],
    ['лимон', 'яблоко в карамели', 'кедр'],
    ['гранат', 'цветок апельсина', 'кашмер'],
  ],
  JOURNEYS_DISCOVERIES_SPONTANEITY: [
    ['белый перец', 'шалфей', 'бобы тонка'],
    ['красный мандарин', 'кедр', 'сандал'],
    ['цитрон', 'цветок имбиря', 'карамель'],
    ['шалфей', 'бобы тонка', 'ветивер'],
    ['красная смородина', 'роза', 'ветивер'],
    ['зелёный аккорд', 'жасмин', 'ваниль'],
  ],
  STUDY_DISCIPLINE_DEVELOPMENT: [
    ['мандарин', 'роза', 'кожа'],
    ['грейпфрут', 'морской<br>аккорд', 'гваяковое<br>дерево'],
    ['розовый перец', 'герань', 'ветивер'],
    ['лайм', 'водка', 'древесный аккорд'],
    ['трио<br>яблок', 'гардения', 'амбровое<br>дерево'],
  ],
}

export const PRODUCTS_BY_PURPOSE = {
  CAREER_OPPORTUNITIES_MONEY: [
    {
      id: 16,
      sku: 19760330830,
      name: 'jean paul gaultier le male',
      href: '/19760330830-le-male',
      description:
        '«Моряк» — легендарный герой, он воплощает силу и мужественность. Вдохновлённый героями в тельняшках, Le Male создан покорять сердца. В аромате свежесть лаванды подчёркивает мужественность, напоминая запах традиционного французского мыла для бритья. Его дополняют ваниль и бобы тонка, чтобы усилить чувственность и притягательность.',
      aromas: ['мята', 'лаванда', 'ваниль'],
      aromasDescription:
        'Этот аромат — яркий спутник твоей уверенности и решительности. С ним тебе покорятся все рабочие задачи. Ты будешь сконцентрирован и спокоен, а деньги и возможности сами постучатся в твою дверь.',
    },
    {
      id: 13,
      sku: 19760330826,
      name: 'jean paul gaultier scandal',
      href: '/19760330826-scandal',
      description:
        'Scandal — это ода кабаре, вечному источнику вдохновения для маэстро Готье. Аромат относится к шипровой, гурманской группе. Особую притягательность ему придаёт нота мёда в сочетании со свежим красным апельсином и цветущей гардении. Обволокивающий и тёплый аромат.',
      aromas: ['гардения', 'мёд', 'пачули'],
      aromasDescription:
        'Гармония и богатство верхних и средних нот украшает база, призванная включить интуицию и анализ. Что это, если не секрет к открытию новых возможностей и развитию карьеры.',
    },
    {
      id: 1,
      sku: 19000066077,
      name: 'carolina herrera bad boy cobalt',
      href: '/19000066077-bad-boy-cobalt',
      description:
        'Bad Boy Cobalt — это аромат, который словно ударом молнии разрушает рамки условностей. Современные мужчины знают, что не бывает силы без милосердия и власти без сострадания. Именно поэтому свежие верхние ноты аромата усиливают цветочное сердце.',
      aromas: ['розовый перец', 'герань', 'ветивер'],
      aromasDescription:
        'Аромат снимает напряжение, но при этом он притягивающий и бодрящий. Сделай глубокий вдох и выдох — приготовься действовать! Притягивай к себе деньги, возможности и развитие.',
    },
    {
      id: 14,
      sku: 19000258597,
      name: 'nina ricci nina illusion',
      href: '/19000258597-nina-illusion',
      description:
        'Магия культовых яблочек Nina Ricci. Уникальные пикантные ноты японского юдзу переплетаются с нотами розового перца и поражают с первого вздоха. Игривые ноты ванили в обрамлении бархатистого цветка апельсина создают яркое и роскошное звучание. Завершающий штрих — сливочные ноты ванили и слегка тёплые пачули.',
      aromas: ['юдзу', 'малина', 'пачули'],
      aromasDescription:
        'Откройся смелости и дерзости, вооружившись яркими ягодными нотами. С таким арсеналом тебе покорятся любые амбиции, ведь ты вдохновлена и полна решимости.',
    },
    {
      id: 12,
      sku: 24863300003,
      name: 'carolina herrera good girl',
      href: '/24863300003-good-girl',
      description:
        'Парфюмерная вода, заключённая во флакон в виде изысканной чёрной туфельки. Начальная нота Good Girl — ароматный запах кофе и нежный миндаль, нота сердца — цветочные запахи жасмина самбака и бархатной туберозы, завершают композицию какао и пряные бобы тонка.',
      aromas: ['миндаль', 'жасмин', 'какао'],
      aromasDescription:
        'Твори и фантазируй вместе с этим ароматом, ведь он создан, чтобы вдохновить тебя и подтолкнуть вперёд к новым интересным возможностям. Восточная аура задаёт темп композиции и награждает выносливостью, стойкостью и решимостью на пути.',
    },
    {
      id: 15,
      sku: 19000139409,
      name: 'rabanne lady million',
      href: '/19000139409-lady-million',
      description:
        'Парфюмерная вода Lady Million имеет древесно-цветочный аромат и создана для женщин, желающих довести до совершенства свой образ. Изысканный дизайн подчеркнёт богатое содержание флакона. Lady Million раскрывается нотами сладкой малины, освежающего лимона и нежной нероли.',
      aromas: ['белые цветы', 'абсолю жасмина', 'мёд'],
      aromasDescription:
        'Что может быстрее привести тебя к успеху и достатку, чем вдохновение от Lady Million? Золотая огранка бриллианта — уже новый уровень, а звучание аромата укрепит в тебе уверенность и побудит к новым свершениям.',
    },
  ],
  ROMANCE_DATES_EASE: [
    {
      id: 18,
      sku: 19000147637,
      name: 'carolina herrera good girl blush',
      href: '/19000147637-very-good-girl-blush',
      description:
        'Парфюмерная вода Good Girl Blush — это яркая игра контрастов и неповторимое пудровое звучание цветочной нежности. Аромат начинается с захватывающего противостояния чувственных нот ванили и розы с цитрусовой свежестью бергамота, окутанной в базу изысканных нот пиона и искристого иланг-иланга.',
      aromas: ['бергамот', 'пион', 'ваниль'],
      aromasDescription:
        'Очаровательные цветочные ароматы сделают твой образ более романтичным. Нежные, утончённые и чарующие ноты завораживают других и надолго остаются в памяти.',
    },
    {
      id: 17,
      sku: 7181500009,
      name: 'carolina herrera 212 vip rose',
      href: '/7181500009-212vip-rose',
      description:
        'Парфюмерная вода Carolina Herrera 212VIP Rose — тёплый, цветочный аромат, который оставляет стойкий шлейф магнетического притяжения. Начальная нота — розовое шампанское, нота сердца — пленяющий и волнующий цветок персикового дерева. Завершает композицию древесная нота.',
      aromas: ['розовое шампанское', 'цветок персика', 'мускус'],
      aromasDescription:
        'Лёгкие и романтичные пузырьки розового шампанского дополнят твою беззаботность, нежность и чувственность. Решайся на романтику! Тёплые встречи и праздник каждый день — всё для тебя.',
    },
    {
      id: 22,
      sku: 7292400002,
      name: 'rabanne olympéa',
      href: '/7292400002-olympea',
      description:
        'Свежая восточная Olympéa раскрывается нотами зелёного мандарина. Сердце аромата звучит цветочно-водным аккордом имбирной лилии и водяного жасмина. Да, это искушающий, дерзкий и очень естественный характер. Завершает композицию томная солёная ваниль, усиленная нотами сандалового дерева и амбры.',
      aromas: ['жасмин', 'имбирная лилия', 'солёная ваниль'],
      aromasDescription:
        'Воодушевляйся, наполняйся счастьем и смело беги навстречу романтике. А аромат подчеркнёт твою природную чувственность и соблазнительность.',
    },
    {
      id: 19,
      sku: 19760330832,
      name: 'jean paul gaultier la belle',
      href: '/19760330832-la-belle',
      description:
        'Новая героиня — «Красотка». Идеальные формы флакона подчёркнуты ожерельем из золотых цветов. Новая пара героев — фантазия дизайнера о райских садах. В сердце аромата La Belle — свежие ноты яблока. Сочетание фруктовых и цветочных нот делают композицию невероятно привлекательной и чувственной.',
      aromas: ['бергамот', 'зелёная груша', 'ваниль'],
      aromasDescription:
        'Радостной тебе — радостный аромат. Он, свежий и привлекательный, наполнит всё вокруг нежностью и романтикой, а ты смело подхватывай его и лети навстречу чувствам.',
    },
    {
      id: 20,
      sku: 7140600013,
      name: 'nina ricci nina',
      href: '/7140600013-nina',
      description:
        'Парфюм интригует, начиная с верхних освежающих нот экзотической кислинки лайма и лимона. Ноты сердца звучат зелёным яблоком, окутанным тягучей карамелью, сладким пионом, пряным пралине и пьянящим дурманом. Базовые ноты — тёплый, терпковатый древесный аромат яблони с благородной хвоей кедра и мускуса.',
      aromas: ['лимон', 'яблоко в карамели', 'кедр'],
      aromasDescription:
        'Вдохни аромат своего 2025 года. Фантазийная и при этом яркая солнечная композиция унесёт тебя по освежающим и тёплым волнам лёгкости к жизнерадостной и романтичной тебе.',
    },
    {
      id: 21,
      sku: 19000156057,
      name: 'lady million royal',
      href: '/19000156057-lady-million',
      description:
        'Забыв об условностях, приготовься играть по своим правилам. Пришло время смело заявить о себе. Пришло время стать Lady Million Royal. В парфюмерной воде сочные брызги граната жизнерадостно искрятся на пышном королевском букете с бархатистой кашмерановой базой.',
      aromas: ['гранат', 'цветок апельсина', 'кашмер'],
      aromasDescription:
        'Аромат романтики, любви и страсти. Алый сочный гранат раскроет яркую, смелую и чувственную тебя. Сладкие цветы апельсина откроют сердце для чего-то нового. Ты готова покорять сердца и покориться миру ощущений.',
    },
  ],
  JOURNEYS_DISCOVERIES_SPONTANEITY: [
    {
      id: 6,
      sku: 19760330454,
      name: 'carolina herrera bad boy',
      href: '/19760330454-bad-boy',
      description:
        'В композиции яркие ноты шалфея, зелёного бергамота и перца сливаются с чувственной насыщенностью бобов тонка, какао и амбры. Притягательный аромат переносит легенду о вечном бунтаре в наше время. Он тот, кто не боится нарушать правила и выбирает свой путь.',
      aromas: ['белый перец', 'шалфей', 'бобы тонка'],
      aromasDescription:
        'Смелый и яркий аромат принесёт тебе неожиданное решение на этот год — отправляйся в горы Мексики. Новые впечатления, спонтанные поездки и открытия — то, что ждёт тебя в новом году, то, на что ты решишься.',
    },
    {
      id: 10,
      sku: 19000309949,
      name: 'rabanne million gold',
      href: '/19000309949-million-gold',
      description:
        'Million Gold создан для мужчины, чей стиль жизни невозможно игнорировать. Здесь звучат пленительные ноты сандалового дерева, мандарина и кедра: они зарядят энергией. Цветочные аккорды придают экстравагантность и уникальность. Манящее древесное звучание отражает внутренний свет и непринужденность мужчины.',
      aromas: ['красный мандарин', 'кедр', 'сандал'],
      aromasDescription:
        'Не переставай желать открытий! Многогранный аромат вдохновит тебя на поездки в разные части света: ты можешь отправиться в Италию, окутанную запахами сладких мандаринов, или за гармонией в лиственные леса тропической Азии.',
    },
    {
      id: 9,
      sku: 19760330436,
      name: 'nina ricci nina rouge',
      href: '/19760330436-nina-rouge',
      description:
        'Невероятное наслаждение начинается с оттенка сочной малины. Свежие и яркие ноты лимонной цедры вызывают любопытство. Слегка пряный аромат бутонов имбиря подчёркивает бархатистое, почти сливочное, звучание гардении. Завершают композицию Nina Rouge — аккорды бурбонской ванили и хрустящей карамели.',
      aromas: ['цитрон', 'цветок имбиря', 'карамель'],
      aromasDescription:
        'Сладкий флёр карамели настроит тебя на фантазии, а свежесть других нот подтолкнёт к решению о поездках по таинственной Азии. Смело хватай эту спонтанность и окунись в приключения.',
    },
    {
      id: 8,
      sku: 19000141064,
      name: 'jean paul gaultier scandal ph',
      href: '/19000141064-scandal',
      description:
        'Древесно-амбровый Scandal раскрывается с первого же вдоха. Контраст между мягкой карамелью и притягательными нотами кожи. Композиция выстроена вокруг жареных бобов тонка на базе ветивера и кедра, коронованных мускатным шалфеем и мандарином.',
      aromas: ['шалфей', 'бобы тонка', 'ветивер'],
      aromasDescription:
        'Наноси парфюм, окрыляйся и притягивай новые ощущения! Такой аромат вдохновит тебя на жаркие и влажные тропики Южной Америки и горы Мексики. Они ждут, чтобы открыть для тебя неожиданные эмоции и новые впечатления.',
    },
    {
      id: 7,
      sku: 19000006048,
      name: 'carolina herrera very good girl',
      href: '/19000006048-very-good-girl',
      description:
        'Very Good Girl открывается дерзкими аккордами красной смородины, которые дополняют экзотические оттенки личи. В сердце композиции раскрываются кремовые и пудровые оттенки розы. Финальный аккорд — лёгкая вуаль из древесных нответивера. Он соединяется с тёплыми оттенками ванили и придаёт неожиданное звучание.',
      aromas: ['красная смородина', 'роза', 'ветивер'],
      aromasDescription:
        'Солнечный и лёгкий аромат смородины побудит тебя к поездке, а сладкое личи прошепчет направление — Юго-Восток Китая. Неожиданная и спонтанная поездка! Что может быть слаще путешествий и новых открытий?',
    },
    {
      id: 11,
      sku: 19000279938,
      name: 'rabanne olympea parfum',
      href: '/19000279938-olympea',
      description:
        'Свежесть зелёного аккорда, пряного розового перца и ароматической эссенции мускатного шалфея. В сердце композиции — цветочный букет из солнечного жасмина, абсолю розы и цветков апельсина. Наконец, ваниль, бензоин и мускус в базовых нотах раскрывают самые волнующие грани женской натуры.',
      aromas: ['зелёный аккорд', 'жасмин', 'ваниль'],
      aromasDescription:
        'Ты — загадочная и свободная, лёгкая и очаровательная. Загадай себе в этом году чарующую Азию, надевай, как украшение, аромат свежести зелёных нот с таинственной ванилью и изысканным жасмином.',
    },
  ],
  STUDY_DISCIPLINE_DEVELOPMENT: [
    {
      id: 5,
      sku: 7291300004,
      name: 'rabanne 1 million',
      href: '/7291300004-1-million',
      description:
        'Цитрусовые ноты освежают и обращают на себя внимание, травяные аккорды придают дерзость и решительность. Древесная композиция — стиль и элегантность. Корица делает образ гармоничным и чувственным, а мята бодрит и поднимает настроение. Ведь главная задача — добиться цели и реализовать мечту.',
      aromas: ['мандарин', 'роза', 'кожа'],
      aromasDescription:
        'Сладкие и солнечные мандарины не только освежают, но и помогут сосредоточиться на том, что для тебя действительно важно. Вооружайся ароматом и готовься к подвигам.',
    },
    {
      id: 3,
      sku: 7291600002,
      name: 'rabanne invictus',
      href: '/7291600002-invictus',
      description:
        'Яркие цитрусовые ноты аромата перекликаются с волнующими морскими аккордами, которые позволяют расслабиться. Пачули создаёт ауру загадочности. Амбра образует лёгкий шлейф и придаёт глубину композиции. Обладатели Rabanne Invictus ставят перед собой цели и всегда их добиваются.',
      aromas: ['грейпфрут', 'морской аккорд', 'гваяковое дерево'],
      aromasDescription:
        'Аромат подарит тебе солнечное настроение и поможет настроиться на работоспособность. Всё, что тебе нужно — это почувствовать прилив свежести и сил и двигаться вперёд.',
    },
    {
      id: 1,
      sku: 19000066077,
      name: 'carolina herrera bad boy cobalt',
      href: '/19000066077-bad-boy-cobalt',
      description:
        'Этот аромат, словно ударом молнии, разрушает рамки условностей. Свежие верхние ноты аромата подчеркиваются цветочным сердцем с нотами лаванды и чёрной сочной сливы. Композиция аромата усиливается насыщенным базовым аккордом: мужественными и сексуальными нотами ветивера и кедра с подчеркнутыми дубовыми гранями.',
      aromas: ['розовый перец', 'герань', 'ветивер'],
      aromasDescription:
        'Вдыхай — аромат снимет твоё напряжение и взбодрит, чтобы все трудности перед тобой стали обычными задачами, которые ты быстро решишь. Композиция будет мотивировать и поможет сохранить настрой на развитие, которое для тебя важно.',
    },
    {
      id: 2,
      sku: 7181500007,
      name: 'carolina herrera 212 vip men',
      href: '/7181500007-212-vip-men',
      description:
        '212 VIP Men придаст уверенности и раскроет все секреты мира. Коктейль из ароматов лайма, чёрной икры, перца, имбиря и маракуйи завоюет тебя, подчеркнёт энергичность, смелость и страсть. Неожиданное сочетание водки, джина, специй и голубой мяты — сердце композиции. Его дополняют шлейф из дерева, дорогой кожи и амбры.',
      aromas: ['лайм', 'водка', 'древесный аккорд'],
      aromasDescription:
        'Свежесть и свобода этого аромата как ничто другое снимут стресс и усталость, придадут уверенности и энергичности. Преисполнившись тонусом и бодростью, смело берись за важное тебе дело.',
    },
    {
      id: 4,
      sku: 19000196866,
      name: 'nina ricci nina le parfum',
      href: '/19000196866-nina-le-parfum',
      description:
        'Фруктовая цветочная древесная Nina Le Parfum с уникальным притягательным характером. Аромат раскрывается свежим яблочным трио с пикантными нотами итальянского лимона. В сердце композиции — изысканный белый букет из цветков апельсина, гардении и туберозы. В шлейфе — чувственный дуэт древесной амбры и тёплой ванили.',
      aromas: ['трио яблок', 'гардения', 'амбровое дерево'],
      aromasDescription:
        'Ничто не отвлечёт тебя. Расслабься и отпусти все тревоги, а яблочный Nina Ricci поможет поймать спокойствие и сосредоточиться на развитии. Новые знания, новая стезя, новые мысли.',
    },
  ],
}

export const state = () => ({
  currentStep: INITIAL_STEP,
  steps: ['welcome', 'action', 'summary'],

  selectedPurpose: 'CAREER_OPPORTUNITIES_MONEY',
  selectedAromas: ['герань', 'розовый перец', 'ветивер'],
})
