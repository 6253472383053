export const mainEndpoints = {
  default: () => ({
    url: 'location',
  }),
  locationByIp: () => ({
    url: 'location/location-by-ip',
  }),
  geoSuggest: () => ({
    url: 'location/suggest',
  }),
  geoGeocode: () => ({
    url: 'location/geocode',
  }),
  geoReverseGeocode: () => ({
    url: 'location/reverse-geocode',
  }),
  setAddress: () => ({
    url: 'location/add-address',
  }),
  setAddressV2: () => ({
    url: 'location/add-address-v2',
  }),
}
