const autoInit = false;
const config = {"enabled":true,"debug":false,"businessId":"82","autoInit":false,"scriptAsync":true,"scriptURL":"https://storage.googleapis.com/eyezonscriptv2/static/js/eyezonwidget.js","region":"prod","scriptUrl":"/eyezone/static/js/eyezonwidget.js","hideEyeAfterMinutes":240};
import { Eyezon } from './eyezon.class'

export default function (context, inject) {
  const $eyezon = new Eyezon()

  if (autoInit) {
    $eyezon.init(config)
  }

  inject('eyezon', $eyezon)
}
