import merge from 'lodash/merge'

import layouts from '~/locales/languages/layouts/ar'
import layoutsArSa from '~/locales/languages/layouts/ar-SA'

export default () => {
  return {
    ...merge(layouts, layoutsArSa),
  }
}
