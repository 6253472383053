import { STEP, STEPS_ORDER, WELCOME_TYPE } from '../constants'

export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  goToStep(step) {
    this.gaApp.stores.auth.modal.step = step
  }

  goToPreviousStep() {
    const currentIndex = STEPS_ORDER.indexOf(this.gaApp.stores.auth.modal.step)
    const previousIndex = Math.max(0, currentIndex - 1)

    const previousStep = STEPS_ORDER[previousIndex]

    this.goToStep(previousStep)
  }

  onCodeSendSuccess({ phone, phoneCode, phoneFormatted }) {
    this.gaApp.stores.auth.modal.phone = phone
    this.gaApp.stores.auth.modal.phoneCode = phoneCode
    this.gaApp.stores.auth.modal.phoneFormatted = phoneFormatted

    this.goToStep(STEP.CONFIRM)
  }

  isFormFieldError(parameters) {
    const code = 'verification_token'
    const phone = 'phone_number'
    return (
      Object.keys(parameters).length && (parameters[code] || parameters[phone])
    )
  }

  handleError(error) {
    const errorData = error.data?.data
    const invalidParameters = errorData?.invalidParameters || {}
    const metadata = errorData?.metadata || {}

    if (metadata.next_request_delay_ms) {
      return this.handleMetaDataError(errorData)
    }

    if (this.isFormFieldError(invalidParameters)) {
      this.handleFieldError(errorData)
    } else {
      this.handleGlobalError(this.gaApp.i18n.t('auth.error.default'))
    }
  }

  setStepError(error) {
    this.gaApp.stores.auth.modal.stepError = error
  }

  handleGlobalError(message) {
    this.gaApp.services.notification.main.open(message)
  }

  handleFieldError(error) {
    this.setStepError(error)
  }

  handleMetaDataError(error) {
    this.setStepError(error)
  }

  onLocaleChangingConfirmationConfirm() {
    const { localeChangingConfirmationCountry } = this.gaApp.stores.auth.modal

    const storeContent = this.gaApp.services.app.main.getStoreContent(
      localeChangingConfirmationCountry,
    )
    const storeDomain = storeContent?.storeDomain || ''
    const { path } = this.gaApp.route
    const hash = '#auth'

    window.location.href = `${storeDomain}${path}${hash}`
  }

  onUpdateCountry(country) {
    if (country === this.gaApp.i18n.locale?.country) {
      return
    }

    this.gaApp.stores.auth.modal.localeChangingConfirmationCountry = country

    this.goToStep(STEP.LOCALE_CHANGING)
  }

  async onCodeConfirmSuccess({ isRegistered }, emit) {
    this.gaApp.stores.auth.modal.logged = true

    if (isRegistered) {
      emit('success')
    } else {
      if (this.gaApp.features.get('newWelcomeScreen')) {
        await this.setWelcomeType()
      }

      this.goToStep(STEP.WELCOME)
    }
  }

  reset() {
    this.gaApp.stores.auth.modal.$reset()
  }

  handleIsNotLogged() {
    if (!this.gaApp.stores.auth.modal.logged) {
      this.gaApp.services.user.main.resetUser()
      this.reset()

      // Если это роут требующий авторизации,
      if (
        this.gaApp.services.app.router.isAuthRequired(this.gaApp.route.path)
      ) {
        // то перенаправляем юзера на главную
        this.gaApp.router.push('/')
      }
    }
  }

  handleBackAction(emit) {
    if (this.gaApp.stores.auth.modal.isStartStep) {
      emit('back')
    } else {
      this.goToPreviousStep()
    }
  }

  onBackAction(emit) {
    this.gaApp.stores.auth.modal.localeChangingConfirmationCountry = null
    this.setStepError(null)
    this.handleBackAction(emit)
  }

  updateNextRequestDelay(delay) {
    this.gaApp.stores.auth.modal.nextRequestDelay = delay
  }

  updateVerificationCode(code) {
    this.gaApp.stores.auth.modal.verificationCode = code || ''
  }

  onOpenModal() {
    this.gaApp.analytics.modules.auth.onOpenAuthModal()
  }

  async setWelcomeType() {
    const otherCountriesUser =
      await this.gaApp.services.user.main.checkOtherCountriesPhoneExistence({
        phoneCode: this.gaApp.stores.auth.modal.phoneCode,
        phone: this.gaApp.stores.auth.modal.phone,
      })

    this.gaApp.stores.auth.modal.welcomeType = otherCountriesUser
      ? WELCOME_TYPE.OTHER_COUNTRIES_USER
      : WELCOME_TYPE.NEW_USER
  }
}
