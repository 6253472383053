import isNumber from 'lodash/isNumber'

export class BonusService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  // получение номинала разделенного на деноминатор
  getNominal({ amount, denominator }) {
    const amountNumber = Number(amount)
    const denominatorNumber = Number(denominator) || 1

    return amountNumber / denominatorNumber
  }

  // получение номинала как строки с разделителем
  getNominalFormatted({
    amount,
    denominator,
    symbol,
    minimumFractionDigits = null,
    withSymbol = false,
  }) {
    const nominal = this.getNominal({ amount, denominator })

    const options = {
      minimumFractionDigits: this.getMinimumFractionDigits(
        minimumFractionDigits,
        denominator,
      ),
    }

    const value = this.gaApp.services.app.number.format(nominal, options)

    const nominalFormatted =
      withSymbol && Boolean(symbol) ? `${value} ${symbol}` : value

    return nominalFormatted
  }

  getBonusWeight(bonus) {
    const nominal = this.getNominalFormatted(bonus)

    const weightNominal = this.gaApp.services.app.string.getWeight(nominal)
    const weightSymbol = this.getSymbol(bonus.symbol).length

    return bonus.amount
      ? {
          value: weightNominal,
          withSymbol: weightNominal + weightSymbol + 0.5,
        }
      : {
          value: 0,
          withSymbol: 0,
        }
  }

  getMinimumFractionDigits(value, denominator) {
    if (isNumber(value)) {
      return value
    }

    const MINIMUM_FRACTION_DIGITS_BY_DENOMINATOR = {
      1: 0,
      10: 1,
      100: 2,
    }

    const MINIMUM_FRACTION_DIGITS_BY_STORE = {
      BY: 2,
      QA: 2,
      AE: 2,
    }

    if (this.gaApp.features.get('fractionalDigitsByDenominator')) {
      return MINIMUM_FRACTION_DIGITS_BY_DENOMINATOR[denominator] ?? 0
    } else {
      const [, storeName] = this.gaApp.i18n.locale.iso.split('-')
      return MINIMUM_FRACTION_DIGITS_BY_STORE[storeName] ?? 0
    }
  }
}
