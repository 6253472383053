import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

/**
 * Очищает локацию от лишних полей
 * @param { object } location
 */
const cleanLocation = (location) => {
  // чистим null и undefined
  let result = omitBy(location, isNil)

  // чистим пустые массивы
  result = omitBy(result, (key) => isArray(key) && isEmpty(key))

  return result
}

/**
 * Сравнивает две локации по массиву ключей
 * @param { object } firstLocation
 * @param { object } secondLocation
 * @param { array } fields - массив ключей по которым сравниваются локации
 * @returns { boolean }
 */
export const areFieldsEqual = (firstLocation, secondLocation, fields = []) => {
  // избавляемся от полей со значением null, чтобы считать равными null и undefined
  const cleanFirstLocation = cleanLocation(firstLocation)
  const cleanSecondLocation = cleanLocation(secondLocation)

  return fields.every((field) =>
    isEqual(cleanFirstLocation[field], cleanSecondLocation[field]),
  )
}
