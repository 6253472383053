export const HEADING_MARGIN_SIZE = {
  LARGE: 'l',
}

export const EDITOR_BLOCK_TYPES = {
  HEADER: 'header',
  IMAGE: 'image',
  QUOTE: 'quote',
  PARAGRAPH: 'paragraph',
  LIST: 'list',
  CAROUSEL: 'carousel',
  BUTTON: 'button',
  PRODUCT_CARD: 'product_card',
  PRODUCT_SLIDER: 'product_slider',
  PRODUCT_LISTING: 'product_listing',
  EMBED: 'embed',
  BANNER: 'banner',
}

export const EMPTY_HEADER = {
  type: EDITOR_BLOCK_TYPES.HEADER,
  data: {
    isSkeletonPreview: true,
    isSectionHeader: false,
    text: '',
    level: 2,
    noMargin: false,
  },
}

export const EMPTY_PARAGRAPH = {
  type: 'paragraph',
  data: {
    isSkeletonPreview: true,
    text: '',
    dropCap: false,
    highlight: false,
    noMargin: false,
  },
}
