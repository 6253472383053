import { BLOCK_TYPES } from '../../components/molecules/content/scripts/consts'

export const BLOCK_VIEWS = {
  WIDE: 'wide', // Занимает две колонки в двухколоночной верстке
  NARROW: 'narrow', // Занимает одну колонку
  ADAPTIVE: 'adaptive', // TODO - разобраться зачем
}

export const BLOCK_VIEWS_BY_TYPES = {
  [BLOCK_TYPES.BUTTON]: BLOCK_VIEWS.ADAPTIVE,

  [BLOCK_TYPES.CAROUSEL]: BLOCK_VIEWS.WIDE,
  [BLOCK_TYPES.PRODUCT_LISTING]: BLOCK_VIEWS.WIDE,
  [BLOCK_TYPES.PRODUCT_SLIDER]: BLOCK_VIEWS.WIDE,

  [BLOCK_TYPES.EMBED]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.HEADER]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.IMAGE]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.LIST]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.PARAGRAPH]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.PRODUCT_CARD]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.QUOTE]: BLOCK_VIEWS.NARROW,
  [BLOCK_TYPES.BANNER]: BLOCK_VIEWS.NARROW,
}
