import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductBaseModel } from '../gtm-ga4-product-base.model/gtm-ga4-product-base.model'

export class GtmGa4ProductViewModel extends GtmBaseModel {
  constructor({ product, chunk }) {
    if (!product) {
      throw new Error('product is required')
    }

    const { id, price, searchTerm, list, itemListId, plpType, additional } =
      product

    super({ event: 'view_item_list', additional })

    this.item_list_name = list
    this.item_list_id = itemListId || id
    this.plp_type = plpType || ''
    this.search_term = searchTerm || ''
    this.value = price

    this.ecommerce = {
      items: chunk.map((product) => new GtmGa4ProductBaseModel(product)),
    }
  }
}
