import {
  AROMA_DESCRIPTIONS,
  AROMAS_BY_PURPOSE,
  PRODUCTS_BY_PURPOSE,
} from './state'

// Helper function to remove <br> tags from strings
const sanitizeString = (str) => str.replace(/<br\s*\/?>/g, ' ')

export function areArraysEqual(arr1, arr2) {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Create copies of the arrays, sanitize, and sort them
  const sortedArr1 = [...arr1].map(sanitizeString).sort()
  const sortedArr2 = [...arr2].map(sanitizeString).sort()

  // Compare the sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false
    }
  }

  return true
}

export const getters = {
  aromasByPurpose() {
    return (purpose) => {
      return AROMAS_BY_PURPOSE[purpose].map((product) => {
        return product.map((aroma) => {
          return aroma
        })
      })
    }
  },

  selectedAromasWithDescription() {
    if (!this.selectedAromas) {
      return []
    }

    return this.selectedAromas.map((aroma) => {
      return {
        title: sanitizeString(aroma),
        subtitle: AROMA_DESCRIPTIONS[sanitizeString(aroma)],
      }
    })
  },

  selectedProduct() {
    if (!this.selectedPurpose || !this.selectedAromas) {
      return null
    }

    return PRODUCTS_BY_PURPOSE[this.selectedPurpose].find((product) => {
      return areArraysEqual(product.aromas, this.selectedAromas)
    })
  },

  productRecommendations() {
    if (!this.selectedProduct) {
      return []
    }

    return PRODUCTS_BY_PURPOSE[this.selectedPurpose].filter((product) => {
      return product !== this.selectedProduct
    })
  },
}
