import { TYPE } from '../../constants'
import {
  DigiAnalyticsBrandViewModel,
  DigiAnalyticsCategoryViewModel,
  MindboxPageViewModel,
} from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class PageViewBuilder extends BaseBuilder {
  constructor(data, country) {
    const models = new Map([[TYPE.MINDBOX, MindboxPageViewModel]])

    const type = data.meta?.type
    if (type === 'category') {
      models.set(TYPE.DIGI, DigiAnalyticsCategoryViewModel)
    } else if (type === 'brandzone') {
      models.set(TYPE.DIGI, DigiAnalyticsBrandViewModel)
    }

    super(models, data, country)
  }
}
