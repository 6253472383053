export const TARGET = {
  MAIN: 'main',
  PREVIEW: 'preview',
}

export const STATE = {
  PRODUCT: 'product',
  DELIVERY: 'delivery',
  RECOMMENDATIONS: 'recommendations',
}

export const initialData = () => ({
  updatedAt: null,
  pending: false,
  error: false,
  data: null,
})

export const STATUS = {
  IN_STOCK: 'in-stock',
  LOW_STOCK: 'low-stock',
  OOS: 'oos',
  WHOLE_STOCK_IN_CART: 'whole-stock-in-cart',
}

export const MOSCOW_CITY_ID = '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'

export const ATTRIBUTE = {
  VISUAL: 'colors',
  PHYSICAL: 'units',
}

export const TYPE_CONTENT = {
  DESC: 'Description',
  BRAND: 'Brand',
  TEXT: 'Text',
}

export const SHORT_DESCRIPTION_MAX_LENGTH = 150

export const VIEW = {
  PAGE: 'page',
  QUICK_PREVIEW: 'quick-preview',
}

export const SKU_HASH_NAME = 'sku'
export const SKU_HASH_DELIMITER = '='

export const ADVANTAGE_MODAL_NAME = {
  DELIVERY: 'delivery',
}

export const MAX_OPTIONS_LENGTH = 5
export const MAX_ATTRIBUTE_VALUE_LENGTH = 4

export const DELIVERY_ABORT_KEY = 'delivery_abort_key'
