import {
  Advent2024AddToCartButtonClickBuilder,
  Advent2024ClickOnCartOnProductInYouWillLikeItSectionBuilder,
  Advent2024ClickOnProductInYouWillLikeItSectionBuilder,
  Advent2024ClickTakeTestButtonClickBuilder,
  Advent2024CopyPromocodeButtonClickBuilder,
  Advent2024GoToBackButtonClickBuilder,
  Advent2024GoToPurchasesButtonClickBuilder,
  Advent2024GoToPurchasesFromLastWindowButtonClickBuilder,
  Advent2024NextButtonInQuizButtonClickBuilder,
  Advent2024OnCellProductClickBuilder,
  Advent2024OnProductInCellClickBuilder,
  Advent2024OpenButtonClickBuilder,
  Advent2024OpenCartButtonClickBuilder,
  Advent2024PromocodesButtonClickBuilder,
  BtsButtonDownloadClickBuilder,
  BtsProductClickBuilder,
  BtsSocialClickBuilder,
  FormSentBuilder,
  HeroButtonClickBuilder,
  LoyaltyButtonClickBuilder,
  PuigOnClickOnGoToProductButtonBuilder,
  PuigOnClickOnStartButtonBuilder,
  PuigOnPutItemInFirstStepInBoxBuilder,
  PuigOnPutItemInSecondStepInBoxBuilder,
  TrixBloggerGoToProductPageBuilder,
  TrixBloggerProductsAddToCartBuilder,
  TrixButtonGoToBrandBuilder,
  TrixTopProductClickBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class InfoAnalytics extends BaseController {
  /**
   * Нажатие на кнопку "unlock your treasure" в hero-блоке лендинга Катара
   */
  onHeroButtonClick() {
    this.send(HeroButtonClickBuilder)
  }

  /**
   * Нажатие на кнопку "join" в блоке программы лояльности лендинга Катара
   */
  onLoyaltyButtonClick() {
    this.send(LoyaltyButtonClickBuilder)
  }

  /**
   * Отправка формы на странице лендинга Катара
   */
  onFormSent() {
    this.send(FormSentBuilder)
  }

  // back-to-school

  /**
   * Клик по кнопке "скачать чек-лист" лендинга back-to-school
   */
  btsOnButtonDownloadClick() {
    this.send(BtsButtonDownloadClickBuilder)
  }

  /**
   * Клик по продукту лендинга back-to-school
   */
  btsOnProductClick({ listName, itemSku }) {
    this.send(BtsProductClickBuilder, { listName, itemSku })
  }

  /**
   * Клик по иконке мессенджера/соц. сети back-to-school
   */
  btsOnSocialClick({ name, place }) {
    this.send(BtsSocialClickBuilder, { name, place })
  }

  // matrix

  /**
   * Клик по карточке продукта в секции топовые товары back-to-school
   */
  trixOnTopProductClick({ itemSku, blockName }) {
    this.send(TrixTopProductClickBuilder, { itemSku, blockName })
  }

  /**
   * Клик по кнопке перейти к бренду matrix
   */
  trixOnButtonGoToBrandClick() {
    this.send(TrixButtonGoToBrandBuilder)
  }

  /**
   *Клик по корзине товара в блоке блоггера matrix
   */
  trixOnButtonBloggerProductAddToCartClick({ itemSku, blockName }) {
    this.send(TrixBloggerProductsAddToCartBuilder, { itemSku, blockName })
  }

  /**
   *Клик по карточке товара в блоке блоггера matrix
   */
  trixOnProductBloggerClick({ itemSku, blockName }) {
    this.send(TrixBloggerGoToProductPageBuilder, { itemSku, blockName })
  }

  /**
   * Клик по кнопке открыть компонента welcome если пользователь авторизован
   */
  adventOnOpenButtonClick() {
    this.send(Advent2024OpenButtonClickBuilder)
  }

  /**
   * Клик по кнопке открыть модальное окно промокодов
   */
  adventOnOpenPromocodesModalWindowButtonClick() {
    this.send(Advent2024PromocodesButtonClickBuilder)
  }

  /**
   * Клик по кнопке открыть модальное окно корзины
   */
  adventOnOpenCartModalWindowButtonClick() {
    this.send(Advent2024OpenCartButtonClickBuilder)
  }

  /**
   * Клик по кнопке перейти к покупкам в модальном окне промокодов
   */
  adventOnGoToPurchasesButtonClick({ blockName }) {
    this.send(Advent2024GoToPurchasesButtonClickBuilder, { blockName })
  }

  /**
   * Клик по промокоду в модальном окне промокодов и копирование его названия
   */
  adventOnCopyPromoCodeButtonClick({ promoCodeName }) {
    this.send(Advent2024CopyPromocodeButtonClickBuilder, { promoCodeName })
  }

  /**
   * Клик по кнопке "Вернуться назад" на экране получения промокода после теста
   */
  adventOnGoBackButtonClick() {
    this.send(Advent2024GoToBackButtonClickBuilder)
  }

  /**
   * Клик по кнопке перейти к покупкам в окне получения промокода после прохождения теста
   */
  adventOnGoToPurchasesFromLastWindowButtonClick({ blockName }) {
    this.send(Advent2024GoToPurchasesFromLastWindowButtonClickBuilder, {
      blockName,
    })
  }

  /**
   * Клик до последней кнопки "Далее" во время прохождения квиза
   */
  adventGoToNextQuestionButtonClick() {
    this.send(Advent2024NextButtonInQuizButtonClickBuilder)
  }

  /**
   * Клик по кнопке добавить в корзину в модальном окне продукта
   */
  adventAddToCartButtonClick({ itemSku, type }) {
    this.send(Advent2024AddToCartButtonClickBuilder, { itemSku, type })
  }

  /**
   * Клик по кнопке пройти тест в модальном окне продукта
   */
  adventClickTakeTestButtonClick({ itemSku }) {
    this.send(Advent2024ClickTakeTestButtonClickBuilder, { itemSku })
  }

  /**
   *Клик по продукту в модальном окне секции тебе понравится
   */
  adventClickOnProductInYouWillLikeSection({ itemSku, blockName }) {
    this.send(Advent2024ClickOnProductInYouWillLikeItSectionBuilder, {
      itemSku,
      blockName,
    })
  }

  /**
   *Клик по закрытой ячейке продукта в адвент календаре
   */
  adventOnCellProductClick({ itemSku, cellNumber }) {
    this.send(Advent2024OnCellProductClickBuilder, {
      itemSku,
      cellNumber,
    })
  }

  /**
   *Клик по открытой ячейке продукта в адвент календаре
   */
  adventOnProductInCellClick({ itemSku, cellNumber, blockName }) {
    this.send(Advent2024OnProductInCellClickBuilder, {
      itemSku,
      cellNumber,
      blockName,
    })
  }

  /**
   *Клик по корзине карточки продукта в модальном окне секции тебе понравится
   */
  adventClickOnCartOnProductInYouWillLikeSection({ itemSku, type }) {
    this.send(Advent2024ClickOnCartOnProductInYouWillLikeItSectionBuilder, {
      itemSku,
      type,
    })
  }

  // puig

  /**
   * Клик по кнопке начать на welcome экране лендинга puig
   */
  puigOnClickOnStartButtonClick() {
    this.send(PuigOnClickOnStartButtonBuilder)
  }

  /**
   * Перетягивание варианта ответа в бокс на первом шаге
   */
  puigOnPutItemInFirstStepInBox({ stepNumber, blockName }) {
    this.send(PuigOnPutItemInFirstStepInBoxBuilder, { stepNumber, blockName })
  }

  /**
   * Перетягивание варианта ответа в бокс на втором шаге
   */
  puigOnPutItemInSecondStepInBox({ stepNumber, blockName }) {
    this.send(PuigOnPutItemInSecondStepInBoxBuilder, { stepNumber, blockName })
  }

  /**
   * Клик по кнопкам перейти к продукту/отправить в корзину
   */

  puigOnClickOnGoToPurchasesButton({ itemSku }) {
    this.send(PuigOnClickOnGoToProductButtonBuilder, { itemSku })
  }
}
