import { CLEAR_PAGES } from '../constants'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getListing() {
    const filtersFromQuery =
      await this.gaApp.services.purchased.filters.getFilteredQueryFilters()

    const page = this.gaApp.stores.purchased.plp.lastViewedPage

    const cacheData =
      this.gaApp.services.cache.main.getServerData('purchased-plp')

    const {
      id: cityId,
      cityDistrict,
      geoPolygons,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    const customerId = this.gaApp.stores.user.main?.data?.id

    const { data } =
      cacheData ||
      (await this.gaApp.repositories.purchased.main.plp({
        cityId,
        cityDistrict,
        geoPolygons,
        customerId,
        pageNumber: page,
        filters: filtersFromQuery,
      }))
    const { products, filters } = data

    this.gaApp.stores.purchased.plp.pages = []

    this.gaApp.services.purchased.plp.setProductsData(
      products,
      filtersFromQuery,
      page,
    )

    this.gaApp.services.purchased.plp.setHasProducts(products)

    this.gaApp.services.purchased.filters.setFiltersData(filters)

    this.gaApp.services.purchased.filters.updateFiltersUrlPostTask()
  }

  async fetchProductsByPage({ page = 1, clearPages = CLEAR_PAGES.NONE }) {
    try {
      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setCountSelectedFilters(
        this.gaApp.stores.filters.main.getFiltersMainSelected.length,
      )

      const cacheData =
        this.gaApp.services.cache.main.getServerData('purchased-products')

      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const customerId = this.gaApp.stores.user.main?.data?.id

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.purchased.main.products({
          cityId,
          cityDistrict,
          geoPolygons,
          customerId,
          offset: 0,
          pageSize: 24,
          pageNumber: page,
          filters: filtersSelected,
        }))

      if (clearPages === CLEAR_PAGES.AFTER_REQUEST) {
        this.gaApp.stores.purchased.plp.pages = []
      }

      this.gaApp.services.purchased.plp.setProductsData(
        data,
        filtersSelected,
        page,
      )
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('purchased.error.products'),
      )
    }
  }

  async getFilters() {
    try {
      this.gaApp.stores.filters.main.setFiltersPending(true)

      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setFiltersHash({
        filters: filtersSelected,
      })

      const {
        id: cityId,
        cityDistrict,
        geoPolygons,
      } = this.gaApp.services.location.main.getDeliveryAddress()

      const response = await this.gaApp.repositories.purchased.main.filters({
        filters: filtersSelected,
        cityId,
        cityDistrict,
        geoPolygons,
      })

      this.gaApp.services.purchased.filters.setFiltersData(response.data)

      return response
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('purchased.error.filters'),
      )

      this.gaApp.stores.filters.main.setFiltersPending(false)
    }
  }
}
