import debounce from 'lodash/debounce'

const SEARCH_DEBOUNCE_DELAY = 200

export class SearchService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.getSearchDataDebounced = debounce(
      this.getSearchDataDebounced,
      SEARCH_DEBOUNCE_DELAY,
    )
  }

  resetState() {
    this.gaApp.stores.location.search.$reset()
  }

  setItems(value) {
    this.gaApp.stores.location.search.items = value
    this.gaApp.stores.location.search.cities.items = value
  }

  pendingStart() {
    this.gaApp.stores.location.search.isPending = true
  }

  pendingStop() {
    this.gaApp.stores.location.search.isPending = false
  }

  updateDefaultState() {
    const isItemsEmpty = this.gaApp.stores.location.search.items.length === 0
    const isQueryEmpty =
      this.gaApp.stores.location.search.query.trim().length === 0

    const isDefaultState = this.gaApp.stores.location.search.isDefaultState
    let isDefaultStateUpdate = false

    if (isQueryEmpty || (isItemsEmpty && isQueryEmpty)) {
      isDefaultStateUpdate = true
    }

    if (isDefaultState !== isDefaultStateUpdate) {
      this.gaApp.stores.location.search.isDefaultState = isDefaultStateUpdate
    }
  }

  updateQuery(value) {
    this.gaApp.stores.location.search.query = value

    if (value.trim()) {
      this.pendingStart()
      this.getSearchDataDebounced(value)
    } else {
      this.pendingStop()
      this.updateDefaultState()
    }
  }

  async getSearchDataDebounced(value) {
    await this.gaApp.services.location.api.suggestCities(value)

    this.updateDefaultState()

    this.pendingStop()
  }

  /*
   * Возвращает подсказку с полными данными, включая локацию
   * */
  async getSuggestData(option) {
    const additionalData =
      await this.gaApp.services.location.search.fetchSuggestAdditionalData(
        option.value,
        option.fullAddress,
      )

    return {
      ...option,
      ...additionalData,
    }
  }

  /*
   * Запрос локации для адресных подсказок с раздельными запросами
   * */
  async fetchSuggestAdditionalData(suggestId, fullAddress) {
    this.pendingStart()
    const data = await this.gaApp.services.location.api.geocodeCities(
      suggestId,
      fullAddress,
    )
    this.pendingStop()

    return data
  }
}
