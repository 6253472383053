export const SIZE = {
  L: 'l',
  M: 'm',
  S: 's',
}

export const HEIGHT = {
  MAX: 460,
}

export const VISIBILITY_OPTIONS_LENGTH = {
  MAX: 9,
}
