import { EVENT_CATEGORY_PUIG } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmPuigOnClickOnGoToProductButtonModel extends GtmBaseModel {
  constructor({ itemSku }) {
    super({ eventCategory: EVENT_CATEGORY_PUIG })

    this.action_type = 'click_go_to_product'
    this.item_sku = itemSku
  }
}
