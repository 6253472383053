export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getCmsContent(key) {
    const { url } = this.endpoints.cms(key)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchContactsInfo() {
    const { url } = this.endpoints.contacts()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async launchQaSubscribe(params) {
    const { url } = this.endpoints.launchQaSubscribe()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async launchAeSubscribe(params) {
    const { url } = this.endpoints.launchAeSubscribe()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getProduct(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getProductBase()
    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      this.gaApp.api.error(error)
    }
  }

  async fetchProductsBySku(params) {
    const { url } = this.endpoints.fetchProductsBySku()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getXmasUser() {
    const { url } = this.endpoints.getXmasUser()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getXmasTasks() {
    const { url } = this.endpoints.getXmasTasks()

    const x = new Date()
    const currentTimeZoneOffsetInHours = Math.abs(
      x.getTimezoneOffset() / 60,
    ).toString()

    try {
      const response = await this.gaApp.api
        .request()
        .get(url, { params: { offset: currentTimeZoneOffsetInHours } })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async openXmasTask(params) {
    const { url } = this.endpoints.openXmasTask()

    try {
      const response = await this.gaApp.api
        .request()
        .post(url, null, { params })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async completeXmasTask(params) {
    const { url } = this.endpoints.completeXmasTask()

    const x = new Date()
    const currentTimeZoneOffsetInHours = Math.abs(
      x.getTimezoneOffset() / 60,
    ).toString()

    try {
      const response = await this.gaApp.api.request().post(url, null, {
        params: { ...params, offset: currentTimeZoneOffsetInHours },
      })
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
