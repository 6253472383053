import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import {
  CARDS_SUBSECTION_ROUTE,
  submenuCardsList,
} from '../../constants/submenu-cards'

export const useItems = (path, defaultItems, minimized) => {
  const { $gaApp } = useContext()

  const isGiftCardsIndependentNav = $gaApp.features.get(
    'giftCardsIndependentNav',
  )

  const getCardsItems = () => {
    const options = {
      isNewCorporateLink: $gaApp.features.get('giftCardsB2bNewLink'),
      isNewPlastic: $gaApp.features.get('giftCardsB2bNewPlastic'),
      isNewPlasticLink: $gaApp.features.get('giftCardsNewPlasticLink'),
    }

    return submenuCardsList($gaApp.i18n, options)
  }

  const items = computed(() => {
    const isCardsPath = unref(path).includes(CARDS_SUBSECTION_ROUTE)

    if (isCardsPath && isGiftCardsIndependentNav && !unref(minimized)) {
      return getCardsItems()
    }

    return defaultItems.value
  })

  const itemsMain = computed(() => {
    return items.value.filter((item) => !item.mods.additional)
  })

  const itemsAdditional = computed(() => {
    return items.value.filter((item) => item.mods.additional)
  })

  const hasItems = computed(() => unref(items)?.length)

  return {
    items,
    itemsMain,
    itemsAdditional,
    hasItems,
  }
}
