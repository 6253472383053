import { IMask } from 'vue-imask'

import { getPhoneWithoutMask } from '@ga/utils'

import { COUNTRY_GROUP } from '../constant'

export class PhoneService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get commonState() {
    return this.gaApp.stores.app.common
  }

  get phoneMetadataDefault() {
    return this.commonState.phoneCodes.find((phoneCode) => phoneCode.isDefault)
  }

  /**
   * Возвращает очищенный телефон
   * @param {String} phone // Строка с номером телефона
   * @param {('asNumber'|'withPlus')}  rules // Правила очистки
   */
  getSanitizedPhone(phone, rules) {
    return getPhoneWithoutMask(phone, rules)
  }

  /**
   * Метод для получения маски номера телефона
   * @param {string} phone  Строка с номером телефона
   * @returns {(string | '')} Возвращает маску номера телефона, если такая есть, иначе - пустую строку
   */
  getPhoneMask(phone) {
    const phoneCode = this.commonState.phoneCodes.find(
      ({ countryCode, prefixAliases, lengths }) => {
        const isMaxLength = lengths.includes(phone.length)
        const prefixAlias = prefixAliases.find((alias) =>
          phone.startsWith(alias),
        )

        if (prefixAlias && isMaxLength) {
          return true
        }

        return phone.startsWith(countryCode)
      },
    )

    const mask = phoneCode?.masks.international

    return mask || ''
  }

  getFormattedPhone(phone) {
    let phoneToFormat = phone

    const phoneCode = this.commonState.phoneCodes.find(
      ({ countryCode, prefixAliases, lengths }) => {
        const isMaxLength = lengths.includes(phoneToFormat.length)
        const prefixAlias = prefixAliases.find((alias) =>
          phoneToFormat.startsWith(alias),
        )

        if (prefixAlias && isMaxLength) {
          phoneToFormat = phoneToFormat.replace(prefixAlias, countryCode)

          return true
        }

        return phoneToFormat.startsWith(countryCode)
      },
    )

    const mask = phoneCode?.masks.international

    if (!mask) {
      return phone
    }

    return IMask.createMask({
      mask,
    }).resolve(phoneToFormat)
  }

  isCISPhone(phoneCode) {
    return COUNTRY_GROUP.CIS.includes(phoneCode)
  }
}
