/**
 * @typedef {Number} Hour24 - стандартный час (0 - 23)
 */

/**
 * @typedef {'am'|'pm'} AMPM - период am/pm
 */

/**
 * Возвращает 12ч период, соответствующий часу
 * @param { Hour24 } hour
 * @returns { AMPM }
 */
export const getAMPM = (hour) => (hour >= 12 ? 'pm' : 'am')
