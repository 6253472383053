export const state = () => {
  return {
    form: {
      birthday: '',
      firstName: '',
      lastName: '',
      middleName: '',
      gender: 0,
      email: '',
      phone: '',
      city: {
        name: '',
        cityFiasId: '',
      },
      personalDataSigned: false,
      subscriptionsSigned: false,
    },
    formErrors: null,

    personalDataSigned: false,
    subscriptionsSigned: false,
    isEmailConfirmed: false,

    wasSaved: true,

    wasPhoneSaved: false,
    isBirthdayEditBlocked: true,

    isEmailConfirmModalOpened: false,
    isSupportEditModalOpened: false,
    isSaving: false,
    fields: null,
  }
}
