import cloneDeep from 'lodash/cloneDeep'

export class PlasticCounterpartyService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /** Очистка информации о контрагенте */
  clearInfo() {
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.isInfoVisible = false
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.info = {}
  }

  /** Очистка чекбокса подтверждения данных контрагента */
  clearCheckbox() {
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.form.agreed = false
  }

  /**
   * Заполнение информации о контрагенте, исключая поле blocked
   * @param {object} info информация о контрагенте
   */
  fillInfo(info) {
    const copy = cloneDeep(info)

    delete copy.blocked

    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.info = copy
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.isInfoVisible = true
  }

  /** Маска ИНН */
  getInnMask(maxLength) {
    return new RegExp(`^[0-9]{0,${maxLength}}$`)
  }

  setIsLoading(value) {
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.isLoading = value
  }

  async findByInn(inn, setError) {
    this.clearInfo()
    this.setIsLoading(true)

    await this.gaApp.services.giftCardsB2b.api.findByInnPlastic(inn, setError)

    this.setIsLoading(false)
  }
}
