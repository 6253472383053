import { SCREEN_TYPES } from '../../../constants'

export class GtmGa4BannerBaseModel {
  constructor(data) {
    this.item_list_id = SCREEN_TYPES.ACCOUNT

    for (const key in data) {
      this[key] = data[key]
    }
  }
}
