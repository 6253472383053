export class DigiAnalyticsCategoryViewModel {
  constructor({category, page}) {
    const { name, id, breadcrumbs} = category
    const { products, number } = page
    
    this.data = {
      eventType: "CATEGORY_VIEW",
      pageNumber: number?.toString(),
      categoryId: id,
      categoryName: name,
      breadcrumbs: breadcrumbs?.length ? breadcrumbs.map(b => b.text?.toLowerCase()) : [name],
      pageProducts: products?.map(({ itemId }) => itemId) ?? [],
    }
  }
}
