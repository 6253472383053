import { EVENT_CATEGORY_PUIG } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmPuigOnPutItemInSecondStepInBoxModel extends GtmBaseModel {
  constructor({ stepNumber, blockName }) {
    super({ eventCategory: EVENT_CATEGORY_PUIG })

    this.action_type = 'put_response'
    this.step_number = stepNumber
    this.block_name = blockName
  }
}
