export const getters = (gaApp) => ({
  /**
   * Сохраненнные адреса + временный адрес
   */
  addressesAsObject() {
    const result = {}
    this.addresses.forEach((item) => {
      result[item.addressId + (item.saveId || '')] = item
    })

    return result
  },

  /**
   * Только сохраненные адреса
   */
  savedAddressesAsObject() {
    const result = {}
    this.addresses.forEach((item) => {
      if (item.saveId) {
        result[item.saveId] = item
      }
    })

    return result
  },

  /**
   * Сохраненные курьерские адреса в виде объекта
   */
  savedAddressesAsArray: ({ savedAddressesAsObject }) =>
    Object.values(savedAddressesAsObject || {}),

  /**
   * Флаг наличия сохраненных курьерских адресов
   */
  hasSavedAddresses: ({ savedAddressesAsArray }) =>
    Boolean(savedAddressesAsArray.length),

  /**
   * Получение сохраненного адреса по id
   */
  getSavedAddressById: ({ savedAddressesAsObject }) => {
    return (addressId) => (savedAddressesAsObject || {})[addressId]
  },

  defaultAddressId() {
    if (this.addresses.length === 1) {
      const address = this.addresses[0]
      return address.addressId + (address.saveId + '') || null
    }

    if (this.addresses.length > 1) {
      const address = this.addresses.find((item) => item.isDefaultAddress)
      return address.addressId + (address.saveId + '') || null
    }

    return null
  },
})
