import isArray from 'lodash/isArray'

export class StepsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает текущий список шагов
   * @param {string[]} steps список шагов
   */
  setSteps(steps, stepIndex = 0) {
    if (!isArray(steps)) {
      return undefined
    }

    this.gaApp.stores.giftCardsB2b.steps.items = steps
    this.setCurrent(stepIndex)
  }

  /**
   * Устанавливает номер активного шага
   * @param {number} newStepIndex новый индекс выбранного шага
   */
  setCurrent(newStepIndex) {
    const { minStep, maxStep } = this.gaApp.stores.giftCardsB2b.steps

    if (newStepIndex < minStep) {
      this.gaApp.stores.giftCardsB2b.steps.stepIndex = minStep

      return undefined
    }

    if (newStepIndex > maxStep) {
      this.gaApp.stores.giftCardsB2b.steps.stepIndex = maxStep

      return undefined
    }

    this.gaApp.stores.giftCardsB2b.steps.stepIndex = newStepIndex

    /** Ресет позиции на странице при смене шага */
    window.scrollTo(0, 0)
  }

  /**
   * Переход к следующему шагу
   */
  goForward() {
    if (!this.gaApp.stores.giftCardsB2b.steps.canGoForward) {
      return undefined
    }

    this.setCurrent(this.gaApp.stores.giftCardsB2b.steps.stepIndex + 1)
  }

  /**
   * Переход к предыдущему шагу
   */
  goBack() {
    if (!this.gaApp.stores.giftCardsB2b.steps.canGoBack) {
      return undefined
    }

    this.setCurrent(this.gaApp.stores.giftCardsB2b.steps.stepIndex - 1)
  }
}
