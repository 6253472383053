import ar from './ar.json'
import en from './en.json'
import ru from './ru.json'
import ruRU from './ru-RU.json'
import ruRUOptions from './ru-RU.options.json'

export const locales = {
  'ar-AE': ar,
  'ar-QA': ar,
  'ar-SA': ar,
  'ar-RU': ar,
  'en-AE': en,
  'en-QA': en,
  'en-SA': en,
  'en-RU': en,
  'ru-RU': [ru, ruRU, ruRUOptions],
}
