import { computed, unref } from 'vue'

import { HEIGHT, VISIBILITY_OPTIONS_LENGTH } from '../constants'

export const useSwipeModal = (options) => {
  const swipeModalHeight = computed(() => {
    if (unref(options).length > VISIBILITY_OPTIONS_LENGTH.MAX) {
      return HEIGHT.MAX
    }

    return 'auto'
  })

  return {
    swipeModalHeight,
  }
}
