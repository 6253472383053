export * from './support-messengers'

export const SCROLL_HASH_PREFIX = '#s-'
export const SCROLL_HASH_PREFIX_REGEXP = new RegExp(`^${SCROLL_HASH_PREFIX}`)
export const IS_ACCEPTED_COOKIES_KEY = 'is-accepted-cookies'
export const DEVICE_ID_COOKIES_KEY = 'ga-device-id'
export const CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY =
  'ga-change-country-confirmed'
export const COOKIES_NOFIFICATION_ANSWERED_KEY =
  'ga-cookie-notification-answered'
export const WEBVIEW_COOKIES_KEY = 'ga-webview'

export const SETTINGS_LANG_NAMES_MAP = {
  ru_RU: 'ru',
}

export const COUNTRY = {
  RU: 'ru',
  BY: 'by',
  KZ: 'kz',
  QA: 'qa',
  AE: 'ae',
  SA: 'sa',
}

export const COUNTRY_GROUP = {
  ME: [COUNTRY.QA, COUNTRY.AE, COUNTRY.SA], // Middle East
  CIS: [COUNTRY.RU, COUNTRY.BY, COUNTRY.KZ], // СНГ
}
