export const state = () => ({
  loading: false,
  opened: false,
  week: 1,
  modals: {
    modalGiftIsOpen: false,
    modalPromoIsOpen: false,
    modalQuizIsOpen: false,
  },

  userData: {
    userId: null,
    lastOpenDay: 0,
    promoCodes: [{}, {}, {}, {}],
  },
  tasks: [],
  openedTaskProductPending: false,
  openedTaskProduct: null,
  openedTaskPlacements: [],
  openedTask: null,
})
