import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

/**
 * Предоставляет сохранненые курьерские адреса в формате location
 */
export const useUserSavedAddresses = () => {
  const { $gaApp } = useContext()

  const savedAddressesAsArray = computed(
    () => $gaApp.stores.user.main.savedAddressesAsArray,
  )

  const getSavedAddressById = (addressId) => {
    return $gaApp.stores.user.main.getSavedAddressById(addressId)
  }

  return {
    savedAddressesAsArray,
    getSavedAddressById,
  }
}
