import { EVENT_CATEGORY_ADVENT2024 } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmAdvent2024AddToCartButtonClickModel extends GtmBaseModel {
  constructor({ itemSku, type }) {
    super({ eventCategory: EVENT_CATEGORY_ADVENT2024 })

    this.action_type = 'add_to_cart'
    this.item_sku = itemSku
    this.type = type
  }
}
