import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { GaButton } from '@ga/ui-components/button'
import { GaImage } from '@ga/ui-components/image'
import { GaLinkBase } from '@ga/ui-components/link-base'
import { GaModalCloseButton } from '@ga/ui-components/modal/close-button'

import { WELCOME_TYPE } from '../../constants'

import {
  BACKGROUND_IMAGE,
  COVER_IMAGE,
  FOREGROUND_IMAGE,
} from './scripts/const'

export default {
  name: 'ga-auth-modal-welcome',

  components: {
    GaModalCloseButton,
    GaImage,

    GaButton,
    GaLinkBase,
  },

  setup() {
    const { $gaApp } = useContext()

    const country = computed(() => $gaApp.i18n.locale.country)

    const isMiddleEast = computed(() => $gaApp.isMiddleEast)

    const type = computed(() => $gaApp.stores.auth.modal.welcomeType)

    const fromTabletSmall = computed(() => $gaApp.mq('tablet-small+'))

    const lang = computed(() => $gaApp.i18n.locale.code)

    const coverImage = computed(() => COVER_IMAGE[unref(lang)])
    const foregroundImage = computed(() => FOREGROUND_IMAGE[unref(lang)])
    const backgroundImage = computed(() => BACKGROUND_IMAGE[unref(lang)])

    const title = computed(() =>
      unref(isMiddleEast) && unref(type) === WELCOME_TYPE.OTHER_COUNTRIES_USER
        ? $gaApp.i18n.t(
            `auth.modalWelcomeFormNew.${unref(type)}.${unref(country)}Title`,
          )
        : $gaApp.i18n.t(`auth.modalWelcomeFormNew.${unref(type)}.title`),
    )

    const subtitleLink = computed(() =>
      $gaApp.i18n.t(`auth.modalWelcomeFormNew.${unref(type)}.subtitleLink`),
    )

    return {
      type,

      title,
      subtitleLink,

      fromTabletSmall,

      coverImage,
      foregroundImage,
      backgroundImage,
    }
  },
}
