import isEmpty from 'lodash/isEmpty'

import { computed, watch } from 'vue'

import { AUTOMATED_TESTING_ADDRESS_CONFIRMED } from '@ga/constants/automated-testing'

import { areFieldsEqual } from '../../helpers'
import { symaphoreOrdered } from '../../utils'

export const mainService = (gaApp) => ({
  setLocationDefault() {
    const { value: id, text: locality } = gaApp.services.app.main.getMainCity()

    if (!id || !locality) return

    gaApp.stores.location.main.location = {
      id,
      city: locality,
      isDefaultLocation: true,
    }

    // this.setLocation({
    //   id,
    //   city: locality,
    // })
  },

  setLocation(location) {
    const prevLocation = gaApp.stores.location.main.location
    gaApp.stores.location.main.location = location

    if (
      !isEmpty(prevLocation) &&
      !areFieldsEqual(prevLocation, location, [
        'city',
        'street',
        'house',
        'block',
        'blockType',
        'geoPolygons',
      ])
    ) {
      gaApp.eventBus.publish('module/location/address-change')
    }
  },

  setLocationTemp(location) {
    gaApp.stores.location.main.locationTemp = location
  },

  setCache(location) {
    this.setLocation(location)
    gaApp.stores.location.main.isCacheData = true
  },

  resetState() {
    gaApp.stores.location.main.$reset()
  },

  openModal(screenType) {
    const isAddressBlockEnabled =
      gaApp.stores.app.common.toggle.addressBlockEnabled
    const address = gaApp.stores.location.main.location
    const addresses = gaApp.stores.user.main.addresses
    gaApp.analytics.modules.location.onClickChangeAddress(screenType)

    if (!isAddressBlockEnabled) {
      gaApp.services.location.modal.open()
      return
    }

    if (!gaApp.stores.user.main.isAuthorized || !addresses.length) {
      gaApp.services.location.specify.openEditModal({ screenType })
      return
    }

    if (addresses.length > 1) {
      gaApp.services.location.specify.openSelectModal(screenType)
      return
    }

    if (address.saveId && address.saveId === addresses[0].saveId) {
      gaApp.services.location.specify.openEditModal({ screenType })
    } else if (address.exactId && address.exactId === addresses[0].exactId) {
      gaApp.services.location.specify.openEditModal({ screenType })
    } else {
      gaApp.services.location.specify.openSelectModal(screenType)
    }
  },

  getGeoPolygons(location = gaApp.stores.location.main.location) {
    const polygons = {}

    if (
      gaApp.features.get('geoPolygonDeliveryAreas') &&
      location?.geoPolygons
    ) {
      polygons.geoPolygons = location.geoPolygons
    }

    return polygons
  },

  getAddressFormattedName(location = gaApp.stores.location.main.location) {
    return gaApp.stores.app.common.toggle.addressBlockEnabled
      ? gaApp.services.location.formatter.formatLocationDeliveryName(location)
      : location.city
  },

  getDeliveryAddress() {
    const location = gaApp.stores.location.main.location

    const {
      city,
      cityDistrict,
      street,
      house,
      latitude,
      longitude,
      id,
      saveId,
      isManyStores,
    } = location

    return {
      cityDistrict,
      city,
      latitude,
      longitude,
      id,
      saveId,
      isManyStores,

      isStreetOrHouse: Boolean(street || house),
      formattedName: this.getAddressFormattedName(location),
      ...this.getGeoPolygons(location),
    }
  },

  setWatchUser() {
    const isAuthorized = computed(() => gaApp.stores.user.main.isAuthorized)

    watch(isAuthorized, async (value) => {
      // разлогинились
      if (!value) {
        gaApp.stores.user.main.addresses = []

        return
      }

      // залогинились и адресный блок включен
      if (gaApp.stores.app.common.toggle.addressBlockEnabled) {
        gaApp.services.location.confirm.setAddressConfirmed(false)
        // gaApp.services.user.api.getAddress()

        await this.setSelectedAddress(null, false)
        gaApp.services.location.cache.removeGuestSelectedAddress()

        if (
          gaApp.stores.location.confirm.disabled ||
          gaApp.isAutomatedTesting ||
          gaApp.automatedTestingConfig?.[AUTOMATED_TESTING_ADDRESS_CONFIRMED]
        ) {
          return
        }

        this.processAddresses()

        return
      }

      // залогинились и адресный блок выключен
      gaApp.services.location.cache.removeGuestSelectedAddress()
    })
  },

  /**
   * Установка адреса
   */
  async setInitialLocation() {
    if (gaApp.features.get('addressSelectAddressToPlaid')) {
      await this.setInitialPlaidLocation()
    } else {
      await this.setInitialMagentoLocation()
    }
  },

  /**
   * Установка адреса, мага
   */
  async setInitialMagentoLocation() {
    // подтягиваем селектед адресс с сессии | адрес по ip | дефолтный город
    await gaApp.services.location.api.getData()

    if (
      gaApp.stores.app.common.toggle.addressBlockEnabled &&
      gaApp.services.user.main.isAuthorized
    ) {
      // подтягиваем селектед адресс с сессии и список адресов
      await gaApp.services.user.api.getAddress()
    }
  },

  /**
   * Установка адреса, плед
   */
  async setInitialPlaidLocation() {
    if (gaApp.services.user.main.isAuthorized) {
      await this.setInitialPlaidLocationCustomer()
    } else {
      await this.setInitialPlaidLocationGuest()
    }
  },

  /**
   * Установка адреса, плед для гостя
   * для гостя адрес хранится в сторадже
   */
  async setInitialPlaidLocationGuest() {
    // берем ранее сохраненный адрес с стораджа
    const guestSelectedAddressFromCache =
      gaApp.services.location.cache.getSelectedAddress()

    // если сторадж был пустой, то пишем туда дефолтную локацию
    // сторадж не должен быть пустым
    // в этот момент в gaApp.stores.location.main.location дефолтная локация (для РФ  это Москва)
    if (!guestSelectedAddressFromCache) {
      gaApp.services.location.cache.saveGuestSelectedAddress(
        gaApp.stores.location.main.location,
      )
    }

    // адрес со стораджа пишем с стор
    // если в сторадже дефолтная локация, то
    //  - можно не дергать setLocation, потому что в сторадже и в сторе одинаковая локация
    //  - можно попробовать получить адрес по ip
    if (
      guestSelectedAddressFromCache &&
      !guestSelectedAddressFromCache.isDefaultLocation
    ) {
      await this.setGuestLocationFromCache(guestSelectedAddressFromCache)
      return
    }

    // пробуем получить адрес по ip
    const addressByIp = await gaApp.services.location.api.getLocationByIp()

    if (addressByIp) {
      gaApp.services.location.main.setLocation(addressByIp)
    }
  },

  async setGuestLocationFromCache(location) {
    const { lang, addressId, id, saveId, exactId } = location

    // Селектим сохранненый адрес для смены языка у гостя
    // актуально для катара и эмиратов
    // передаем только id, остальные отсекаем потому что там есть текста, которые нам надо обновить
    // если ранее были на чекауте, то некоторые данные в selected_address могут потерятся, но
    //  1) на чекауте это не отобразится, бэк подтянет в shipping_address все что нужно.
    //  shipping_address потеряются данные только если при инициализации чекаута мы передадим другой фиас из selected_address
    //  2) на остальном вебе отображается только город, потерю данных не заметим
    // todo: для адресного блока такая реализация не пойдет, сделать лучше (в связке с бэком)
    //  предварительно должно быть что-то такое:
    //    - передавать не фиас, а весь адрес
    //    - передвать флаг freeInput, чтобы бэк понимал что нужно перевести, а что нет
    if (lang && lang !== gaApp.i18n.locale.code) {
      await gaApp.services.location.main.setSelectedAddress({
        addressId,
        id,
        saveId,
        exactId,
      })
      return
    }

    gaApp.services.location.main.setLocation(location)
  },

  async setInitialPlaidLocationCustomer() {
    // берем со стораджа дубль выбрнанного адреса с сессии маги
    // migrateSelectedAddress обновляется только если мы ходим в магу
    const migrateSelectedAddress =
      gaApp.services.location.cache.getMigrateSelectedAddress()

    // если в сторадже остался адрес с сессии маги, то сетим этот адрес в плед и чистим сторадж
    // это необходимо только для миграции, выполнится один раз
    if (migrateSelectedAddress) {
      // внутри setSelectedAddress дергается setLocation
      gaApp.services.location.main.setSelectedAddress(migrateSelectedAddress)
      gaApp.services.location.cache.removeMigrateSelectedAddress()
      return
    }

    // получаем адрес с пледа
    // внутри getAddress дергается setLocation
    await gaApp.services.user.api.getAddress()
  },

  async prepareData() {
    await this.setInitialLocation()
  },

  getData() {
    const prepareData = this.prepareData()

    /*
     * TODO: подписываемся сразу, ждем данных
     *  если подписаться позже - можем пропустить событие page-created и коллбэк не отработает
     *  если не ждать данные, то подставятся гео адрес, не дожидаясь пока придет selectedAddress
     * */
    gaApp.eventBus.subscribe('module/app/router/page-created', ({ meta }) => {
      prepareData.then(() => {
        this.onPageCreated({ meta })
      })
    })
  },

  onPageCreated({ meta }) {
    gaApp.services.location.confirm.setDisabled(meta.addressDisabled ?? false)

    if (
      meta.addressDisabled ||
      gaApp.services.location.confirm.isAddressConfirmed()
    )
      return

    if (gaApp.stores.app.common.toggle.addressBlockEnabled) {
      gaApp.services.location.main.processAddresses()
      return
    }

    gaApp.services.location.confirm.requestOpening()

    // на пледе мы дергаем селект, а вместе с ним и подтверждение
    if (!gaApp.features.get('addressSelectAddressToPlaid')) {
      const { id: cityId } = gaApp.services.location.main.getDeliveryAddress()
      gaApp.services.location.confirm.saveClientLocation(cityId)
    }
  },

  async processAddresses() {
    const address = gaApp.stores.location.main.location
    const addressDefault = gaApp.stores.user.main.addresses.find(
      (element) => element.isDefaultAddress,
    )

    if (addressDefault) {
      /*
       * Нужно учитывать что cityDistrict, geoPolygons есть только в selectedAddress
       * поэтому событие смены адреса нужно триггерить после setSelectedAddress,
       * иначе может быть неправильно рассчитана доставка
       * */

      /*
       * поскольку не вызываем confirm.open(), который так или иначе ведет к вызову setSelectedAddress()
       * то, делаем это тут, чтобы на бэке и фронте в жил один адрес
       * иначе это вылезет при переходе на чекаут
       * */

      if (address.exactId !== addressDefault.exactId) {
        this.setSelectedAddress(addressDefault, false)
      } else {
        this.setLocation(addressDefault)
      }

      return
    }

    const addressGeo = await this.getGeoAddress()

    this.processAddressesWithGeo({ address, addressDefault, addressGeo })
  },

  processAddressesWithGeo({ address, addressDefault, addressGeo }) {
    // гость
    // есть селектед
    //  определяем по гео
    //  если гео нет ставим селектед

    // пользак
    // есть селектед
    //  определяем по гео
    //  если гео нет ставим селектед
    // есть дефолт
    //  определяем по гео
    //  если гео нет ставим дефолт

    // нет селектед адреса
    //   определеяем по гео
    //   если гео нет ставим дефолт

    if (address.isSelectedAddress && addressGeo) {
      if (address.exactId !== addressGeo.exactId) {
        gaApp.services.location.confirm.requestOpening()
      }
      return
    }

    if (addressDefault && addressGeo) {
      this.setLocation(addressDefault)

      if (addressDefault.exactId !== addressGeo.exactId) {
        gaApp.services.location.confirm.requestOpening()
      }

      if (!gaApp.services.location.confirm.isAddressConfirmed()) {
        gaApp.services.location.confirm.requestOpening()
      }
      return
    }

    if (!address.isSelectedAddress && addressGeo) {
      this.setLocation(addressGeo)
      gaApp.services.location.confirm.requestOpening()

      return
    }

    if (!addressGeo) {
      gaApp.services.location.confirm.requestOpening()
    }
  },

  async getGeoAddress() {
    let addressGeo = null

    const { latitude, longitude } =
      await gaApp.services.location.geolocation.browserDetection()
    if (latitude && longitude) {
      try {
        addressGeo = await gaApp.services.location.api.getGeolocation(
          {
            latitude,
            longitude,
          },
          { hasAlertError: false },
        )
      } catch (error) {}
    }

    return addressGeo
  },

  async setSelectedAddress(data, isAddressConfirmed = true) {
    const address = data || gaApp.stores.location.main.location

    if (!address) {
      return
    }

    await gaApp.services.location.api.setAddress(address)

    gaApp.services.location.main.setLocationTemp(null)
    gaApp.stores.location.specify.addressIdTemp = null

    // если addressBlockEnabled выключен, то используется другой механизм проверки
    // на подтверждение, поэтому дергать setAddressConfirmed нет необходимости
    if (
      isAddressConfirmed &&
      gaApp.stores.app.common.toggle.addressBlockEnabled
    ) {
      gaApp.services.location.confirm.setAddressConfirmed()
    }
  },

  /**
   * Нужно для отслеживания смены адреса на этапе первичной загрузки приложения.
   */
  watchInitialLocationChange() {
    // Объект для управления порядком событий
    let symaphore = null

    // События, на которые нужно подписаться
    const ADDRESS_CHANGE = 'module/location/address-change'
    const PAGE_MOUNTED = 'module/app/router/page-mounted'

    // События которые нужно прослушать. Порядок имеет значение
    const events = [ADDRESS_CHANGE, PAGE_MOUNTED]

    // Подписываемся на события, и возвращаем массив отписок
    const subscriptions = events.map((event) =>
      gaApp.eventBus.subscribe(event, () => symaphore(event)),
    )

    // Отписываемся от прослушивания событий и очищаем объект симафора
    const clear = () => {
      subscriptions.forEach((unsubscribe) => unsubscribe())

      symaphore = null
    }

    /**
     * Целевой кейс: Адрес сменился во время загрузки страницы
     * Порядок отрабатывания событий - [address-change -> page-mounted]:
     * Когда отработает события монтирования страницы, будет отправлено событие смены адреса.
     * Основной слушатель смены адреса обработает это событие.
     *
     * Остальные кейсы: Адрес сменился после загрузки страницы
     * Порядок отрабатывания событий - [page-mounted -> address-change]:
     * Основной слушатель смены адреса обработает это событие.
     */
    symaphore = symaphoreOrdered().create({
      onSuccess: () => gaApp.eventBus.publish(ADDRESS_CHANGE),
      onFinish: () => clear(),
      flags: events,
    })

    return symaphore
  },

  /**
   * Пересохраняем выбранный адрес в кэше
   * Фиксит некорректную обработку row: null на старом чекауте
   * todo: убрать как чекаут станет корректно обрабатывать
   */
  reSaveSelectedAddressFromCache() {
    const selectedAddress = gaApp.services.location.cache.getSelectedAddress()

    if (selectedAddress) {
      gaApp.services.location.cache.setSelectedAddress(selectedAddress)
    }
  },
})
