import { TYPE } from '../../constants'
import { GtmAdvent2024OnCellProductClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class Advent2024OnCellProductClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmAdvent2024OnCellProductClickModel]])
    super(models, data)
  }
}
