import { advBannersEndpoints } from './endpoints/adv-banners'
import { endpoints } from './endpoints/index'
import { streamsEndpoints } from './endpoints/streams'
import { AdvBannersRepository } from './adv-banners.repository'
import { MainRepository } from './main.repository'
import { StreamsRepository } from './streams.repository'

export const repositories = (gaApp) => ({
  main: new MainRepository(gaApp, endpoints),
  streams: new StreamsRepository(gaApp, streamsEndpoints),
  advBanners: new AdvBannersRepository(gaApp, advBannersEndpoints),
})
