import { EVENT_CATEGORY_ADVENT2024 } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmAdvent2024ClickTakeTestButtonClickModel extends GtmBaseModel {
  constructor({ itemSku }) {
    super({ eventCategory: EVENT_CATEGORY_ADVENT2024 })

    this.action_type = 'click_take_test'
    this.item_sku = itemSku
  }
}
