import { TYPE } from '../../constants'
import { buildVariant } from '../../helpers'

export class BaseController {
  constructor(gaApp, core) {
    this.gaApp = gaApp

    this.core = core

    this.metrics = {
      [TYPE.GTM_4]: this.core.all?.gtm,
      [TYPE.MINDBOX]: this.core.all?.mindbox,
      [TYPE.GDESLON]: this.core.all?.gdeslon,
      [TYPE.GAA]: this.core.all?.gaAnalytics,
      [TYPE.DIGI]: this.core.all?.digiAnalytics,
    }

    this.referer = null

    this.getCurrencyService = () => this.gaApp.services.app.currency
  }

  // Этот метод используется в других модулях
  getItemListName(analyticData) {
    return analyticData.path
  }

  getProductPosition(product) {
    return product.index + 1
  }

  getItemListId() {
    return this.gaApp.stores.plp.main.getCategoryData?.id
  }

  getPrice(price) {
    return {
      amount: this.getCurrencyService().getNominal(price.actual),
      currency: price.actual.currency,
    }
  }

  getProductData(product, analyticData) {
    const user = this.gaApp.stores.user.main.data

    const userId = user?.id ?? null

    const { itemId, name, brand, inStock, attributes, prices } = product

    const { plpType, searchTerm } = analyticData

    const { amount, currency } = this.getPrice(prices)

    return {
      name,
      brand,
      inStock,
      breadcrumbs: null,
      userId,
      itemListId: analyticData.itemListId,
      id: itemId,
      price: amount,
      currency,
      plpType,
      searchTerm: searchTerm || '',
      categoryId: analyticData.categoryId || null,
      category: null,
      variant: buildVariant(attributes),

      list: this.getItemListName(analyticData),
      position: this.getProductPosition(product),

      additional: analyticData.additional || {},
    }
  }

  // Отправить данные в сервисы аналитики
  send(Builder, data) {
    const { country } = this.gaApp.i18n.locale
    new Builder(data, country).build().forEach((data, key) => {
      const metric = this.metrics[key]

      if (metric && metric.send) {
        metric.send(data)
      }
    })
  }
}
