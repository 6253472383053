export const getters = {
  taskIdByDay(state) {
    return (day) => {
      return state.tasks.find((task) => task.day === day)?.id || null
    }
  },

  openedTaskQuizCompleted() {
    if (!this.openedTask) {
      return false
    }

    return !!this.userData.promoCodes.find((promoCode) => {
      return promoCode.day === this.openedTask.day
    })
  },

  promoCodes() {
    return this.userData.promoCodes.map((promoCode) => {
      const task = this.tasks.find((task) => task.day === promoCode.day)

      if (!task || !task.coupon) {
        return { couponCode: promoCode.value }
      }

      return {
        available: true,
        applied: false,
        shortDescription: task.coupon.description,
        subtitle: task.coupon.subtitle,
        title: task.coupon.title,
        couponCode: promoCode.value,
      }
    })
  },
}
