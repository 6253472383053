export const STEP = {
  START: 'start',
  LOCALE_CHANGING: 'locale-changing',
  CONFIRM: 'confirm',
  WELCOME: 'welcome',
}

export const STEPS_ORDER = [STEP.START, STEP.CONFIRM, STEP.WELCOME]

export const WELCOME_TYPE = {
  NEW_USER: 'newUser',
  OTHER_COUNTRIES_USER: 'otherCountriesUser',
}
