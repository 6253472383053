import { trimObjectStrings } from '@ga/utils'

import { DELIVERY_TYPE, MOSCOW_CITY_ID } from '../../constants'

export const getters = (gaApp) => ({
  total() {
    return this.sets.items.reduce(
      (total, set) => total + (set.cardsValue || 0) * (set.cardsAmount || 0),
      0,
    )
  },

  packagesCount() {
    return this.sets.items.reduce((acc, set) => {
      if (!set.cardsAmount) return acc

      return acc + set.cardsAmount
    }, 0)
  },

  setsCount() {
    return this.sets.items.length
  },

  isDeliveryVisible() {
    return (
      this.deliveryInfo.type === DELIVERY_TYPE.DELIVERY &&
      !!this.deliveryInfo.addressString
    )
  },

  isDeliveryType() {
    return this.deliveryInfo.type === DELIVERY_TYPE.DELIVERY
  },

  selectedCityText() {
    return this.config.cities.find((city) => city.value === this.config.city)
      ?.text
  },

  counterpartyInfo() {
    return {
      inn: this.counterparty.form.inn,
      ...this.counterparty.info,
    }
  },

  contactInfo() {
    return {
      email: this.contacts.form.contactEmail,
      name: this.contacts.form.contactName,
      phone: this.contacts.form.contactPhone,
    }
  },

  formattedContactPhone() {
    return gaApp.services.app.phone.getFormattedPhone(
      this.contacts.form.contactPhone,
    )
  },

  formattedRecipientPhone() {
    return gaApp.services.app.phone.getFormattedPhone(
      this.contacts.form?.recipientPhone,
    )
  },

  uploadedFile() {
    return this.contacts.form?.document
  },

  recipientInfo() {
    return {
      email: this.contacts.form.contactEmail,
      name: this.contacts.form.recipientName,
      phone: this.contacts.form.recipientPhone,
    }
  },

  deliveryInfoForm() {
    return {
      type: this.deliveryInfo.type,
      storeId: this.deliveryInfo.store?.id,
      comment: this.deliveryInfo.comment,
    }
  },

  /** Данные адреса для формы в зависимости от способа доставки */
  addressInfoForm() {
    const { type, addressForm, store } = this.deliveryInfo

    const isDelivery = type === DELIVERY_TYPE.DELIVERY

    return {
      cityId: this.config.city,
      line1: isDelivery ? addressForm.street : store?.address,
      line2: isDelivery ? addressForm.apt : '',
      intercom: isDelivery ? addressForm.intercom : '',
      entrance: isDelivery ? addressForm.entrance : '',
      floor: isDelivery ? addressForm.floor : '',
    }
  },

  /** Информация о наборах */
  setsInfo() {
    return this.sets.items.map((set) => ({
      itemId: set.card.itemId,
      cardsAmount: set.cardsAmount,
      cardsValue: set.cardsValue,
    }))
  },

  /** Сбор данных формы для заказа КППК (новый флоу) */
  form() {
    const deliveryInfoTrimmed = {
      ...trimObjectStrings(this.deliveryInfoForm),
      address: trimObjectStrings(this.addressInfoForm),
    }

    return {
      sets: this.setsInfo,
      counterpartyInfo: trimObjectStrings(this.counterpartyInfo),
      contactInfo: trimObjectStrings(this.contactInfo),
      recipientInfo: trimObjectStrings(this.recipientInfo),
      deliveryInfo: deliveryInfoTrimmed,
    }
  },

  /** Форма в строковом формате */
  stringifiedForm() {
    return JSON.stringify(this.form)
  },

  isMoscowCity() {
    return this.config.city === MOSCOW_CITY_ID
  },

  /**
   * Используется для превью пвз и в модальном окне
   * Если название магазина отсутствует, то выводим дефолтное
   */
  storeName() {
    return (
      this.deliveryInfo.store?.name ||
      gaApp.i18n.t('giftCardsB2b.plasticNew.store.nameDefault')
    )
  },
})
