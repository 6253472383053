/**
 * Вычисляет новое время, учитывая offset и направление смещения.
 * @param {Object} options - объект с параметрами
 * @param {number} options.duration - общая длительность видео
 * @param {number} options.currentTime - текущее время видео
 * @param {number} options.offset - смещение, на которое нужно изменить время
 * @param {boolean} options.up - направление смещения (true - вверх, false - вниз)
 * @return {number} новое время
 */
export const getTimeOffset = ({ duration, currentTime, offset, up }) => {
  if (up) {
    const timeWithOffsetUp = currentTime + offset

    return timeWithOffsetUp > duration ? duration : timeWithOffsetUp
  }

  const timeWithOffsetDown = currentTime - offset

  return timeWithOffsetDown < 0 ? 0 : timeWithOffsetDown
}
