/**
 * @typedef {Number} Hour12 - час в 12ч формате (1 - 12)
 */

/**
 * @typedef {Number} Hour24 - стандартный час (0 - 23)
 */

/**
 * @typedef {'am'|'pm'} AMPM - период am/pm
 */

/**
 * Возвращает нормализованный час из 12ч формата и периода
 * @param { Hour12 } hour
 * @param { AMPM } ampm
 * @returns { Hour24 }
 */
export const getHour24FromHour12 = (hour, ampm) => {
  if (ampm === 'am') {
    if (hour === 12) return 0
    return hour
  }

  if (ampm === 'pm') {
    if (hour === 12) return 12
    return hour + 12
  }
}
