import ar from './ar.json'
import en from './en.json'
import ru from './ru.json'
import ruKZ from './ru-KZ.json'

export const locales = {
  'ru-RU': [ru],
  'ru-KZ': [ru, ruKZ],
  'ar-AE': ar,
  'ar-QA': ar,
  'ar-SA': ar,
  'en-AE': en,
  'en-QA': en,
  'en-SA': en,
  'ar-RU': ar,
  'en-RU': en,
}
