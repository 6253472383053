import { areArraysEqual } from './getters'
import { AROMAS_BY_PURPOSE, PURPOSES } from './state'

export const actions = {
  nextStep() {
    this.moveStep(1)
  },

  prevStep() {
    this.moveStep(-1)
  },

  moveStep(direction) {
    const currentIndex = this.steps.indexOf(this.currentStep)
    const nextIndex = currentIndex + direction

    if (nextIndex >= 0 && nextIndex < this.steps.length) {
      this.currentStep = this.steps[nextIndex]
    }
  },

  selectPurpose(purpose) {
    if (PURPOSES[purpose]) {
      this.selectedPurpose = purpose
    } else {
      throw new Error(
        `[puig] [error] недопустимое значение purpose: ${purpose}`,
      )
    }
  },

  selectAromas(aromas) {
    if (!this.selectedPurpose) {
      throw new Error(
        `[puig] [error] переменная selectedPurpose еще не определена`,
      )
    }

    const validAromas = AROMAS_BY_PURPOSE[this.selectedPurpose] || []
    if (
      validAromas.some((allowedAromas) => areArraysEqual(allowedAromas, aromas))
    ) {
      this.selectedAromas = aromas
    } else {
      throw new Error(`[puig] [error] недопустимое значение aromas: ${aromas}`)
    }
  },
}
