import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4ProductModel } from '../gtm-ga4-product.model/gtm-ga4-product.model'

export class GtmGa4Model extends GtmBaseModel {
  constructor(product) {
    if (!product) {
      throw new Error('product is required')
    }
    const {
      view,
      brand,
      name,
      amount,
      currency,
      id,
      breadcrumbs,
      attributesSelected,
      itemListName,
      isEyezonEnabled,
      searchTerm,
      reviewStatistics,
      itemListId,
      inStock,
      shortVideoCount,
      index,
      analyticData,
      hasCoupon,
      categoryId,
    } = product

    super({
      event: 'view_item',
      view,
      isEyezonEnabled,
      reviewStatistics,
      additional: analyticData?.additional,
    })

    this.search_term = searchTerm

    if (shortVideoCount) {
      this.short_video = shortVideoCount
    }

    const creativeName = analyticData?.creativeName
    const creativeSlot = analyticData?.creativeSlot
    const promotionName = analyticData?.promotionName

    if (creativeName) this.creative_name = creativeName
    if (creativeSlot) this.creative_slot = creativeSlot
    if (promotionName) this.promotion_name = promotionName

    if (hasCoupon) this.has_promocode = 1

    this.ecommerce = {
      currency,
      item_list_id: itemListId,
      item_list_name: itemListName,
      items: [
        new GtmGa4ProductModel({
          brand,
          name,
          amount,
          id,
          breadcrumbs,
          attributesSelected,
          itemListName,
          itemListId,
          inStock,
          currency,
          index,
          categoryId,
          analyticData,
          creativeName,
          creativeSlot,
          promotionName,
        }),
      ],
    }
  }
}
