export const offerListingMock = {
  data: {
    offers: [
      {
        id: '6627ca1e480cc6c822199d08',
        title: 'Adria: дополнительная скидка 15%',
        description: 'Adria: скидка 15%',
        url: '/offers/special-price-11-07',
        startDate: '2024-06-06',
        endDate: '2024-06-07',
        imageUrl: {
          s: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
          l: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
        },
        disclaimer: {
          legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
          erid: 'rgrgwg',
          label: 'black',
        },
        labels: [
          {
            id: 'online',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
          {
            id: 'offline',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
        ],
      },
      {
        id: '6627ca1e4801cc6c822199d08',
        title: 'Adria:скидка 15%',
        description: 'тональная основа The Healthy Foundation со скидкой −40%',
        url: '/offers/special-price-11-07',
        startDate: '2024-06-06',
        endDate: '2024-06-07',
        imageUrl: {
          s: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
          l: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
        },
        disclaimer: {
          legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
          erid: 'rgrgwg',
          label: 'black',
        },
        labels: [
          {
            id: 'online',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
          {
            id: 'offline',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
        ],
      },
      {
        id: '6627ca1e4802cc6c822199d',
        title: 'подарок от Vichy',
        description: 'тональная основа The Healthy Foundation со скидкой −40%',
        url: '/offers/special-price-11-07',
        startDate: '2024-06-06',
        endDate: '2024-06-07',
        imageUrl: {
          s: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
          l: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
        },
        disclaimer: {
          legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
          erid: 'rgrgwg',
          label: 'black',
        },
        labels: [
          {
            id: 'online',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
          {
            id: 'offline',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
        ],
      },
      {
        id: '6627ca1e4803cc6c822199d08',
        title: "Physician's Formula",
        description: 'тональная основа The Healthy Foundation со скидкой −40%',
        url: '/offers/special-price-11-07',
        startDate: '2024-06-06',
        endDate: '2024-06-07',
        imageUrl: {
          s: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
          l: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
        },
        disclaimer: {
          legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
          erid: 'rgrgwg',
          label: 'black',
        },
        labels: [
          {
            id: 'online',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
          {
            id: 'offline',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
        ],
      },
      {
        id: '6627ca1e4804cc6c822199d08',
        title: 'итоги розыгрыша подарков от La Roche-Posay',
        description: 'тональная основа The Healthy Foundation со скидкой −40%',
        url: '/offers/special-price-11-07',
        startDate: '2024-06-06',
        endDate: '2024-06-07',
        imageUrl: {
          s: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
          l: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
        },
        disclaimer: {
          legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
          erid: 'rgrgwg',
          label: 'black',
        },
        labels: [
          {
            id: 'online',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
          {
            id: 'offline',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
        ],
      },
      {
        id: '6627ca1e4805cc6c822199d08',
        title: 'итоги розыгрыша подарков от La Roche-Posay',
        description: 'тональная основа The Healthy Foundation со скидкой −40%',
        url: '/offers/special-price-11-07',
        startDate: '2024-06-06',
        endDate: '2024-06-07',
        imageUrl: {
          s: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
          l: {
            fullhd:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            tablet:
              'https://cdnf.goldapple.ru/fullhd/card/6666f8e1783246ae2617c3ca.jpg',
            mobile:
              'https://cdnf.goldapple.ru/mobile/card/667462591c7e2d34c1e1558d.jpg',
          },
        },
        disclaimer: {
          legalEntity: ['ООО Екатеринбург Яблоко ИНН 6670381056'],
          erid: 'rgrgwg',
          label: 'black',
        },
        labels: [
          {
            id: 'online',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
          {
            id: 'offline',
            group: 'standard',
            resourse: {
              resourceType: 'image',
              resourceUrl:
                'https://stage-pcdn.goldapple.ru/t/scopedlabel/5374616e646172645f6e6577/6c6162656c3634623933323061326537623936336335313264616163668db89228dc61a1a.png',
            },
          },
        ],
      },
    ],
    count: 20,
    pageNumber: 1,
  },
}
