export default (context) => {
  // пытаемся найти инфу про вебвью
  const isWebview =
    context.$gaApp.route.query.webview ||
    context.$gaApp.cookies.get('ga-webview') ||
    window.localStorage.getItem('ga-webview')

  // проверяем домены
  const isMainDomain = window.location.host.split('.')[0] === 'goldapple'
  const isStageDomain = window.location.host.split('.')[0] === 'stage'
  const allowedDomains = isMainDomain || isStageDomain

  /**
   * подключаем плагин если это:
   * 1. не webview
   * 2. не дев-режим
   * 3. допустимый домен
   */
  if (!isWebview && !context.$gaApp.config.get('dev') && allowedDomains) {
    try {
      // В зависимости от домена, строим строку
      const cid = (() => {
        // ru, kz, by, qa
        const tld = window.location.host.split('.').pop()

        /**
         * w-goldapple-ru-t
         * w-goldapple-by-t
         * w-goldapple-kz-t
         * w-goldapple-qa-t
         */
        if (isStageDomain) return `w-goldapple-${tld}-t`

        /**
         * w-goldapple-ru
         * w-goldapple-by
         * w-goldapple-kz
         * w-goldapple-qa
         */
        return `w-goldapple-${tld}`
      })()

      window.gib.init({
        cid,
        backUrl: '/front/api/event',
        gafUrl: '//ru.id.facct.ru/id.html',
      })

      // Устанавливаем данные пользователя в GUN
      const setUserIdentity = () => {
        const { data, isAuthorized } = context.$gaApp.stores.user.main

        const userId = data?.id ?? null
        const userSessionId = context.$gaApp.cookies.get('ga-session-id')
        const userAuthStatus = isAuthorized
          ? window.gib.IS_AUTHORIZED
          : window.gib.IS_GUEST

        window.gib.setIdentity(userId)
        window.gib.setSessionID(userSessionId)
        window.gib.setAuthStatus(userAuthStatus)
      }

      // Сбрасываем данные пользователя в GUN
      const resetUserIdentity = () => {
        const userId = null
        const userSessionId = context.$gaApp.cookies.get('ga-session-id')
        const userAuthStatus = window.gib.IS_GUEST

        window.gib.setIdentity(userId)
        window.gib.setSessionID(userSessionId)
        window.gib.setAuthStatus(userAuthStatus)
      }

      // Устанавливаем данные пользователя на этапе инита
      const unsubscribeInitial = context.$gaApp.eventBus.subscribe(
        'module/app/router/page-created',
        () => {
          setUserIdentity()
          unsubscribeInitial()
        },
      )

      // Подписываемся на события login/logout
      context.$gaApp.eventBus.subscribe('module/auth/login', setUserIdentity)
      context.$gaApp.eventBus.subscribe('module/auth/logout', resetUserIdentity)
    } catch (error) {
      console.log('Ошибка инициализации GUN')

      const errorApm = new Error(`Ошибка инициализации GUN`)
      errorApm.errorData = JSON.stringify(error)

      context.$apm.captureError(errorApm)
    }
  }
}
