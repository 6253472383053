export class PlasticRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getConfig() {
    const { url } = this.endpoints.getPlasticConfig()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async order(data) {
    const { url } = this.endpoints.plasticOrder()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получение конфигурации для КППК - новый флоу
   * @param {string} cityId идентификатор города
   */
  async getConfigV2(cityId) {
    const { url } = this.endpoints.getPlasticV2Config()

    const config = {
      params: { cityId },
    }

    try {
      const response = await this.gaApp.api.request().get(url, config)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получение информации о контрагенте
   * @param {Object} data - данные для поиска
   * @property {string} data.inn - ИНН контрагента
   * @returns {Promise<Object>}
   */
  async findByInn(data) {
    const { url } = this.endpoints.findByInn()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Создание заказа на КППК - новый флоу
   * @param {Object} data - данные заказа
   * @returns {Promise<Object>}
   */
  async orderV2(data) {
    const { url } = this.endpoints.plasticV2Order()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   *  Отправка загруженной доверенности после оформления заявки КППК - новый флоу
   * @param {Object} form - доверенность
   * @param {string} orderNumber - номер заявки
   * @returns {Promise<Object>}
   */
  async uploadFile(form, orderNumber) {
    const { url } = this.endpoints.plasticUploadFile()

    const config = {
      params: { orderNumber },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const response = await this.gaApp.api.request().post(url, form, config)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
