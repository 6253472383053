<template>
  <svg fill="none" viewBox="0 0 55 36">
    <path fill="currentColor" d="m3 18 23 14V4L3 18Z" />
    <path fill="currentColor" d="m29 18 23 15V3L29 18Z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-rewind-double',
}
</script>
