import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'
import { GtmGa4BannerBaseModel } from '../gtm-ga4-banner-base.model/gtm-ga4-banner-base.model'

export class GtmGa4BannerClickModel extends GtmBaseModel {
  constructor(data) {
    super({ event: 'select_promotion' })

    this.ecommerce = new GtmGa4BannerBaseModel(data)
  }
}
