import { getId } from '@ga/utils'

export class PlasticSetsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Установка базового дизайна карты в открытом наборе КППК
   * Пока по умолчанию - первый дизайн
   * @returns {object} - данные дизайна карты
   */
  getActiveCard() {
    const card = this.gaApp.stores.giftCardsB2b.plasticNew.config.cards[0]

    return card
  }

  /**
   * Создание нового набора КППК
   * @returns {object} новый набор
   * @property {null} cardsAmount - количество карт в наборе
   * @property {null} cardsValue - номинал карт в наборе
   * @property {object} card - дизайн карты
   * @property {string} id - идентификатор набора
   */
  createSet() {
    const setId = getId()
    const card = this.getActiveCard()

    return {
      cardsAmount: null,
      cardsValue: null,
      card,
      id: setId,
    }
  }

  /**
   * Добавление нового набора в общий список наборов
   */
  addSet() {
    if (
      this.gaApp.stores.giftCardsB2b.plasticNew.setsCount >=
      this.gaApp.stores.giftCardsB2b.plasticNew.sets.rules.maxSets
    )
      return

    const newSet = this.createSet()
    this.gaApp.stores.giftCardsB2b.plasticNew.sets.items.push(newSet)
  }

  /**
   * Удаление набора из списка наборов по index
   * @param {number} index - индекс набора
   */
  removeSet(index) {
    if (this.gaApp.stores.giftCardsB2b.plasticNew.setsCount <= 1) return

    this.gaApp.stores.giftCardsB2b.plasticNew.sets.items.splice(index, 1)
  }

  /**
   * Установка дизайна карты в наборе КППК
   * @param {string} cardId - идентификатор карты
   * @param {string} setId - идентификатор набора
   */
  setCard(cardId, setId) {
    const set = this.gaApp.stores.giftCardsB2b.plasticNew.sets?.items.find(
      (set) => set.id === setId,
    )
    const card = this.gaApp.stores.giftCardsB2b.plasticNew.config.cards?.find(
      (card) => card.itemId === cardId,
    )

    if (!set || !card) return

    set.card = card
  }

  /** Сброс данных наборов в заказе */
  reset() {
    const newEmptySet = this.createSet()
    this.gaApp.stores.giftCardsB2b.plasticNew.sets.items = [newEmptySet]
  }
}
