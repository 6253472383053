export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async updateUserLanguageV1(body) {
    try {
      const { data } =
        await this.gaApp.repositories.user.main.updateUserLanguage(body)

      return data
    } catch (error) {
      return null
    }
  }

  async updateUserLanguageV2() {
    try {
      const response =
        await this.gaApp.repositories.user.main.updateUserLanguageV2()

      return response
    } catch (error) {
      return null
    }
  }

  /**
   * Обновление языка пользователя (для коммуникаций)
   */
  async updateUserLanguage(body) {
    if (this.gaApp.features.get('updateUserLanguagePlaid')) {
      return await this.updateUserLanguageV2()
    } else {
      return await this.updateUserLanguageV1(body)
    }
  }

  async getProofAge() {
    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('adult')

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.user.main.getData({
          sections: ['adult_goods'],
        }))

      const { adultGoods, rawAdultGoods } = data

      if (!adultGoods || !rawAdultGoods) {
        return
      }

      this.gaApp.services.cache.main.saveAdult(rawAdultGoods)

      this.gaApp.stores.user.main.isAdult = adultGoods.isAdult
    } catch (error) {}
  }

  /**
   * Получение списка сохраненных адресов, выбранного адреса
   * todo: перенести в модуль location
   */
  async getAddress() {
    const geoAddressesEnabled = this.gaApp.features.get(
      'addressSelectAddressToPlaid',
    )

    return geoAddressesEnabled
      ? await this.getAddressV2()
      : await this.getAddressV1()
  }

  /**
   * Получение списка адресов и выбранного адреса
   * мага, дергается если аторизован и включен адресный блок
   */
  async getAddressV1() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userAddress')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getAddress())

      const { addresses: addressesAsLocations, selectedAddress } = data

      if (addressesAsLocations) {
        this.gaApp.services.user.main.setUserAddresses(addressesAsLocations)
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
        this.gaApp.services.location.cache.saveMigrateSelectedAddress(
          selectedAddress,
        )
      }
    } catch (error) {}
  }

  /**
   * Получение списка адресов и выбранного адреса
   * плед, дергается если аторизован
   */
  async getAddressV2() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userAddress')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getAddressV2())

      const { addresses: addressesAsLocations, selectedAddress } = data

      if (addressesAsLocations) {
        this.gaApp.services.user.main.setUserAddresses(addressesAsLocations)
      }

      if (selectedAddress) {
        this.gaApp.services.location.main.setLocation(selectedAddress)
      }

      return data
    } catch (error) {}
  }

  async setProofAge() {
    try {
      const { data } = await this.gaApp.repositories.user.main.setProofAge()

      const { rawAdultGoods } = data

      if (!rawAdultGoods) {
        return
      }

      this.gaApp.services.cache.main.saveAdult(rawAdultGoods)
    } catch (error) {}
  }

  async fetchUserInfoShort() {
    const { data } = await this.gaApp.repositories.user.main.getInfoShort()
    this.gaApp.services.user.main.setUser({ data })

    return { data }
  }

  async fetchUserInfoFull() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userInfoFull')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getInfoFull())

      this.gaApp.services.user.main.setUser({ data })

      return { data }
    } catch (error) {}
  }

  async fetchUserInfoFullWithToken() {
    try {
      const { data } = await this.gaApp.repositories.user.main.getInfoFull()

      return { data }
    } catch (error) {}
  }

  async fetchUserMobileInfoFullWithToken() {
    try {
      const { data } =
        await this.gaApp.repositories.user.main.getMobileInfoFull()

      return { data }
    } catch (error) {}
  }

  async checkPhoneNumber({ phone, country }) {
    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    const params = { phone, country }

    if (captchaToken) {
      params.captchaToken = captchaToken
    }

    try {
      const { data } =
        await this.gaApp.repositories.user.main.checkPhoneNumber(params)

      return data
    } catch (error) {
      return false
    }
  }
}
