import { DtoConfigService } from './dto/config'
import { DtoOrderService } from './dto/order'
import { AboutService } from './about.service'
import { ApiService } from './api.service'
import { BalanceService } from './balance.service'
import { DigitalService } from './digital.service'
import { DigitalAiDesignService } from './digital-ai-design.service'
import { DigitalDesignTypesService } from './digital-design-types.service'
import { DigitalReceiveCardService } from './digital-receive-card.service'
import { DigitalReceiveReturnCardService } from './digital-receive-return-card.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { PaymentService } from './payment.service'
import { PaymentCardService } from './payment-card.service'
import { PaymentMagnatiService } from './payment-magnati.service'
import { PaymentMasterCardService } from './payment-mastercard.service'
import { PaymentSbpService } from './payment-sbp.service'
import { PaymentStatusService } from './payment-status.service'
import { ReactionsService } from './reactions.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  digital: new DigitalService(gaApp),
  dto: {
    config: new DtoConfigService(gaApp),
    order: new DtoOrderService(gaApp),
  },
  reactions: new ReactionsService(gaApp),
  balance: new BalanceService(gaApp),
  payment: new PaymentService(gaApp),
  paymentCard: new PaymentCardService(gaApp),
  paymentMasterCard: new PaymentMasterCardService(gaApp),
  paymentMagnati: new PaymentMagnatiService(gaApp),
  paymentSbp: new PaymentSbpService(gaApp),
  paymentStatus: new PaymentStatusService(gaApp),
  digitalAiDesign: new DigitalAiDesignService(gaApp),
  about: new AboutService(gaApp),
  digitalDesignTypes: new DigitalDesignTypesService(gaApp),
  digitalReceive: new DigitalReceiveCardService(gaApp),
  digitalReceiveReturn: new DigitalReceiveReturnCardService(gaApp),
})
