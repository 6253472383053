export class AdvBannersRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp
    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getBanners(data) {
    try {
      const { url } = this.endpoints.fetchAdvBanners()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addViewed(data) {
    try {
      const { url } = this.endpoints.fetchAdvBannerViewed()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addViewedWebview(data) {
    try {
      const { url } = this.endpoints.fetchAdvBannerViewedWebview()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addClicked(data) {
    try {
      const { url } = this.endpoints.fetchAdvBannerClicked()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async addClickedWebview(data) {
    try {
      const { url } = this.endpoints.fetchAdvBannerClickedWebview()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getBannerPreview({ campaignId, bannerId }) {
    try {
      const { url } = this.endpoints.fetchAdvBannerPreview({
        campaignId,
        bannerId,
      })
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
