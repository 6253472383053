import { pdp as ar } from './ar.json'
import { pdp as en } from './en.json'
import { pdp as ru } from './ru.json'
import { pdp as ruBYDelivery } from './ru-BY.json'
import { pdp as ruKZDelivery } from './ru-KZ.json'
import { pdp as ruRUDelivery } from './ru-RU.json'
import { pdp as ruRUOptions } from './ru-RU.options.json'

export const locales = {
  'ru-RU': [ru, ruRUOptions, ruRUDelivery],
  'ru-BY': [ru, ruBYDelivery],
  'ru-KZ': [ru, ruKZDelivery],
  'ar-AE': ar,
  'ar-QA': ar,
  'ar-SA': ar,
  'en-AE': en,
  'en-QA': en,
  'en-SA': en,
  'ar-RU': ar,
  'en-RU': en,
}
