export class AdvBannersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async loadBanners(data) {
    if (this.gaApp.isServer) {
      return
    }

    return await this.gaApp.services.articles.apiAdvBanners.getBanners(data)
  }

  async loadBannerPreview({ campaignId, bannerId }) {
    if (this.gaApp.isServer) {
      return
    }

    const response =
      await this.gaApp.services.articles.apiAdvBanners.getBannerPreview({
        campaignId,
        bannerId,
      })
    this.setPreviewBannerDataToStore(response)
  }

  async addViewed({ campaignId, bannerId }) {
    const captchaToken = await this.getCaptchaToken()

    await this.gaApp.services.articles.apiAdvBanners.addViewed({
      captchaToken,
      bannerId,
      campaignId,
    })
  }

  async addClicked({ campaignId, bannerId }) {
    const captchaToken = await this.getCaptchaToken()

    await this.gaApp.services.articles.apiAdvBanners.addClicked({
      captchaToken,
      bannerId,
      campaignId,
    })
  }

  async getCaptchaToken() {
    return !this.gaApp.isWebview
      ? await this.gaApp.services.captcha.main.generateToken()
      : null
  }

  setPreviewBannerDataToStore(response) {
    this.gaApp.stores.articles.banner.banner.type = response.type
    this.gaApp.stores.articles.banner.banner.bannerId = response.bannerId
    this.gaApp.stores.articles.banner.banner.campaignId = response.campaignId
    this.gaApp.stores.articles.banner.banner.title = response.title
    this.gaApp.stores.articles.banner.banner.subtitle = response.subtitle
    this.gaApp.stores.articles.banner.banner.link = response.link
    this.gaApp.stores.articles.banner.banner.tags = response.tags
    this.gaApp.stores.articles.banner.banner.imageUrl = response.imageUrl
    this.gaApp.stores.articles.banner.banner.disclaimer = response.disclaimer
  }

  reset() {
    this.gaApp.stores.articles.banners.$reset()
  }
}
