import { TYPE } from '../../constants'
import { GtmPuigOnPutItemInFirstStepInBoxModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class PuigOnPutItemInFirstStepInBoxBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([
      [TYPE.GTM_4, GtmPuigOnPutItemInFirstStepInBoxModel],
    ])
    super(models, data)
  }
}
