/*
 * TODO:
 *  - !!! РЕФАКТОРИНГ CSS !!!
 *  - подружить &__nav и ga-modal в плане анимации появления и отображения overlay
 *  - попробовать переписать навигацию на модалку
 *  - сделать открытие табов при видимом баннере мобильного приложения с задержкой
 */

import { useContext } from '@nuxtjs/composition-api'

import { computed, ref } from 'vue'

import { scrollLockFillGap, scrollLockScrollable } from '@ga/shared-directives'

import {
  GaIconAdditionalCross,
  GaIconAdditionalHeartBig,
  GaIconLocationGeoPin,
  GaIconPrimaryBurger,
  GaIconPrimaryLogo,
  GaIconPrimarySearch,
  GaIconPrimaryUser,
} from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaIcon } from '@ga/ui-components/icon'
import { GaVisuallyHidden } from '@ga/ui-components/visually-hidden'

import { TYPE } from '~/modules/location'

import { GaCartIcon } from '../../atoms/cart-icon'
import GaNavBannersTablet from '../../molecules/nav-banners-tablet'
import { GaHeaderNavFull } from '../../organisms/nav-full'
import { GaHeaderNavMinimized } from '../../organisms/nav-minimized'

import {
  useBanners,
  useColorSet,
  useExtended,
  useLocation,
  useMedia,
  useModals,
  useMods,
  useNav,
  useOnRouteChange,
  useOverlaid,
  useParams,
  useRender,
  useState,
  useTabs,
} from './scripts/use'

const svg = {
  cross: GaIconAdditionalCross,
  nav: GaIconPrimaryBurger,
  heart: GaIconAdditionalHeartBig,
  location: GaIconLocationGeoPin,
  logo: GaIconPrimaryLogo,
  search: GaIconPrimarySearch,
  user: GaIconPrimaryUser,
}

// @vue/component
export default {
  name: 'ga-header',

  components: {
    GaCartIcon,
    GaHeaderNavFull,
    GaHeaderNavMinimized,

    GaAction,
    GaIcon,
    GaVisuallyHidden,

    GaNavBannersTablet,
  },

  directives: {
    scrollLockFillGap,
    scrollLockScrollable,
  },

  setup() {
    const rootRef = ref()
    const { $gaApp, localePath } = useContext()

    const { desktop, tablet } = useMedia()

    const {
      mounted,
      extended,
      collapsed,
      minimized,
      full,
      authorized,
      hovered,
    } = useParams(desktop, rootRef)

    const {
      searchModalOpened,
      langSwitcherModalOpened,
      setModalsByTab,
      openCartModal,
      searchModalTransitionDelay,
      updateSearchModalTransitionDelay,
    } = useModals(full, collapsed)

    const { updateHeaderExtendedState } = useExtended(
      extended,
      searchModalOpened,
    )

    const {
      tabs,
      selectedTab,
      setSelectedTab,
      clearSelectedTab,
      onTabClick,
      onAuthClick,
    } = useTabs(
      setModalsByTab,
      openCartModal,
      searchModalOpened,
      langSwitcherModalOpened,
      updateHeaderExtendedState,
      updateSearchModalTransitionDelay,
      full,
      mounted,
      minimized,
      authorized,
      svg,
    )

    const { navExtended, navActive, navHeightStyle, navWasActive } = useNav(
      selectedTab,
      setSelectedTab,
      clearSelectedTab,
      minimized,
    )

    const { overlaid } = useOverlaid(
      minimized,
      selectedTab,
      navActive,
      navExtended,
    )

    const { mods } = useMods(mounted, overlaid, collapsed)

    const { updateState } = useState(overlaid, minimized, hovered)

    const {
      locationVisuallyHidden,
      locationMods,
      isLocationConfirmAddressOpen,
      onLocationClick,
    } = useLocation()

    const { serverColorSetCustomProperties } = useColorSet()

    useOnRouteChange(minimized, clearSelectedTab, updateState)

    const { bannersTablet, bannersTabletActive } = useBanners(navActive)

    const { renderFull, renderMinimized } = useRender(minimized)

    const showLangSwitcher = computed(
      () => $gaApp.services.app.langSwitcher.hasAvailableLanguages,
    )

    const storeSwitcherFeatureOn = $gaApp.features.get('storeSwitcher')

    const showStoreSwitcher = computed(
      () =>
        $gaApp.services.app.langSwitcher.hasAvailableLanguages &&
        desktop.value &&
        storeSwitcherFeatureOn,
    )

    const getTabHref = (tab) =>
      ['main'].includes(tab.type) ? localePath(tab.href) : tab.href

    return {
      rootRef,

      svg,

      mods,
      tablet,
      minimized,
      full,

      searchModalTransitionDelay,

      tabs,
      onTabClick,
      onAuthClick,
      getTabHref,

      navActive,
      navHeightStyle,
      navWasActive,

      clearSelectedTab,

      locationVisuallyHidden,
      locationMods,
      onLocationClick,
      isLocationConfirmAddressOpen,

      serverColorSetCustomProperties,

      bannersTablet,
      bannersTabletActive,

      renderFull,
      renderMinimized,

      showLangSwitcher,
      showStoreSwitcher,

      deliveryAddressType: TYPE.EXTENDED,
    }
  },
}
