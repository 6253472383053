import { TYPE } from '../../constants'
import { GtmGa4BannerClickModel } from '../../models'
import { BaseBuilder } from '../index'

export class BannerClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4BannerClickModel]])
    super(models, data)
  }
}
