export const getStateInitial = () => ({
  addressIdTemp: null,
  searchQueryTemp: null,

  editModalOpened: false,
  selectModalOpened: false,
  screenType: '',
  openingReason: null,

  initialGeoPending: false,
  initialGeoCancel: false,
})

export const state = () => {
  return getStateInitial()
}
