import { getId, trimObjectStrings } from '@ga/utils'

import { MOSCOW_PICKUP_DATA } from '../constants'

export class PlasticService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Формирование данных заказа для КППК
   */
  get order() {
    const formData = new FormData()
    const { details, counterparty, contacts } =
      this.gaApp.stores.giftCardsB2b.plastic

    const dto = {
      ...trimObjectStrings(details),
      ...trimObjectStrings(counterparty),
      ...trimObjectStrings(contacts),
      deliveryDate: details.deliveryDate.toISOString(),
      qty: parseInt(details.qty),
      cardType: 'real',
    }

    Object.entries(dto).forEach(([name, value]) => {
      if (name === 'requisites') {
        formData.append(name, value, value.name)

        return undefined
      }

      formData.append(name, value)
    })

    return formData
  }

  /** Ниже всё для КППК (новый флоу) */

  /** Текущий идентификатор заказа */
  get requestId() {
    return (
      this.gaApp.stores.giftCardsB2b.plasticNew.requestIdCache[
        this.gaApp.stores.giftCardsB2b.plasticNew.stringifiedForm
      ] || this.generateRequestId()
    )
  }

  /** Данные заказа */
  get orderV2() {
    return {
      ...this.gaApp.stores.giftCardsB2b.plasticNew.form,
      requestId: this.requestId,
    }
  }

  /**
   * Генерация идентификатора заказа
   * В requestIdCache хранится связка requestId:состояние формы.
   * При создании заказа (получаем requestId) идет поиск (requestIdCache) по состоянию формы.
   * Если уже такое состояние заказа было, то возвращается ранее сгенерированный requestId.
   * Если при создании заказа такого состояния еще не было, то создается новый requestId и связка.
   *
   * Таким образом, если пользователь уже отправлял заказ с одинаковым содержимым, мы это узнаем по requestId.
   * @returns {string}
   */
  generateRequestId() {
    const newId = getId()

    this.gaApp.stores.giftCardsB2b.plasticNew.requestIdCache = {
      ...this.gaApp.stores.giftCardsB2b.plasticNew.requestIdCache,
      [this.gaApp.stores.giftCardsB2b.plasticNew.stringifiedForm]: newId,
    }

    return newId
  }

  /** Установка статуса загрузки */
  setPlasticV2ConfigLoading(value) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.isLoading = value
  }

  async getPlasticV2Config(cityId) {
    try {
      this.setPlasticV2ConfigLoading(true)

      const data =
        await this.gaApp.services.giftCardsB2b.api.getPlasticV2Config(cityId)

      if (!data || !data?.cards?.length) {
        throw new Error('Could not receive plastic cards configuration data')
      }

      this.setPlasticV2Data(data)

      return data
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    } finally {
      /**
       * Для избежания анимации единственного открытого набора
       * (await nextTick не решает проблему)
       */
      setTimeout(() => {
        this.setPlasticV2ConfigLoading(false)
      }, 0)
    }
  }

  /** Установка данных */
  setPlasticV2Data(data) {
    const { cities, cards, sets, counterparty, contacts, document, stores } =
      data

    /** Установка городов, доступных для заказа КППК */
    this.gaApp.services.giftCardsB2b.location.setCities(cities)
    /** Установка города по умолчанию */
    this.gaApp.services.giftCardsB2b.location.setDefaultCity()
    /** Установка возможных дизайнов КППК */
    this.gaApp.stores.giftCardsB2b.plasticNew.config.cards = cards
    /** Установка правил валидации полей заявки */
    this.gaApp.stores.giftCardsB2b.plasticNew.sets.rules = sets
    /** Установка правил валидации полей контрагента */
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.rules = counterparty
    /** Установка правил валидации полей контактов */
    this.gaApp.stores.giftCardsB2b.plasticNew.contacts.rules = contacts
    /** Установка ссылки на документ */
    this.gaApp.stores.giftCardsB2b.plasticNew.contacts.document = document

    /** При отсутствии наборов, добавляем новый с нулевыми значениями */
    if (!this.gaApp.stores.giftCardsB2b.plasticNew.sets.items.length) {
      this.gaApp.services.giftCardsB2b.plasticSets.addSet()
    }

    /** Установка опций доставки */
    this.gaApp.services.giftCardsB2b.plasticDelivery.setDeliveryOptions()

    /** Установка данных ПВЗ города */
    /** Хардкод данных для Москвы - https://jira01.goldapple.ru/browse/B2BGC-82 */
    if (this.gaApp.stores.giftCardsB2b.plasticNew.isMoscowCity) {
      this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.store =
        MOSCOW_PICKUP_DATA
    } else {
      this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.store = stores?.[0]
    }
  }

  /** Отправка данных формы */
  async submitPlasticNewOrder() {
    this.gaApp.stores.giftCardsB2b.main.isLoading = true
    const orderNumber =
      await this.gaApp.services.giftCardsB2b.api.submitPlasticNewOrder()

    /** Очистка данных стора после успешного заказа */
    if (orderNumber) {
      this.resetPlasticNewFormData()
    }

    this.gaApp.stores.giftCardsB2b.main.isLoading = false
  }

  async uploadFile(orderNumber) {
    const file =
      this.gaApp.stores.giftCardsB2b.plasticNew.contacts.form.document

    if (!orderNumber || !file) return

    const form = new FormData()
    form.append('file', file, file.name)

    await this.gaApp.repositories.giftCardsB2b.plastic.uploadFile(
      form,
      orderNumber,
    )
  }

  resetPlasticNewFormData() {
    /** Сброс наборов */
    this.gaApp.services.giftCardsB2b.plasticSets.reset()
    /** Сброс данных доставки */
    this.gaApp.services.giftCardsB2b.plasticDelivery.resetAddressData()
    /** Сброс данных контрагента */
    this.gaApp.services.giftCardsB2b.plasticCounterparty.clearInfo()
    /** Сброс данных формы */
    this.gaApp.stores.giftCardsB2b.plasticNew.resetFormData()
    /** Сброс шага */
    this.gaApp.services.giftCardsB2b.steps.setCurrent(0)
  }
}
