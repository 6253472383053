<template>
  <svg fill="none" stroke="none" viewBox="0 0 14 51">
    <path stroke="currentColor" d="M7 50V0" />
    <path stroke="currentColor" d="m13 44-6 6-6-6" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-info-puig-arrow-down',
}
</script>
