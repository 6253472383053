export class OrderService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  toggleQrCodeModal() {
    this.gaApp.stores.customer.main.qrCodeModal.active =
      !this.gaApp.stores.customer.main.qrCodeModal.active
  }

  closeQrCodeModal() {
    this.gaApp.stores.customer.main.qrCodeModal.active = false
  }

  setQrCodeModalValue(code) {
    this.gaApp.stores.customer.main.qrCodeModal.code = code
  }

  setQrCodeType(type) {
    this.gaApp.stores.customer.main.qrCodeModal.qrCodeType = type
  }

  togglePickupPointModal() {
    this.gaApp.stores.customer.main.pickupPointModal.active =
      !this.gaApp.stores.customer.main.pickupPointModal.active
  }

  closeQrPickupPointModal() {
    this.gaApp.stores.customer.main.pickupPointModal.active = false
  }

  setPickupPointModalValue(data) {
    this.gaApp.stores.customer.main.pickupPointModal.data = data
  }

  reorder(id) {
    this.gaApp.services.cart.api.reorder(id)
  }

  toggleOnDemandModal() {
    this.gaApp.stores.customer.main.onDemandModal.active =
      !this.gaApp.stores.customer.main.onDemandModal.active
  }

  closeOnDemandModal() {
    this.gaApp.stores.customer.main.onDemandModal.active = false
  }

  // Переключение состояния модального окна изменения даты доставки
  toggleChangeDeliveryModal(value) {
    this.gaApp.stores.customer.main.changeDeliveryModal.active = value
  }

  // Сохранение состояния модального окна изменения даты доставки
  setChangeDeliveryModalData(data) {
    this.gaApp.stores.customer.main.changeDeliveryModal.data = data
  }

  setAnchor(value) {
    this.gaApp.stores.customer.order.anchor = value
  }

  clearAnchor() {
    this.gaApp.stores.customer.order.anchor = ''
  }

  /**
   * Нужен для доскролла до заказа по его id
   * @param {string} orderId id заказа
   * @param {object} scrollOptions объект опций для ф-ции scrollTo
   */
  scrollToOrderById(orderId, scrollOptions = {}) {
    const element =
      this.gaApp.services.app.scrollNavigation.findElement(orderId)

    if (element) {
      this.gaApp.libs.scrollTo(element, scrollOptions)
    }
  }

  setChangeDeliveryType(value) {
    this.gaApp.stores.customer.main.changeDeliveryModal.deliveryType = value
  }
}
