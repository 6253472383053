import { defineStore, getActivePinia } from 'pinia'

import { actions } from './actions'
import { getters } from './getters'
import { state } from './state'

export const puigStore = () => {
  const init = defineStore('puigStore', {
    state,
    getters,
    actions,
  })

  return init(getActivePinia())
}
