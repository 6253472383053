import { DATE_FORMAT, GENDER } from '../../constants'

export const getters = (gaApp) => ({
  genderOptions() {
    return [
      {
        id: 1,
        label: gaApp.i18n.t(`customer.edit.personal.gender.man`),
        value: GENDER.male.value,
        isChecked: this.form.gender === GENDER.male.value,
      },
      {
        id: 2,
        label: gaApp.i18n.t(`customer.edit.personal.gender.woman`),
        value: GENDER.female.value,
        isChecked: this.form.gender === GENDER.female.value,
      },
    ]
  },

  birthdayMask() {
    return DATE_FORMAT[gaApp.i18n.locale.code]
  },

  defaultFormattedBirthday() {
    return gaApp.libs
      .dateWrapper(this.form.birthday, this.birthdayMask)
      .utc(true)
      .format(DATE_FORMAT.default)
  },

  normalizedForm() {
    return {
      ...this.form,
      birthday: this.defaultFormattedBirthday,
      phone: gaApp.services.app.phone.getSanitizedPhone(this.form.phone),
    }
  },

  /**
   * Получаем массив строк обязательных полей формы
   */
  getRequiredFieldsLiterals() {
    return this.fields.reduce((result, field) => {
      if (field.isRequired && field.name) {
        result.push(field.name)
      }
      return result
    }, [])
  },
})
