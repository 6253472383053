import {
  CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY,
  COUNTRY_GROUP,
} from '../constant/index'

export class LangSwitcherService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.setSubscriptions()
  }

  /**
   * Возвращает данные о текущем сторе
   *
   * @returns {object}
   */
  get currentStore() {
    return this.gaApp.services.app.main.currentStoreContent
  }

  /**
   * Возвращает массив возможных сторов
   *
   * @returns {array}
   */
  get allStores() {
    return this.gaApp.stores.app.common.convertedStores
  }

  /**
   * Возвращает массив возможных языков теущего стора, кроме текущего
   *
   * @returns {array}
   */
  get availableLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages.filter(
      (lang) => lang.code !== this.gaApp.i18n.locale.code,
    )
  }

  /**
   * Возвращает массив всех языков текущего стора
   *
   * @returns {array}
   */
  get allLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages
  }

  /**
   * Возвращает данные текущего языка
   *
   * @returns {object}
   */
  get currentLanguage() {
    return this.gaApp.stores.app.common.convertedLanguages.find(
      (lang) => lang.code === this.gaApp.i18n.locale.code,
    )
  }

  /**
   * Проверяет, есть ли возможные языки, кроме текущего (у текущего стора)
   *
   * @returns {boolean}
   */
  get hasAvailableLanguages() {
    return !!this.availableLanguages.length
  }

  /**
   * Проверяет, является ли переданный язык текущим
   *
   * @param {string} languageCode - код языка
   * @returns {boolean}
   */
  isCurrentLanguage(languageCode) {
    return languageCode === this.currentLanguage.code
  }

  /**
   * Проверяет, является ли переданный язык дефолтным для переданного стора
   *
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   * @returns {boolean}
   */
  isStoreDefaultLanguage(languageCode, store) {
    return languageCode === store.languageDefaultCode
  }

  /**
   * Проверяет, равен ли код переданного стора коду текущего стора
   *
   * @param {string} storeCode - код стора (страны)
   * @returns {boolean}
   */
  isCurrentStore(storeCode) {
    return storeCode === this.currentStore.code
  }

  /**
   * Возвращает код языка в массиве languages из api, соответствующий переданному коду языка в i18n
   *
   * @param {string} code - код языка в i18n
   * @returns {string} - соответствующий код языка из api
   */
  getApiLanguageCode(code) {
    return this.gaApp.stores.app.common.initialLanguagesCodeMap[code] || code
  }

  /**
   * Записывает в куки переданную локаль, публикует событие о смене локали
   *
   * @param {string} code - код языка
   */
  switchLocale(code) {
    this.gaApp.i18n.setLocaleCookie(code)
    this.gaApp.eventBus.publish('module/app/lang-switcher/locale-changed', code)
  }

  /**
   * Открывает модалку смены языка
   */
  openModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = true
  }

  /**
   * Закрывает модалку смены языка
   */
  closeModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = false
  }

  /**
   * Открывает модалку смены страны
   *
   * @param {object} [props={}] - дополнительные пропсы для модалки, например { position: 'right' }
   */
  openCountryModal(props = {}) {
    this.gaApp.stores.app.langSwitcher.countryModal.props = props
    this.gaApp.stores.app.langSwitcher.countryModal.opened = true
  }

  /**
   * Закрывает модалку смены страны
   */
  closeCountryModal() {
    this.gaApp.stores.app.langSwitcher.countryModal.opened = false
  }

  /**
   * Открывает модалку подтверждения смены страны
   *
   * @param {object} [props={}] - дополнительные пропсы для модалки, например { position: 'right' }
   */
  openConfirmModal(props = {}) {
    this.gaApp.stores.app.langSwitcher.confirmModal.props = props
    this.gaApp.stores.app.langSwitcher.confirmModal.opened = true
  }

  /**
   * Закрывает модалку подтверждения смены страны
   */
  closeConfirmModal() {
    this.gaApp.stores.app.langSwitcher.confirmModal.opened = false
  }

  /**
   * Показывает дропдаун выбора страны и языка
   */
  showDropdown() {
    this.gaApp.stores.app.langSwitcher.dropdown.opened = true
  }

  /**
   * Скрывает дропдаун выбора страны и языка
   */
  hideDropdown() {
    this.gaApp.stores.app.langSwitcher.dropdown.opened = false
  }

  /**
   * Обогащает урл домена переданного стора дополнительным query параметром language при условии:
   * - наличия в аргументах languageCode, либо
   * - наличия у переданного стора дефолтного языка
   *
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   * @returns {string} - урл домена стора
   */
  getEnrichedStoreDomain({ store, languageCode }) {
    const url = new URL(store.storeDomain)
    const selectedLanguage = languageCode ?? this.gaApp.i18n.locale.code
    selectedLanguage && url.searchParams.append('language', selectedLanguage)

    return url.href
  }

  /**
   * Записывает в стору обогащенный query параметром language домен переданного в аргументах стора
   *
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   */
  setSelectedStoreDomain({ store, languageCode }) {
    this.gaApp.stores.app.langSwitcher.selectedStoreDomain = store?.storeDomain
      ? this.getEnrichedStoreDomain({ store, languageCode })
      : '/'
  }

  /**
   * Записывает в стору в качестве выбранных:
   * - переданный в аргументах код стора (страны)
   * - соответствующий ему домен
   *
   * @param {string} code - код стора (страны)
   */
  setSelectedCountry(code) {
    this.gaApp.stores.app.langSwitcher.selectedCountry = code
    const selectedStore = this.gaApp.services.app.main.getStoreContent(code)
    this.setSelectedStoreDomain({ store: selectedStore })
  }

  /**
   * Редиректит пользователя на ранее выбранный им домен
   */
  redirectToSelectedStore() {
    window.location.href =
      this.gaApp.stores.app.langSwitcher.selectedStoreDomain
  }

  /**
   * Устанавливает куку подтверждения пользователем смены страны сроком на 1 год
   */
  setCountryConfirmCookie() {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    this.gaApp.cookies.set(CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY, true, {
      expires: expiresDate,
      path: '/',
      sameSite: 'lax',
    })
  }

  /**
   * Убирает куку подтверждения пользователем смены страны
   */
  removeCountryConfirmCookie() {
    this.gaApp.cookies.remove(CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY)
  }

  /**
   * Устаналивает подписки:
   * - на событие логаута
   */
  setSubscriptions() {
    this.gaApp.eventBus.subscribe('module/auth/logout', () => {
      this.removeCountryConfirmCookie()
    })
  }

  /**
   * Проверяет необходимость показа модалки подтверждения смены страны.
   * Должна быть показана, если
   * - пользователь ранее НЕ подтверждал смену страны в текущем сторе
   * - текущая страна находится в группе СНГ
   *
   * @returns {boolean}
   */
  needConfirmation() {
    const countryConfirmCookie = this.gaApp.cookies.get(
      CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY,
    )

    const { code } = this.currentStore

    return !countryConfirmCookie || COUNTRY_GROUP.CIS.includes(code)
  }

  /**
   * Проверяет необходимость показа модалки подтверждения смены страны
   * - если true, открывает модалку
   * - если false, редиректит пользователя на ранее выбранный им домен
   *
   * @param {object} [props={}] - дополнительные пропсы для модалки, например { position: 'right' }
   */
  confirmOrRedirectToSelected(modalProps = {}) {
    if (this.needConfirmation()) {
      this.openConfirmModal(modalProps)
    } else {
      this.redirectToSelectedStore()
    }
  }
}
