export const BLOCK_TYPES = {
  HEADER: 'header',
  IMAGE: 'image',
  QUOTE: 'quote',
  PARAGRAPH: 'paragraph',
  LIST: 'list',
  CAROUSEL: 'carousel',
  BUTTON: 'button',
  PRODUCT_CARD: 'product_card',
  PRODUCT_SLIDER: 'product_slider',
  PRODUCT_LISTING: 'product_listing',
  EMBED: 'embed',
  BANNER: 'banner',
}

export const INDENTS_HIDE = {
  TOP: 'top',
  BOTTOM: 'bottom',
  BOTH: 'both',
  NO: 'no',
}
