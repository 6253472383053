export const state = () => {
  return {
    banner: {
      type: '',
      bannerId: '',
      campaignId: '',
      title: '',
      subtitle: '',
      link: '',
      tags: [],
      imageUrl: {},
      disclaimer: {},
    },
  }
}
