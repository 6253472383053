import { EVENT_CATEGORY_ADVENT2024 } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmAdvent2024OnCellProductClickModel extends GtmBaseModel {
  constructor({ itemSku, cellNumber }) {
    super({ eventCategory: EVENT_CATEGORY_ADVENT2024 })

    this.action_type = 'click_cell'
    this.item_sku = itemSku
    this.cell_number = cellNumber
  }
}
