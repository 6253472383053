<template>
  <svg fill="none" viewBox="0 0 55 36">
    <path fill="currentColor" d="M52 18 29 32V4l23 14Z" />
    <path fill="currentColor" d="M26 18 3 33V3l23 15Z" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-video-player-forward-double',
}
</script>
