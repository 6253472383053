export class CardsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setLoyalty(data) {
    this.gaApp.stores.customer.cards.loyalty = data
  }

  setLoyaltyBanner(data) {
    this.gaApp.stores.customer.cards.loyaltyBanner = data
  }

  toggleLoyaltyInfoModal(value) {
    this.gaApp.stores.customer.cards.isLoyaltyInfoModalOpened = value
  }

  toggleLoyaltyDetailsModal(value) {
    this.gaApp.stores.customer.cards.isLoyaltyDetailsModalOpened = value
  }
}
