import { EVENT_CATEGORY_PUIG } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmPuigOnClickOnStartButtonModel extends GtmBaseModel {
  constructor() {
    super({ eventCategory: EVENT_CATEGORY_PUIG })

    this.action_type = 'click_start'
  }
}
