export class GtmGa4BannerBaseModel {
  constructor({ creativeName, promotionName, promotionId, itemListId }) {
    this.creative_name = creativeName
    this.promotion_name = promotionName
    this.promotion_id = promotionId

    this.items = [
      {
        creative_name: creativeName,
        promotion_name: promotionName,
        promotion_id: promotionId,
        item_list_id: itemListId,
      },
    ]
  }
}
