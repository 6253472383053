import { useContext } from '@nuxtjs/composition-api'

import { computed, onMounted, onUnmounted } from 'vue'

import { GaModalBackButton } from '@ga/ui-components/modal/back-button'
import { GaModalTransitionContent } from '@ga/ui-components/modal/transition-content'

import GaAuthModalConfirmForm from '../modal-confirm-form'
import GaAuthModalStartForm from '../modal-start-form'
import GaAuthModalWelcome from '../modal-welcome'
import GaAuthModalWelcomeForm from '../modal-welcome-form'

import { useConfirm, useMods } from './scripts/use'

const DEFAULT_META = {
  reason: 'regular-login',
  callbacks: {},
}

// @vue/component
export default {
  name: 'ga-auth-modal',

  components: {
    GaModalBackButton,
    GaModalTransitionContent,

    GaAuthModalStartForm,
    GaAuthModalConfirmForm,
    GaAuthModalWelcomeForm,
    GaAuthModalWelcome,
  },

  props: {
    hasBackAction: {
      type: Boolean,
      default: false,
    },
    meta: {
      type: Object,
      default: () => DEFAULT_META,
    },
    modalData: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const { $gaApp } = useContext()

    const newWelcomeScreenFeatureOn = $gaApp.features.get('newWelcomeScreen')

    const isStartStep = computed(() => $gaApp.stores.auth.modal.isStartStep)
    const isLocaleChangingStep = computed(
      () => $gaApp.stores.auth.modal.isLocaleChangingStep,
    )
    const isConfirmStep = computed(() => $gaApp.stores.auth.modal.isConfirmStep)
    const isWelcomeStep = computed(() => $gaApp.stores.auth.modal.isWelcomeStep)
    const showBackAction = computed(
      () =>
        isConfirmStep.value ||
        isLocaleChangingStep.value ||
        props.hasBackAction,
    )

    const { mods } = useMods({ newWelcomeScreenFeatureOn, isWelcomeStep })

    const {
      phone,
      phoneCode,
      phoneFormatted,
      nextRequestDelay,
      verificationCode,
      stepError,

      onCodeSendSuccess,
      onCodeSendErrorClear,
      onCodeConfirmSuccess,
      onCodeConfirmErrorClear,
      onUpdateNextRequestDelay,
      onUpdateVerificationCode,
    } = useConfirm($gaApp, emit)

    const onLocaleChangingConfirmationConfirm = () =>
      $gaApp.services.auth.modal.onLocaleChangingConfirmationConfirm()
    const onUpdateCountry = (country) =>
      $gaApp.services.auth.modal.onUpdateCountry(country)
    const onBackAction = () => $gaApp.services.auth.modal.onBackAction(emit)
    const onOpenModal = () => $gaApp.services.auth.modal.onOpenModal()

    onMounted(() => {
      onOpenModal()
    })

    onUnmounted(() => {
      emit('close')
      $gaApp.services.auth.modal.handleIsNotLogged()
    })

    return {
      mods,
      newWelcomeScreenFeatureOn,

      isStartStep,
      isLocaleChangingStep,
      isConfirmStep,
      isWelcomeStep,
      showBackAction,

      phone,
      phoneCode,
      phoneFormatted,
      nextRequestDelay,
      verificationCode,
      stepError,

      onCodeSendSuccess,
      onCodeSendErrorClear,

      onCodeConfirmSuccess,
      onCodeConfirmErrorClear,

      onUpdateNextRequestDelay,
      onUpdateVerificationCode,
      onUpdateCountry,

      onLocaleChangingConfirmationConfirm,
      onBackAction,
    }
  },
}
