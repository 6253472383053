import { EVENT_CATEGORIES } from '../../../constants'
import { GtmGa4UserInteraction } from '../gtm-ga4-user-interaction.model/user-interaction.model'

export class GtmGa4OrderInteraction extends GtmGa4UserInteraction {
  constructor(data) {
    super({
      event_category: EVENT_CATEGORIES.ORDER_INTERACTION,
      ...data,
    })
  }
}
